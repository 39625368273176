import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { EditorState, convertFromRaw, } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import parse from 'html-react-parser';
import {toast, ToastContainer} from "react-toastify";

require('dotenv').config();

class Pages extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            errors: {},

            id: '',
            title: '',
            content: '',
            slug: '',
            isEditor: false,
        };
    }

    componentDidMount() {

        document.title = 'Gudangemas - Page';

        this.__fetchData();

    }


    componentWillReceiveProps() {
        setTimeout(function () {
            this.__fetchData();
        }.bind(this), 0.5);
    }

    __fetchData = () => {
        const slug = this.props.match.params.slug;
        this.setState({
            loading: true
        });
        Api.get('/blog-page/by-slug/'+slug).then(resp => {

            if (resp.data) {

                let currentContentAsHTML = '';
                if(resp.data['is-editor']) {
                    currentContentAsHTML = resp.data.content;
                }else {
                    let data = convertFromRaw(JSON.parse(resp.data.content));
                    let editorState = EditorState.createWithContent(data);

                    console.log(JSON.parse(resp.data.content))
                    if(editorState) {
                        currentContentAsHTML = convertToHTML({
                            styleToHTML: (style) => {
                                if (style === 'BOLD') {
                                    return <span style={{color: 'blue'}} />;
                                }
                            },
                            blockToHTML: (block) => {
                                if (block.type === 'PARAGRAPH') {
                                    return <p />;
                                }
                            },
                            entityToHTML: (entity, originalText) => {
                                if (entity.type === 'IMAGE') {
                                    return <img src={entity.data.src} width={entity.data.width} height={entity.data.height} alt='media' />;
                                }
                                if (entity.type === 'EMBEDDED_LINK') {
                                    return <iframe src={entity.data.src} width={entity.data.width} height={entity.data.height} frameBorder="0" allowFullScreen />;
                                }
                                if (entity.type === 'LINK') {
                                    return <a href={entity.data.url} target={entity.data.targetOption}>{entity.data.title}</a> ;
                                }
                                return originalText;
                            }
                        })(editorState.getCurrentContent());
                    }
                }

                this.setState({
                        id: resp.data.id,
                        title: resp.data.title,
                        content: currentContentAsHTML,
                        slug: resp.data.slug,
                        isEditor: resp.data['is-editor'],
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (
            <main className='pages'>
                <section className='container'>

                    {this.state.loading ?
                        <div className='loading-page'>
                            <i className='fa fa-spinner fa-spin'> </i>
                        </div> :
                        <>
                            <h1>{this.state.title}</h1>
                            {!this.state.isEditor ?
                                <div className='row'>
                                    <div className='col-md-9'>
                                        {parse(this.state.content)}
                                    </div>
                                </div> :
                                parse(this.state.content)}
                        </>
                    }
                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);