import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { EditorState, convertFromRaw, } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import parse from 'html-react-parser';
import {toast, ToastContainer} from "react-toastify";
import queryString from "query-string";

require('dotenv').config();

class BlogsDetail extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            errors: {},

            id: '',
            title: '',
            content: '',
            image: '',
            slug: '',
            mobile: false,
        };
    }

    componentDidMount() {
        let urlParams = queryString.parse(window.location.search);

        if(urlParams) {
            if(urlParams.mobile == 1) {
                this.setState({
                    mobile: true,
                });
            }

        }

        const slug = this.props.match.params.slug;
        this.setState({
            loading: true
        });


        document.title = 'Gudangemas - Blog';


        Api.get('/blog-page/by-slug/'+slug).then(resp => {

            if (resp.data) {

                let data = convertFromRaw(JSON.parse(resp.data.content));
                const editorState = EditorState.createWithContent(data);
                let currentContentAsHTML = '';
                if(editorState) {
                    currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
                }

                this.setState({
                        id: resp.data.id,
                        title: resp.data.title,
                    image: resp.data.image,
                        content: currentContentAsHTML,
                        slug: resp.data.slug,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (
            <main className='pages'>
                <section className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <div className={this.state.mobile ? 'd-none' : 'mb-3'}>
                                <a href='/blogs' className='btn-blue'>Kembali</a>
                            </div>

                            <p className='category'>category post</p>

                            {this.state.loading ?
                                <div className='loading-page'>
                                    <i className='fa fa-spinner fa-spin'> </i>
                                </div> :
                                <>
                                    <h1 className='text-center'>{this.state.title}</h1>
                                    <div className='text-center'>
                                        <time>16 Agustus 2020</time>

                                        {this.state.image &&
                                        <img src={this.state.image} alt={this.state.slug} className='img-fluid'/>
                                        }
                                    </div>
                                    {parse(this.state.content)}
                                </>
                            }

                            <div className={this.state.mobile ? 'd-none' : 'mt-3'}>
                                <a href='/blogs' className='btn-blue'>Kembali</a>
                            </div>
                        </div>

                    </div>




                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsDetail);