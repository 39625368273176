import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {connect} from 'react-redux';
import MomentUtils from '@date-io/moment';
import Checkbox from '@material-ui/core/Checkbox';

import moment from 'moment/moment';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';
import Default from "../../../images/default.jpeg";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {toast, ToastContainer} from "react-toastify";
import templateId from '../../../libraries/lang-id';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cart from "../../../images/product-cart.svg";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
require('dotenv').config();

SimpleReactValidator.addLocale('indo', templateId);


const validator = new SimpleReactValidator({
    locale: 'indo'
});
const validatorBilling = new SimpleReactValidator({
    locale: 'indo'
});
const validatorPassword = new SimpleReactValidator({
    locale: 'indo'
});
const validatorDelete = new SimpleReactValidator({
    locale: 'indo'
});
class Account extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingImage: false,
            loadingButton: false,
            showDialog: false,
            showDialogDelete: false,
            errors: {},
            value: 0,

            provinces: [],
            cities: [],
            subdistricts: [],

            photo: Default,
            user: null,

            levels: [],

            id: '',
            code: '',
            firstName: '',
            lastName: '',
            name: '',
            username: '',
            email: '',
            phone: '',

            company: '',
            place: '',
            address: '',
            province: '',
            city: '',
            subdistrict: '',
            type: '',
            zipcode: '',
            check: null,

            gender: '',
            birth: null,
            role: '',
            avatar: '',
            lastTransaction: '',
            lastUpdate: '',
            customerPoint: {},
            customerPointHistories: [],
            influencers: [],
            level: '',
            levelId: '',

            password: '',
            newPassword: '',
            confirmNewPassword: '',
            showPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            arows: [],
            atotal: 0,
            aperPage: 10,
            acurrentPage: 0,
            anextPage: 0,
            acurrentPageTable: 0,

            ocode: '',
            oorders: [],
            ostatus: '',
            ocurrentStatus: '',
            oname: '',
            ousername: '',
            oemail: '',
            olookup: {},
            obank: {},
            ocoupon: '',

            oresi: '',
            oshipping:'',
            oshippingType: '',

            opayment: '',
            odate: '',
            oshippingCost: '',
            ohistories: '',

            ototal: '',
            ounique: '',
            ograndTotal: '',
            odiscountCoupon: '',
            odiscountRedeem: '',

        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {

        document.title = 'Gudangemas - Akun';

        let user = AuthHelper.getProfile();

        this.setState({
            user: user,
            code: user['customer-code'],
        }, () => {
            this.__fetchData();
            this.__afetchData();
        });

        Api.get('/customer/by-code/'+user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    name: data['full-name'] !== null ? data['full-name'] : '',
                    username: data.username ? data.username : '',
                    email: data.email,
                    gender: data.gender ? data.gender : '',
                    birth: data['birth-date'] !== null ? moment(data['birth-date'],'DD-MM-YYYY') : null,
                    phone: data['phone-number'] !== null ? data['phone-number'] : '',
                    photo: data.avatar === null ? Default : data.avatar,
                    level: data['customer-type'],
                    levelId: data['customer-type-id'],
                    lastTransaction: data['last-transaction-date'],
                    lastUpdate: data['last-update'],
                    customerPoint: data['customer-point'] !== null ? data['customer-point'] : [],
                    influencers: data.influencers !== null ? data.influencers : [],
                });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/billing-address/'+user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    firstName: data['first-name'] !== null ? data['first-name'] : '',
                    lastName: data['last-name'] !== null ? data['last-name'] : '',
                    address: data.address ?  data.address : '',
                    company: data.company ?  data.company : '',
                    place: data.place ?  data.place : '',
                    province: data.province ? data.province.province_id : '',
                    city: data.city ?  data.city && data.city.id : '',
                    zipcode: data['postal-code'] ? data['postal-code'] : '',
                    subdistrict: data['sub-district'] ?  data['sub-district'].id : '',

                }, () => {
                    if(data.address) {
                        this.__fetchAddress();
                    }
                });
            }
        }).catch(err => {
            console.log(err);
        });

        this.__fetchProvince();

    }

    handleClickShowPassword = (prop) => {

        this.setState({

            [prop]:(!this.state[prop])

        });
    };

    __fetchProvince = () => {
        Api.get('/province').then(resp => {

            if (resp.data) {

                this.setState({
                        provinces: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchAddress = () => {

        this.__fetchProvince();

        this.__fetchCity();

        this.__fetchSubdistrict();

    };

    __fetchCity = () => {

        Api.get('/city/'+this.state.province).then(resp => {

            if (resp.data) {

                this.setState({
                        cities: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    __fetchSubdistrict = () => {

        Api.get('/subdistrict/'+this.state.city).then(resp => {

            if (resp.data) {

                this.setState({
                        subdistricts: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    coverName = (status) => {

        switch (status.toLowerCase()) {
            case 'pending': return 'Menunggu pembayaran'
            case 'processing': return 'Sedang diproses'
            case 'shipped': return 'Sedang dikirim'
            case 'complete': return 'Selesai'
            case 'cancelled': return 'Dibatalkan'
            default: return status
        }
    }

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChecked = (e) => {

        this.setState({

            check: e.target.checked,

        })

    };

    handleProvince (e, prop){

        this.setState({

            [prop]: e.target.value,
            city: '',
            subdistrict: '',
            zipcode: '',
            cities: [],
            subdistricts: [],

        }, () => {
            this.__fetchCity();
        });

    };

    handleCity (e, prop){
        let all = this.state.cities.filter((item) => item.id === e.target.value);

        this.setState({

            [prop]: e.target.value,
            subdistrict: '',
            subdistricts: [],
            zipcode: all.length > 0 ? all[0].postalCode : ''

        }, () => {
            this.__fetchSubdistrict();
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'birth-date': moment(this.state.birth).format('DD-MM-YYYY'),
            'first-name': this.state.firstName,
            'last-name': this.state.lastName,
            'email': this.state.email,
            'gender': this.state.gender,
            'phone-number': this.state.phone,
            'code': this.state.code,
            'level-id': this.state.levelId,
        };



        Api.put('/customer', params).then(resp => {

            if(resp.status === 200) {
                let user = AuthHelper.getProfile();
                user['full-name'] = this.state.firstName+ ' '+this.state.lastName;
                user['phone-number'] = this.state.phone;
                user['gender'] = this.state.gender;

                AuthHelper.setProfile(user);
                this.showMessage(true, 'Data berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loadingButton: false,
                }
            );


        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );
        });
    };

    handleSubmitAddress = (e) => {

        e.preventDefault();

        if (!validatorBilling.allValid()) {

            this.setState({
                    errors: validatorBilling.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params2 = {
            'address': this.state.address,
            'city-id': this.state.city,
            'company': this.state.company,
            'place': this.state.place,
            'province-id': this.state.province,
            'customer-code': this.state.code,
            'subdistrict-id': this.state.subdistrict,
            'postal-code': this.state.zipcode,
        };


        Api.put('/billing-address', params2).then(resp => {
            if(resp.status === 200) {
                this.showMessage(true, 'Data alamat berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loadingButton: false,
                }
            );


        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );
        });

    };

    handleSubmitPassword = (e) => {

        e.preventDefault();

        if (!validatorPassword.allValid()) {

            this.setState({
                    errors: validatorPassword.getErrorMessages()
                }
            );

            return false;

        }

        if(this.state.newPassword !== this.state.confirmNewPassword) {
            this.showMessage(false, 'Konfirmasi password tidak sama');
            return false;
        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params2 = {
            'current-password': this.state.password,
            'new-password': this.state.newPassword,
            'username': this.state.user.username,
        };


        Api.post('/user/reset-password', params2).then(resp => {
            if(resp.status === 200) {
                this.showMessage(true, 'Password berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );
        });

    };

    handleAvatar = (e) => {
        this.setState({
            loadingImage: true,
            }
        );

        let idCardBase64 = '';
        let url = e.target.files[0];

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.upload(url, idCardBase64),
            3000
        );
    };

    upload = (url, idCardBase64) => {
        let params = {
            'customer-code': this.state.code,
            images: [idCardBase64],
        };

        Api.put('/customer/update-image', params).then(resp => {
            if(resp.status === 200) {
                this.setState({
                        photo: url,
                    }
                );
                this.showMessage(true, 'Photo berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                loadingImage: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                loadingImage: false
                }
            );
        });


    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleClose = () => {

        this.setState({
            anchorEl: null
        })
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    handleCopy = () => {
        let copyText = document.getElementById("reff");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("Copied: " + copyText.value);
    }

    __fetchData = update => {

        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            'customer-code': this.state.code,
            size: this.state.perPage,
            ['search-by']: this.state.searchBy,
            'sort': [
                {
                    'column-name': 'transactionDate',
                    'is-ascending': false
                }
            ],
        };

        Api.post('/customer/point-history/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        rows: resp.data.customerPointHistories,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    __afetchData = update => {

        let page = update ? 0 : this.state.acurrentPage;

        let params = {
            page: page,
            'customer-code': this.state.code,
            size: this.state.aperPage,
        };

        Api.post('/order/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        arows: resp.data.viewOrders,
                        atotal: resp.data.totalItems,
                        acurrentPageTable: resp.data.currentPage,
                        acurrentPage: resp.data.currentPage,
                        anextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    ahandleChangePage = (event, newPage) => {
        this.setState({
            arows: [],
            acurrentPage:newPage,
            acurrentPageTable:newPage,
            loading: true,
        }, () => {
            this.__afetchData(false);
        });
    };

    handleLogout = () => {

        AuthHelper.logOut();

    };

    handleOpenDelete = () => {
        this.setState({
            showDialogDelete: true,
        });
    }

    handleCloseDelete = () => {
        this.setState({
            showDialogDelete: true,
        });
    }
    handleDelete = () => {
        if (!validatorDelete.allValid()) {
            this.setState({
                    errors: validatorDelete.getErrorMessages()
                }
            );
            return false;
        }

        Api.get('/user/delete-user').then(resp => {

            if(resp.status === 200) {
                AuthHelper.logOut();
                window.location.href = '/';


            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    loadingButton: false,
                }
            );
        });

    }

    handleOpen = (row) => {
        this.setState({
            ocode: '',
            oorders: [],
            ostatus: '',
            ocurrentStatus: '',
            oname: '',
            ousername: '',
            oemail: '',
            olookup: {},
            obank: {},
            ocoupon: '',

            oresi: '',
            oshipping:'',
            oshippingType: '',

            opayment: '',
            odate: '',
            oshippingCost: '',
            ohistories: '',

            ototal: '',
            ounique: '',
            ograndTotal: '',
            odiscountCoupon: '',
            odiscountRedeem: '',
        });

        if(row) {
            Api.get('/order/get-order-by-code/'+row.orderCode).then(resp => {

                let data = resp.data.order;

                if (data) {
                    this.setState({
                        ocode: data.orderCode,
                        oorders: data['order-details'],
                        ostatus: data.status,
                        oname: resp.data.name,
                        ousername: resp.data.username,
                        oemail: resp.data.email,
                        ocoupon: data.coupon,

                        oresi: data['resi-no'],
                        oshipping: data['shipping-address'],
                        oshippingType: data['shipping-type'],

                        opayment: data['payment-method'],
                        odate: data['order-date'],
                        oshippingCost: data['shipping-cost'],
                        ohistories: data['order-history'],

                        ototal: data['total-amount'],
                        ounique: data['unique-code'],
                        ograndTotal: data['grand-total'],
                        odiscountCoupon: data['discount-coupon'],
                        odiscountRedeem: data['discount-redeem'],
                        loading: false,
                    },() => {
                        this.setState({
                            showDialog: true,
                        });
                    });
                }

            }).catch(err => {
                console.log(err);
            });

        }

    };

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    };

    render() {
        return (
            <main className='accounts'>
                <div className='container'>
                    <section className='row justify-content-center section-account'>
                        <div className='col-md-10 col-xl-9'>
                            <div className='profile-img text-center'>
                                <img src={this.state.photo} className='img-profile' alt='profile' />
                                <label htmlFor="avatar" className="label-file">{this.state.loadingImage ? <i className="fa fa-spinner fa-spin"> </i> : <i className="fas fa-pen"> </i>}</label>
                                <TextField variant="outlined"
                                           type="file"
                                           id='avatar'
                                           name="avatar"
                                           className="avatar"
                                           onChange={this.handleAvatar}
                                />
                            </div>

                            <div className='account-wrap'>
                                <Tooltip title="Log Out" placement="bottom">
                                    <button className='button-logout' onClick={this.handleLogout}>
                                        <i className="fas fa-power-off"> </i>
                                    </button>
                                </Tooltip>

                                <h1 className='text-center mb-3'>{this.state.firstName} {this.state.lastName}</h1>
                                <div className='row justify-content-center'>
                                    <div className='col-6 col-md-4 text-right border-right'>
                                        <div className='text-center'>
                                            <i className="far fa-clock"> </i>
                                            <p className='mb-2'><strong>Login terakhir</strong></p>
                                            <time>{this.state.lastUpdate}</time>
                                        </div>
                                    </div>
                                    <div className='col-6 col-md-4'>
                                        <div className='text-center'>
                                            <i className="fas fa-exchange-alt"> </i>
                                            <p className='mb-2'><strong>Transaksi terakhir</strong></p>
                                            <time>{this.state.lastTransaction}</time>
                                        </div>
                                    </div>
                                </div>
                                <AppBar position="static">
                                    <Tabs value={this.state.value}
                                          variant="scrollable"
                                          scrollButtons="auto"
                                          onChange={this.handleChangeTab} aria-label="produk tabs">
                                        <Tab label="Informasi Pribadi" {...a11yProps(0)} />
                                        <Tab label="Informasi Penagihan" {...a11yProps(1)} />
                                        <Tab label="Kelola Password" {...a11yProps(2)} />
                                        <Tab label="Status Akun" {...a11yProps(3)} />
                                        <Tab label="Riwayat Pemesanan" {...a11yProps(4)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={this.state.value} index={0}>
                                    <form name="account" id="editAccount" className="row" noValidate>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Username</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='username'
                                                           name="username"
                                                           label="Username"
                                                           value={this.state.user ? this.state.user.username : ''}
                                                           disabled={true}
                                                           fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Depan*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='firstName'
                                                           name="firstName"
                                                           label="Nama Depan"
                                                           onChange={(e) => this.handleChange(e, 'firstName')}
                                                           value={this.state.firstName}
                                                           fullWidth
                                                />
                                                {validator.message('firstName', this.state.firstName, 'required')}
                                                <div className='text-danger'>{this.state.errors.firstName}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Belakang*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='lastName'
                                                           name="lastName"
                                                           label="Nama Belakang"
                                                           onChange={(e) => this.handleChange(e, 'lastName')}
                                                           value={this.state.lastName}
                                                           fullWidth
                                                />

                                                <div className='text-danger'>{this.state.errors.lastName}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tanggal Lahir*</label>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        label="Tanggal Lahir"
                                                        value={this.state.birth}
                                                        onChange={(date) => this.handleDate(date, 'birth')}
                                                        format={'DD-MM-YYYY'}
                                                        cancelLabel="BATAL"
                                                        inputVariant="outlined"
                                                        className='full'
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {validator.message('birth', this.state.birth, 'required')}
                                                <div className='text-danger'>{this.state.errors.birth}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Email*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='email'
                                                           name="email"
                                                           label="Email"
                                                           onChange={(e) => this.handleChange(e, 'email')}
                                                           value={this.state.email}
                                                           fullWidth
                                                />
                                                {validator.message('email', this.state.email, 'email|required')}
                                                <div className='text-danger'>{this.state.errors.email}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Jenis Kelamin*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="gender"
                                                           name="gender"
                                                           label="Jenis Kelamin"
                                                           onChange={(e) => this.handleChange(e, 'gender')}
                                                           value={this.state.gender}
                                                           fullWidth
                                                >
                                                    <MenuItem value="P">
                                                        Perempuan
                                                    </MenuItem>
                                                    <MenuItem value="L">
                                                        Laki - Laki
                                                    </MenuItem>
                                                </TextField>
                                                {validator.message('gender', this.state.gender, 'required')}
                                                <div className='text-danger'>{this.state.errors.gender}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Phone*</label>
                                                <TextField variant="outlined"
                                                           type='number'
                                                           id='phone'
                                                           name="phone"
                                                           label="Phone"
                                                           onChange={(e) => this.handleChange(e, 'phone')}
                                                           value={this.state.phone}
                                                           fullWidth
                                                />
                                                {validator.message('phone', this.state.phone, 'required')}
                                                <div className='text-danger'>{this.state.errors.phone}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmit}
                                                disabled={this.state.loadingButton }
                                            >
                                                Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                            </Button>

                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel value={this.state.value} index={1}>
                                    <form name="billing" id="editBilling" className="row" noValidate>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Perusahaan</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='company'
                                                           name="company"
                                                           label="Nama Perusahaan"
                                                           onChange={(e) => this.handleChange(e, 'company')}
                                                           value={this.state.company}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.company}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Apt, Suite, etc.</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='place'
                                                           name="place"
                                                           label="Lokasi"
                                                           onChange={(e) => this.handleChange(e, 'place')}
                                                           value={this.state.place}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.place}</div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Address*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='address'
                                                           name="address"
                                                           label="Address"
                                                           onChange={(e) => this.handleChange(e, 'address')}
                                                           value={this.state.address}
                                                           fullWidth
                                                />
                                                {validatorBilling.message('address', this.state.address, 'required')}
                                                <div className='text-danger'>{this.state.errors.address}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Provinsi*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="province"
                                                           name="province"
                                                           label="Provinsi"
                                                           onChange={(e) => this.handleProvince(e, 'province')}
                                                           value={this.state.province}
                                                           fullWidth
                                                >
                                                    {this.state.provinces.map(option => (
                                                        <MenuItem key={option.province_id} value={option.province_id}>
                                                            {option.province}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validatorBilling.message('address', this.state.address, 'required')}
                                                <div className='text-danger'>{this.state.errors.province}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kota*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="city"
                                                           name="city"
                                                           label="Kota"
                                                           onChange={(e) => this.handleCity(e, 'city')}
                                                           value={this.state.city}
                                                           fullWidth
                                                >
                                                    {this.state.cities.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.type} {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validatorBilling.message('city', this.state.city, 'required')}
                                                <div className='text-danger'>{this.state.errors.city}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kecamatan*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="subdistrict"
                                                           name="subdistrict"
                                                           label="Kecamatan"
                                                           onChange={(e) => this.handleChange(e, 'subdistrict')}
                                                           value={this.state.subdistrict}
                                                           fullWidth
                                                >
                                                    {this.state.subdistricts.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validatorBilling.message('subdistrict', this.state.subdistrict, 'required')}
                                                <div className='text-danger'>{this.state.errors.subdistrict}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kode Pos</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id="zipcode"
                                                           name="zipcode"
                                                           label="Kode Pos"
                                                           onChange={(e) => this.handleChange(e, 'zipcode')}
                                                           value={this.state.zipcode}
                                                           fullWidth
                                                />
                                                {validatorBilling.message('zipcode', this.state.zipcode, 'required')}

                                                <div className='text-danger'>{this.state.errors.zipcode}</div>
                                            </div>
                                        </div>

                                        <div className="col-12 text-right">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmitAddress}
                                                disabled={this.state.loadingButton }
                                            >
                                                Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                            </Button>

                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel value={this.state.value} index={2}>
                                    <form name="password" id="editPassword" className="row" noValidate>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Password saat ini</label>
                                                <TextField variant="outlined"
                                                           type={this.state.showPassword ? 'text' : 'password'}
                                                           id="adornment-password"
                                                           name="password"
                                                           label="Password"
                                                           onChange={(e) => this.handleChange(e,'password')}
                                                           value={this.state.password}
                                                           fullWidth
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label="Toggle password visibility"
                                                                           onClick={() => this.handleClickShowPassword('showPassword')}
                                                                       >
                                                                           {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                               <i className="fas fa-eye-slash"> </i>}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                               )
                                                           }}
                                                />
                                                {validatorPassword.message('password', this.state.password, 'required')}
                                                <div className='text-danger'>{this.state.errors.password}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'> </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Password baru</label>
                                                <TextField variant="outlined"
                                                           type={this.state.showNewPassword ? 'text' : 'password'}
                                                           id="adornment-password"
                                                           name="newPassword"
                                                           label="Password"
                                                           onChange={(e) => this.handleChange(e,'newPassword')}
                                                           value={this.state.newPassword}
                                                           fullWidth
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label="Toggle password visibility"
                                                                           onClick={() => this.handleClickShowPassword('showNewPassword')}
                                                                       >
                                                                           {this.state.showNewPassword ? <i className="fas fa-eye"> </i> :
                                                                               <i className="fas fa-eye-slash"> </i>}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                               )
                                                           }}
                                                />
                                                {validatorPassword.message('newPassword', this.state.newPassword, 'required')}
                                                <div className='text-danger'>{this.state.errors.newPassword}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Konfirmasi Password baru</label>
                                                <TextField variant="outlined"
                                                           type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                                                           id="adornment-password"
                                                           name="confirmNewPassword"
                                                           label="Password"
                                                           onChange={(e) => this.handleChange(e,'confirmNewPassword')}
                                                           value={this.state.confirmNewPassword}
                                                           fullWidth
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label="Toggle password visibility"
                                                                           onClick={() => this.handleClickShowPassword('showConfirmNewPassword')}
                                                                       >
                                                                           {this.state.showConfirmNewPassword ? <i className="fas fa-eye"> </i> :
                                                                               <i className="fas fa-eye-slash"> </i>}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                               )
                                                           }}
                                                />
                                                {validatorPassword.message('confirmNewPassword', this.state.confirmNewPassword, 'required')}
                                                <div className='text-danger'>{this.state.errors.confirmNewPassword}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmitPassword}
                                                disabled={this.state.loadingButton }
                                            >
                                                Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                            </Button>

                                        </div>
                                    </form>
                                </TabPanel>

                                <TabPanel value={this.state.value} index={3}>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <label className='mb-0'>Level</label>
                                            <p><strong>{this.state.level}</strong></p>

                                            <label className='mb-0'>Referral Code</label>
                                            <p><strong>{this.state.code}</strong></p>
                                            <label className='mb-0'>Hapus Akun</label>
                                        </div>
                                        <div className='col-md-8 position-relative'>
                                            <label className='mb-0'>Link Refferal</label>
                                            <p><strong>{process.env.REACT_APP_APP+'/register?ref='+this.state.code} </strong></p>
                                            <input type="text" id='reff' value={process.env.REACT_APP_APP+'/register?ref='+this.state.code} style={{opacity:0,position:'absolute',top:0}} />
                                            <button className='btn-blue' onClick={this.handleCopy}>Copy Link</button>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <button className='btn-ongkir' onClick={this.handleOpenDelete}>Hapus Akun</button>
                                        </div>
                                    </div>
                                    <div className="table-list mb-3">
                                        <Table size="small" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Total Poin Aktif</TableCell>
                                                    <TableCell>Total Poin Digunakan</TableCell>
                                                    <TableCell>Total Kadaluarsa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell><i className='icon-p'>P</i> <strong className='text-blue'>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.customerPoint ? this.state.customerPoint['total-point-active'] : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </strong></TableCell>
                                                    <TableCell><i className='icon-p'>P</i> <strong className='text-blue'>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.customerPoint ? this.state.customerPoint['total-point-used'] : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </strong></TableCell>
                                                    <TableCell><i className='icon-p'>P</i> <strong className='text-blue'>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.customerPoint ? this.state.customerPoint['total-point-expired'] : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </strong></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>

                                    <h2 className='mt-5 mb-4'>GE Best Friend</h2>
                                    {this.state.influencers.length > 0 &&
                                    <div className="row">
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <p><i className="fas fa-people-arrows"> </i> <strong>Influencer :</strong></p>
                                        </div>
                                        <div className="col-6">
                                            <p>{this.state.influencers[0] ? this.state.influencers[0].name : ''} | <strong>{this.state.influencers[0] ? this.state.influencers[0].code : ''}</strong></p>
                                        </div>
                                    </div>
                                    }
                                   {/* {this.state.loading ? (
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <CircularProgress color="primary"  />
                                            </div>
                                        </div>
                                    ) : (
                                        this.state.influencers.length === 0 ? (
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <p>Data Kosong</p>
                                                </div>
                                            </div>
                                        ) : (
                                            this.state.influencers.map((row,key) => (
                                                <div className="row" key={key}>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <p><i className="fas fa-people-arrows"> </i> <strong>Influencer {key+1} :</strong></p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>{row.name} | <strong>{row.code}</strong></p>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    )}*/}

                                    <h2 className='mt-5 mb-4'>Histori Poin</h2>
                                    <div className="table-list text-left">
                                        <Table size="small" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Tgl. Transaksi</TableCell>
                                                    <TableCell>Debit Poin</TableCell>
                                                    <TableCell>Kredit Poin</TableCell>
                                                    <TableCell>Balance</TableCell>
                                                    <TableCell>Tgl. Kadaluarsa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.loading ? (
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" className="py-5">
                                                            <CircularProgress color="primary"  />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.rows.length === 0 ? (
                                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                                            <TableCell colSpan={5} style={{ textAlign: "center" }}>Histori kosong</TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        this.state.rows.map(row => (
                                                            <TableRow
                                                                key={row['id']}
                                                                selected={row.selected}
                                                            className={row['is-expired'] ? 'true' : ''}>
                                                                <TableCell>{row.trxDate === null ? '' : row.trxDate}</TableCell>
                                                                <TableCell>
                                                                    <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.debitPoint} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.creditPoint} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.balance} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </TableCell>
                                                                <TableCell>{row.expDate === null ? '' : row.expDate}</TableCell>

                                                            </TableRow>
                                                        ))
                                                    )
                                                )}

                                            </TableBody>
                                        </Table>
                                    </div>

                                    <div className='text-right col-12'>

                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            count={this.state.total}
                                            rowsPerPage={this.state.perPage}
                                            page={this.state.currentPageTable}
                                            backIconButtonProps={{
                                                'aria-label': 'previous page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'next page',
                                            }}
                                            onChangePage={this.handleChangePage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel value={this.state.value} index={4}>
                                    <div className="table-list">
                                        <Table size="small" >
                                            <TableBody>
                                                {this.state.loading ? (
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" className="py-5">
                                                            <CircularProgress color="primary"  />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.arows.length === 0 ? (
                                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                                            <TableCell colSpan={2} style={{ textAlign: "center" }}>Order kosong</TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        this.state.arows.map(row => (
                                                            <TableRow
                                                                key={row['orderCode']}
                                                                selected={row.selected}
                                                            >
                                                                <TableCell>
                                                                    <div className='row align-items-center'>
                                                                        <div className='col-12 text-left'>
                                                                            <time>{row.orderDate}</time>
                                                                        </div>
                                                                        <div className='col-9 text-left'>
                                                                            {/*<h4 className='mb-0'>{row.orderDate}</h4>*/}
                                                                            <p className='mb-0'>No pesanan: <strong className='text-blue'>{row.orderCode}</strong></p>
                                                                            <p className='mb-0'>Total Belanja: <strong className='text-yellow'>Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.grandTotal ? row.grandTotal : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                            </strong></p>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className='text-right'>
                                                                    <span className={'label-status '+row.status.toLowerCase()} onClick={() => this.handleOpen(row)}>
                                                                    {row.status === null ? '' : this.coverName(row.status)}
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )
                                                )}

                                            </TableBody>
                                        </Table>
                                    </div>

                                    <div className='text-right col-12'>

                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            count={this.state.atotal}
                                            rowsPerPage={this.state.aperPage}
                                            page={this.state.acurrentPageTable}
                                            backIconButtonProps={{
                                                'aria-label': 'previous page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'next page',
                                            }}
                                            onChangePage={this.ahandleChangePage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                    </section>

                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleCloseDialog}
                    className='ge-dialog order'
                    aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <h3>Detail Pemesanan</h3>
                        <label>No Order:</label>
                        <p>{this.state.ocode === null ? '' : this.state.ocode}</p>

                        <label>Tanggal Pembelian:</label>
                        <p>{this.state.odate === null ? '' : this.state.odate}</p>

                        <label>Status:</label>
                        <p>{this.state.ostatus === null ? '' : this.coverName(this.state.ostatus)}</p>

                        {this.state.oorders.length > 0 &&
                        this.state.oorders.map(item => (
                            <div className='row border-bottom pt-3' key={item.id}>
                                <div className='col-6'>
                                    <p className='mb-0'>{item.sku} <br />{item.name}</p>
                                    <p>{item.quantity} x <span className='text-yellow'>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={item.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </span></p>

                                </div>
                                <div className='col-6 text-right'>
                                    <label>Total Harga</label>
                                    <p><strong className='text-yellow'>
                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={item.quantity*parseFloat(item.price)} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </strong></p>
                                </div>
                            </div>
                        ))
                        }

                        <div className='row pt-3 border-bottom'>
                            <div className='col-12'>
                                <label>Pengiriman</label>
                                <p className={this.state.oresi === null ? '' : 'mb-0' }>{this.state.oshippingType === null ? '' : this.state.oshippingType.name}</p>
                                {this.state.oresi === null ? '' : <p>No Resi: {this.state.oresi}</p>}
                            </div>
                        </div>


                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Total Harga</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.ototal} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Total Ongkos Kirim</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.oshippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Kode Unik Pembayaran</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp -<NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.ounique} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>
                        {this.state.odiscountCoupon > 0 &&
                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Kupon {this.state.ocoupon ? this.state.ocoupon.code : ''}</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp -<NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.odiscountCoupon} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>}

                        {this.state.odiscountRedeem > 0 &&
                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Redeem Poin</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp -<NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.odiscountRedeem} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>}

                        <div className='row pt-3'>
                            <div className='col-6'>
                                <p className='mb-0'>Total Bayar</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p className='mb-0'><strong>
                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.ograndTotal} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </strong></p>
                            </div>
                        </div>

                        <div className='row border-bottom pt-3'>
                            <div className='col-6'>
                                <p>Metode Pembayaran</p>
                            </div>
                            <div className='col-6 text-right'>
                                <p >{this.state.opayment === null ? '' : this.state.opayment}</p>
                            </div>
                        </div>

                        <div className='row pt-3'>
                            <div className='col-12 col-md-6'>
                                <p>Riwayat Order</p>
                                {this.state.ohistories.length > 0 &&
                                this.state.ohistories.map(row => (
                                    <div className='history-list' key={row.id}>
                                        <p>{row.description}</p>
                                        <small>{row['history-date']}</small>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </DialogContent>

                </Dialog>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogDelete}
                    onClose={this.handleCloseDelete}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Yakin untuk dihapus?</DialogTitle>
                    <DialogContent>
                        <div className='text-center'>
                            <p>Semua data Anda di Gudangemas.com akan terhapus</p>
                            <br/>
                            <p>Dengan melakukan aksi ini Anda menyetujui bahwa Akun anda beserta riwayat akan terhaous dari aplikasi
                            </p>
                            <FormGroup className='mb-3 label-tnc'>
                                <FormControlLabel
                                    color="primary"
                                    name='check'
                                    control={<Checkbox
                                        checked={this.state.check}
                                        onChange={this.handleChecked}
                                        name='check'
                                        value={true}
                                    />}
                                    label={<span> Saya setuju dan mengerti</span>}

                                    className="mb-0"
                                />
                                {validatorDelete.message('check', this.state.check, 'required')}
                                <div className='text-danger'>{this.state.errors.check}</div>
                            </FormGroup>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue" onClick={this.handleDelete}>
                            Hapus
                        </button>
                    </DialogActions>
                </Dialog>
                <ToastContainer />
            </main>
        )
    }
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className='tab-content'>
                    {children}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Account);