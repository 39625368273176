import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {connect} from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';
import Default from "../../../images/default.jpeg";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {toast, ToastContainer} from "react-toastify";
import templateId from '../../../libraries/lang-id';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from "@material-ui/core/Dialog";
require('dotenv').config();

SimpleReactValidator.addLocale('indo', templateId);


const validator = new SimpleReactValidator({
    locale: 'indo'
});
const validatorBilling = new SimpleReactValidator({
    locale: 'indo'
});
const validatorPassword = new SimpleReactValidator({
    locale: 'indo'
});

class Account extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingImage: false,
            loadingButton: false,
            showDialog: false,
            errors: {},
            value: 0,

            provinces: [],
            cities: [],
            subdistricts: [],

            photo: Default,
            user: null,

            levels: [],

            statusWajib: false,
            totalGold: 0,
            zakatValue: 0,
            currentTotal: 0,
            sisaHari: 0,

            id: '',
            code: '',
            jenis: '',
            harga: '',
            tipe: '',
            gram: '',
            kadar: '',
            date: null,

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            showDialogDelete :false,
            idDelete: '',
            currDelete: '',

        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {

        document.title = 'Gudangemas - Zakat';

        let user = AuthHelper.getProfile();

        this.setState({
            user: user,
            code: user['customer-code'],
        }, () => {
            this.__fetchData(false);
        });

    }

    handleGo  = (link) => {

        history.push(link);
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );
        let gram = this.state.gram.replace(/,/g, '.')

        if(this.state.tipe == 'Jual') {
            gram = -1*gram;
        }

        let rate = this.state.kadar.replace(/,/g, '.')

        let params = {
            'customer-code': this.state.code,
            'gold-type': this.state.jenis,
            'id': 0,
            'price': this.state.harga,
            'rate': rate,
            'transaction-date': moment(this.state.date).format('DD-MM-YYYY'),
            'type': this.state.tipe,
            'weight': gram,
        };

        Api.post('/zakat', params).then(resp => {

            if(resp.status === 200) {
                this.__fetchData(false);

                this.showMessage(true, 'Data berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loadingButton: false,
                showDialog: false,
                }
            );


        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false,
                showDialog: false
                }
            );
        });
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    __fetchData = update => {

        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            'customer-code': this.state.code,
            size: this.state.perPage,
        };

        Api.post('/zakat/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                    statusWajib: resp.data['status-wajib'],
                    totalGold: resp.data['total-gold'],
                    zakatValue: resp.data['zakat-value'],
                    currentTotal: resp.data['total-current-price'],
                    sisaHari: resp.data['sisa-hari'],
                    showDialog: false,
                        rows: resp.data.zakats,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleOpenDialog = () => {
        this.setState({
            jenis: '',
            harga: '',
            tipe: '',
            gram: '',
            kadar: '',
            showDialog: true,
        });
    };


    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    };
    handleCloseDelete = () => {
        this.setState({
            showDialogDelete: false,
        });
    };

    handleOpenDelete = (row) => {

        this.setState({
            showDialogDelete :true,
            idDelete: row.id,
            currDelete: row.jenis,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {

            Api.delete('/zakat/'+this.state.idDelete).then(resp => {

                if(resp.status === 200) {


                    this.showMessage(true, 'Data berhasil dihapus');
                    this.__fetchData();
                }else {
                    this.showMessage(false, resp.message[0]);

                }
                this.setState({
                        showDialogDelete: false,
                    }
                );

            }).catch(err => {

                this.setState({
                    showDialogDelete: false
                    }
                );

            });
        }
    };
    render() {
        return (
            <main className='zakat'>
                <div className='container'>
                    <section className='row'>
                        <div className='col-md-6'>
                            <div className='box-zakat text-center'>
                                <div className='profile-img'>
                                    <img src={this.state.photo} className='img-profile' alt='profile' />
                                </div>
                                <h2>{this.state.name}</h2>
                                <div className='row'>
                                    <div className='col-6 border-right'>
                                        <i className="fas fa-box-open"> </i>
                                        <p className='mb-2'><strong>Jumlah Emas</strong></p>
                                        <p>{this.state.totalGold} gram</p>
                                    </div>
                                    <div className='col-6'>
                                        <i className="fas fa-wallet"> </i>
                                        <p className='mb-2'><strong>Total Harga Saat Ini</strong></p>
                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={this.state.currentTotal} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='card-bubble'>
                                <i className="fas fa-quote-right"> </i>
                                {this.state.statusWajib ?
                                    <p className='mb-0'>
                                        Alhamdulillah Emas kamu sekarang sudah <strong>{this.state.totalGold} gram</strong>. Jangan lupa zakat emas kamu adalah Rp <strong> Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={this.state.zakatValue} displayType={'text'} renderText={value => <span>{value}</span>}/> </strong>
                                         yang akan dimulai dalam <strong>{this.state.sisaHari}</strong> hari lagi dan setelah kamu menyimpan selama 1 Tahun Hijriah.
                                        Ayo semangat menabung emas. Semoga Berkah dan Bertambah yaa. Aamiin
                                    </p>
:
                                    <p className='mb-0'>Syarat kamu menjadi waib zakat adalah total emas <strong>85 gram</strong> dan setelah menyimpan selma 1 tahun Hijriyah. Ayo semangat menabung emas!</p>


                                }
                               <div className='text-right'>
                                   <i className="fas fa-quote-left"> </i>

                               </div>
                            </div>

                            <div className='mt-3 info-bank'>
                                <h4>Catat dan hitung zakatnya di gudangemas,<br/>
                                    Salurkan ke Yayasan Bangun Sejahtera Mitra Umat (Yayasan BSMU)</h4>
                                <p className='bold'>Bank Syariah Indonesia (BSI)<br/>
                                    7026202595<br/>
                                    a.n. Yayasan BSMU-ZKT2</p>

                                <h5>Informasi lebih lanjut:</h5>
                                <p>
                                    Email: info@bsmu.or.id<br/>
                                    FB: LaznasBsmu<br/>
                                    IG: laznasbsmumat
                                </p>

                            </div>

                        </div>

                        <div className='col-12'>
                            <div className='row mt-3 mb-3 align-items-center'>
                                <div className='col-6'>
                                    <h2 className='mb-0'>Histori Emas</h2>

                                </div>
                                <div className='col-6 text-right'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpenDialog}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>

                            <div className="table-list">
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tgl. Transaksi</TableCell>
                                            <TableCell>Jenis Emas</TableCell>
                                            <TableCell>Gram</TableCell>
                                            <TableCell>Kadar</TableCell>
                                            <TableCell>Harga Transaksi</TableCell>
                                            <TableCell>Harga Saat Ini</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.loading ? (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center" className="py-5">
                                                    <CircularProgress color="primary"  />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.length === 0 ? (
                                                <TableRow style={{ height: 33 * this.state.perPage}}>
                                                    <TableCell colSpan={7} style={{ textAlign: "center" }}>Histori kosong</TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.rows.map(row => (
                                                    <TableRow
                                                        key={row['id']}
                                                        selected={row.selected}>
                                                        <TableCell>
                                                            {row['type'] == 'Jual' ?
                                                                <i className="fas fa-arrow-left icon"> </i> :
                                                                <i className="fas fa-arrow-right icon"></i>
                                                            }
                                                            {row['transaction-date'] === null ? '' : row['transaction-date']}</TableCell>
                                                        <TableCell>{row['gold-type'] === null ? '' : row['gold-type']}</TableCell>
                                                        <TableCell>{row['weight'] === null ? '' : Math.abs(row['weight'])}</TableCell>
                                                        <TableCell>{row['rate'] === null ? '' : row['rate']}</TableCell>
                                                        <TableCell>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={Math.abs(row['current-price'])} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            <button className="btn-icon" type='button' onClick={() => this.handleOpenDelete(row)}>
                                                                <i className="fas fa-trash-alt"> </i>
                                                            </button>
                                                        </TableCell>

                                                    </TableRow>
                                                ))
                                            )
                                        )}

                                    </TableBody>
                                </Table>
                                <div className='text-right'>

                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={this.state.total}
                                    rowsPerPage={this.state.perPage}
                                    page={this.state.currentPageTable}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    ActionsComponent={TablePaginationActions}
                                />

                                    <Dialog
                                        maxWidth='sm'
                                        fullWidth={true}
                                        open={this.state.showDialogDelete}
                                        onClose={this.handleCloseDelete}
                                        aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">PERHATIKAN! {this.state.currDelete}</DialogTitle>
                                        <DialogContent>
                                            <p>Apakah anda yakin akan membatalkan item ini?</p>
                                        </DialogContent>
                                        <DialogActions>
                                            <button className="btn btn-blue-trans" onClick={this.handleCloseDelete}>
                                                Batal
                                            </button>
                                            <button className="btn btn-blue" onClick={this.handleDelete}>
                                                Hapus
                                            </button>
                                        </DialogActions>
                                    </Dialog>
                            </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleCloseDialog}
                    className='ge-dialog order'
                    aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <h3> BELI/JUAL EMAS</h3>
                        <form name="zakat" id="addZakat" noValidate>

                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Tipe*</label>
                                <TextField variant="outlined"
                                           select
                                           id="tipe"
                                           name="tipe"
                                           label="Tipe"
                                           onChange={(e) => this.handleChange(e, 'tipe')}
                                           value={this.state.tipe}
                                           fullWidth
                                >
                                    <MenuItem value="Beli">
                                        Beli
                                    </MenuItem>
                                    <MenuItem value="Jual">
                                        Jual
                                    </MenuItem>
                                </TextField>
                                {validator.message('tipe', this.state.tipe, 'required')}
                                <div className='text-danger'>{this.state.errors.tipe}</div>
                            </div>

                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Jenis emas*</label>
                                <TextField variant="outlined"
                                           type='text'
                                           id='jenis'
                                           name="jenis"
                                           label="Jenis"
                                           onChange={(e) => this.handleChange(e, 'jenis')}
                                           value={this.state.jenis}
                                           fullWidth
                                />
                                {validator.message('jenis', this.state.jenis, 'required')}
                                <div className='text-danger'>{this.state.errors.jenis}</div>
                            </div>

                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Gram*</label>
                                <TextField variant="outlined"
                                           type='number'
                                           id='gram'
                                           name="gram"
                                           label="Gram"
                                           onChange={(e) => this.handleChange(e, 'gram')}
                                           value={this.state.gram}
                                           fullWidth
                                />
                                {validator.message('gram', this.state.gram, 'required')}
                                <div className='text-danger'>{this.state.errors.gram}</div>
                            </div>

                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Kadar*</label>
                                <TextField variant="outlined"
                                           type='number'
                                           id='kadar'
                                           name="kadar"
                                           label="Kadar"
                                           onChange={(e) => this.handleChange(e, 'kadar')}
                                           value={this.state.kadar}
                                           fullWidth
                                />
                                {validator.message('kadar', this.state.kadar, 'required')}
                                <div className='text-danger'>{this.state.errors.kadar}</div>

                            </div>

                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Harga Transaksi*</label>
                                <TextField variant="outlined"
                                           type='text'
                                           id='harga'
                                           name="harga"
                                           label="Harga Transaksi"
                                           onChange={(e) => this.handleChange(e, 'harga')}
                                           value={this.state.harga}
                                           InputProps={{
                                               inputComponent: this.NumberFormatCustom,
                                           }}
                                           fullWidth
                                />
                            </div>



                            <div className="form-group">
                                <label className='mb-2 font-weight-bold'>Tanggal Transaksi*</label>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        label="Tanggal Transaksi"
                                        value={this.state.date}
                                        onChange={(date) => this.handleDate(date, 'date')}
                                        format={'DD-MM-YYYY'}
                                        cancelLabel="BATAL"
                                        inputVariant="outlined"
                                        className='full'
                                    />
                                </MuiPickersUtilsProvider>
                                {validator.message('date', this.state.date, 'required')}
                                <div className='text-danger'>{this.state.errors.date}</div>
                            </div>

                            <div className="text-center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>

                            </div>
                        </form>

                    </DialogContent>

                </Dialog>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Account);