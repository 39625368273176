import React,{ Component } from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import Logo from '../../../images/logo-white.png';
import queryString from 'query-string';
import Api from "../../../libraries/api";
import moment from "moment/moment";

class Footer extends Component {

    constructor(props) {

        super(props);
        this.state = {
            mobile: false,
            footers: [],
        }

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    componentDidMount() {
        let urlParams = queryString.parse(window.location.search);

        if(urlParams) {
            if(urlParams.mobile == 1) {
                this.setState({
                    mobile: true,
                });
            }

        }

        Api.get('/dashboard').then(resp => {

            let data = resp.data;
            if (data) {

                this.setState({
                        footers: data.footers.reverse(),
                        loading: false,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleToggleSidebar(){

        if(this.props.toggle_sidebar_state){

            this.props.setHideSidebar();

        }else{

            this.props.setShowSidebar();

        }

    }

    handleGo  = (link) => {

        history.push(link);
    };

    render() {
        const date = Date.now();
        return(

            <footer className={this.state.mobile ? 'd-none' : 'footer-info'}>
                <div className='top'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 info'>
                                <img src={Logo} className='img-fluid' alt='logo-footer' />

                                <p className='mb-1'><strong>Email:</strong> cs@gudangemas.com</p>
                                <p className='mb-1'><strong>Chat WA:</strong> 082258540101</p>
                                <p className='mb-0'><strong>Jam Operasional:</strong></p>
                                <p>Senin-Jum’at: 09:00-17:00 WIB<br />
                                    Sabtu: 09:00-13:00 WIB</p>
                            </div>
                            {this.state.footers.length > 0 &&
                            this.state.footers.map((row, id) => (
                                (id === 2 || id === 1) &&
                                <div className='col-md-3 pt-md-4' key={id}>
                                        <h3>{row['footer-group']}</h3>
                                        {row.details.length > 0 &&
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            { row.details.map((item, i) => (
                                                <li className="nav-item" key={i}>
                                                    <a href={item.link} className='nav-link'>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                        }
                                </div>

                            ))}
                            <div className='col-md-3 pt-md-4'>
                                <h3>Social Media</h3>
                                <div className='sos-med'>
                                    <a href='https://www.instagram.com/gudangemas24k/' target='_blank'>
                                        <i className="fab fa-instagram"> </i>
                                    </a>
                                    <a href='https://www.facebook.com/gudang.emas.1447/' target='_blank'>
                                        <i className="fab fa-facebook-f"> </i>
                                    </a>
                                    <a href='https://www.youtube.com/channel/UCKeUna-kl8E32E2oL_74Dkw/videos' target='_blank'>
                                        <i className="fab fa-youtube"> </i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='bottom'>
                    <p>© Copyright - PT. Sinergi Mulia Investama</p>
                </div>

            </footer>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);