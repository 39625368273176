import React,{ Component } from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import Logo from '../../../images/logo.png';
import LogoWhite from '../../../images/small.png';
import user from '../../../images/user.svg';
import cart from '../../../images/cart.svg';
import notif from '../../../images/notif.svg';
import Default from "../../../images/default.jpeg";
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import NumberFormat from 'react-number-format';
import {history} from "../../../shared/configure-store";
import queryString from 'query-string';
import Api from "../../../libraries/api";
import AuthHelper from "../../../libraries/auth-helper";

class Navbar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            fix: false,
            open: false,
            showDialog: false,
            openCart: false,
            openNotif: false,
            user: null,
            carts: [],
            notifs: [],
            totalAmount: 0,
            totalPointProduct: 0,
            loading: false,
            loadingIcon: false,
            mobile: false,

            wa: '',
            menus: [],
        };

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    componentDidMount() {
        let urlParams = queryString.parse(window.location.search);

        window.addEventListener('scroll', this.listenToScroll)
        let user = AuthHelper.getProfile();

        if(user) {
            this.setState({
                user: user,
            }, () => this.__fetch());
        }

        if(urlParams) {
            if(urlParams.mobile == 1) {
                this.setState({
                    mobile: true,
                });
            }

        }

        Api.get('/menu/main/get-menu').then(resp => {

            if(resp.data) {
                this.setState({
                        menus: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });


        Api.get('/config/by-name/AKUN_WHATSAPP').then(resp => {

            if(resp.data) {
                this.setState({
                        wa: resp.data.value,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        let yes = false;

        if(winScroll > 100) {
            yes = true;
        }
        this.setState({
            fix: yes,
        })
    };

    handleToggleSidebar(){

        if(this.props.toggle_sidebar_state){

            this.props.setHideSidebar();

        }else{

            this.props.setShowSidebar();

        }

    }

    handleGo  = (link) => {

        this.setState({
                openCart: false,
            open: false,
            }
        );
        history.push(link);
    };

    openMenu = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    handleOpenCart = (state) => {
        this.setState({
            openCart: state,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleOpenNotif = () => {
        this.setState({
            openNotif: !this.state.openNotif,
        });
    };


    handleDeleteCart = (id) => {

        if(id) {
            this.setState({
                    loadingIcon: true
                }
            );
            Api.delete('/cart/'+id).then(resp => {

                if(resp.status === 200) {

                    window.location.reload();
                }

            }).catch(err => {

                this.setState({
                    openCart: false
                    }
                );

            });
        }
    };

    __fetch = () => {

        Api.get('/cart/'+this.state.user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {

                this.setState({
                        carts: data.carts,
                        point: data.point,
                        totalAmount: data.totalAmount,
                        totalPointProduct: data.totalPointProduct,
                        loading: false,
                    loadingIcon: false,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });
        Api.get('/customer/notif/by-code/'+this.state.user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {
                AuthHelper.setNotif(data);
                this.setState({
                    notifs: data,
                        loading: false,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleLogout = () => {

        AuthHelper.logOut();

        window.location.reload();
    };

    handleOpen = () => {
        this.setState({
            showDialog :true,
        });
    };

    closeMenu = () => {
        this.setState({
            open :false,
        });
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        });
    };

    render() {
        return(
            <header className={!this.state.mobile ? this.state.fix ?'header-nav fix': 'header-nav': 'd-none'}>
                <div className='nav-top'>
                    <div className='container'>
                        <div className='row align-items-center justify-content-end'>
                            <p>Tambah Sekeping Lagi</p>
                            <div className='text-right sos-med'>
                                <a href='https://www.instagram.com/gudangemas24k/' target='_blank'>
                                    <i className="fab fa-instagram"> </i>
                                </a>
                                <a href='https://www.facebook.com/gudang.emas.1447/' target='_blank'>
                                    <i className="fab fa-facebook-f"> </i>
                                </a>
                                <a href='https://www.youtube.com/channel/UCKeUna-kl8E32E2oL_74Dkw/videos' target='_blank'>
                                    <i className="fab fa-youtube"> </i>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='nav-brand'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-12 col-md-4'>
                                <div className='row align-items-center logo-wrap'>
                                    <div className='col-6 pr-0 col-md-12'>
                                        <Link to='/' className="navbar-brand">
{/*
                                            <img src={LogoWhite} alt='logo' className='img-logo d-md-none' />
*/}
                                            <img src={Logo} alt='logo' className='img-logo' />
                                        </Link>
                                    </div>
                                    <div className='col-6 social d-md-none'>
                                        <div className="btn-group-header d-inline-block">
                                            {this.state.user &&
                                            <button className='btn-header'>
                                                <img src={cart} className='img-icon' alt='user'/>
                                                {this.state.carts.length > 0 &&
                                                <span className='tag-label'>{this.state.carts.length}</span>
                                                }
                                            </button>
                                            }
                                            <div className={this.state.openCart ? 'popup-notif open' : 'popup-notif'}>
                                                {this.state.carts.length > 0 ?
                                                    <div className='product-list'>
                                                        {this.state.carts.map(row => (
                                                            <div className='item-review align-items-center' key={row.id}>
                                                                <div className='img-wrap product'>
                                                                    <img src={row.product.image ? row.product.image : Default} alt='user' className='img-avatar' />
                                                                </div>
                                                                <div className='content'>
                                                                    <h3>{row.product.name.substring(0,20)+'...'}</h3>
                                                                    <p>1 x <strong className='text-yellow'>{row.product['regular-price']}</strong></p>
                                                                </div>
                                                                <div className='btn-action ml-auto'>
                                                                    <button className='btn-icon btn-delete' onClick={() => this.handleDeleteCart(row.id)}>
                                                                        {this.state.loadingIcon ? <i className="fa fa-spinner fa-spin"> </i> : <i className="fas fa-trash-alt"> </i>}

                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div> :
                                                    <div className='product-list'>
                                                        <div className='item-review align-items-center justify-content-center'>
                                                            <p className='text-center'>Keranjang kosong</p>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.carts.length > 0 &&
                                                <>
                                                    <div className='total'>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <p className='mb-0'>Total</p>
                                                            </div>
                                                            <div className='col-6 text-right'>
                                                                <p className='mb-0'>
                                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className='full mb-3'
                                                        onClick={() => this.handleGo('/cart')}
                                                    >
                                                        Keranjang
                                                    </Button>
                                                </>
                                                }


                                            </div>
                                        </div>
                                        {this.state.user &&
                                        <div className="btn-group-header">
                                            <button className='btn-header'>
                                                <img src={user} className='img-icon' alt='user' />
                                            </button>
                                            <div className={this.state.openNotif ? 'popup-notif menu open' : 'popup-notif menu'}>
                                                <div className='menu-list'>
                                                    <div className='item-review'>
                                                        <Link to={'/account'}>Akun Saya</Link>
                                                    </div>
                                                    <div className='item-review'>
                                                        <button className='btn-link' onClick={this.handleOpen}> Keluar</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                        <button className={this.state.open ? 'navbar-toggler open' : 'navbar-toggler'} type="button" onClick={this.openMenu}>
                                            <i className="fas fa-bars"> </i>
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md d-none d-md-block'>
                                <form className="d-flex" action='/products'>
                                    <input className="form-control me-2" type="search" name='s' placeholder="Pencarian produk" aria-label="Search" />
                                    <button className="btn btn-outline-success" type="submit">
                                        <i className="fas fa-search"> </i>
                                    </button>
                                </form>
                            </div>
                            <div className='col-6 col-md-4 social text-right d-none d-md-block pl-0'>
                                <a href={'https://api.whatsapp.com/send?phone='+this.state.wa} target='_blank' className='wa-icon d-none d-lg-inline-flex'>
                                    <i className="fab fa-whatsapp"> </i>
                                    <div className='d-none d-lg-block'>
                                        <span>No. Layanan</span>
                                        <strong>{this.state.wa}</strong>
                                    </div>
                                </a>
                                <div className="btn-group-header d-inline-block">
                                    <button className='btn-header'>
                                        <img src={cart} className='img-icon' alt='user' />
                                        {this.state.carts.length > 0 &&
                                        <span className='tag-label'>{this.state.carts.length}</span>
                                        }
                                    </button>
                                    <div className={this.state.openCart ? 'popup-notif open' : 'popup-notif'}>
                                        {this.state.carts.length > 0 ?
                                            <div className='product-list'>
                                                {this.state.carts.map(row => (
                                                    <div className='item-review align-items-center' key={row.id}>
                                                        <div className='img-wrap product'>
                                                            <img src={row.product.image ? row.product.image : Default} alt='user' className='img-avatar' />
                                                        </div>
                                                        <div className='content'>
                                                            <h3>{row.product.name.substring(0,20)+'...'}</h3>
                                                            <p>1 x <strong className='text-yellow'>{row.product['regular-price']}</strong></p>
                                                        </div>
                                                        <div className='btn-action ml-auto'>
                                                            <button className='btn-icon btn-delete' onClick={() => this.handleDeleteCart(row.id)}>
                                                                {this.state.loadingIcon ? <i className="fa fa-spinner fa-spin"> </i> : <i className="fas fa-trash-alt"> </i>}

                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div> :
                                            <div className='product-list'>
                                                <div className='item-review align-items-center justify-content-center'>
                                                    <p className='text-center'>Keranjang kosong</p>
                                                </div>
                                            </div>
                                        }
                                        {this.state.carts.length > 0 &&
                                        <>
                                            <div className='total'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Total</p>
                                                    </div>
                                                    <div className='col-6 text-right'>
                                                        <p className='mb-0'>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className='full mb-3'
                                                onClick={() => this.handleGo('/cart')}
                                            >
                                                Keranjang
                                            </Button>
                                            {/* <Button
                                variant="contained"
                                color="secondary"
                                className='full mb-3'
                                onClick={() => this.handleGo('/checkout')}
                            >
                                Checkout
                            </Button>*/}
                                        </>
                                        }


                                    </div>
                                </div>

                                {this.state.user &&
                                <div className="btn-group-header d-none d-md-inline-block">
                                <button className='btn-header'>
                                    <img src={user} className='img-icon' alt='user' />
                                </button>
                                    <div className={this.state.openNotif ? 'popup-notif menu open' : 'popup-notif menu'}>
                                        <div className='menu-list'>
                                            <div className='item-review'>
                                                <Link to={'/account'}>Akun Saya</Link>
                                            </div>
                                            <div className='item-review'>
                                                <button className='btn-link' onClick={this.handleOpen}> Keluar</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>}

                                {this.state.user &&
                                <div className="btn-group-header d-none d-md-inline-block">
                                    <button className='btn-header'>
                                        <img src={notif} className='img-icon' alt='user'/>
                                        {/*{this.state.notifs.length > 0 &&
                                        <span className='tag-label'>{this.state.notifs.length}</span>
                                        }*/}
                                    </button>
                                    <div className={this.state.openNotif ? 'popup-notif notif open' : 'popup-notif notif'}>
                                        {this.state.notifs.length > 0 ?
                                            <div className='product-list notif text-left'>
                                                {this.state.notifs.map(row => (
                                                    <div className='item-review align-items-center' key={row.id}>
                                                        <div className='content'>
                                                            <time>{row.topic} - {row.send_date}</time>
                                                            <h3>{row.title}</h3>
                                                            <p>{row.message}</p>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div> :
                                            <div className='product-list'>
                                                <div className='item-review align-items-center justify-content-center'>
                                                    <p className='text-center'>Notifikasi kosong</p>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
{/*
                <div className={this.state.openNotif ? 'popup-notif notif open' : 'popup-notif'}>
                    {this.state.notifs.length > 0 ?
                        <div className='product-list notif'>
                            {this.state.notifs.map(row => (
                                <div className='item-review align-items-center' key={row.id}>
                                    <div className='content'>
                                        <time>{row.topic} - {row.send_date}</time>
                                        <h3>{row.title}</h3>
                                        <p>{row.message}</p>
                                    </div>
                                </div>
                            ))
                            }
                        </div> :
                        <div className='product-list'>
                            <div className='item-review align-items-center justify-content-center'>
                                <p className='text-center'>Notifikasi kosong</p>
                            </div>
                        </div>
                    }

                </div>
*/}
                <div className={this.state.open && this.state.fix ? 'nav-main fix open ': this.state.open ? 'nav-main open' : this.state.fix ? 'nav-main fix' : 'nav-main'}>
                    <div className='container'>
                        <nav className={this.state.open ? 'navbar navbar-expand-md' : 'navbar navbar-expand-md'}>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <Link to='/' className="navbar-brand d-none d-md-block">
                                        <img src={LogoWhite} alt='logo' className='img-logo' style={{width:65}} />
                                    </Link>
                                    {this.state.menus.length > 0 &&
                                    this.state.menus.map((row, id) => (
                                        <li className="nav-item" key={id}>
                                            {row.childs.length > 0 ?
                                            <>
                                                <span className='nav-link' onClick={() => this.handleGo(row.url)}>
                                                    {row.parent} <i className="fas fa-caret-down"> </i>
                                                </span>
                                                <ul className='sub-menu'>
                                                    { row.childs.map((item, i) => (
                                                        <li className="nav-item" key={i}>
                                                            <Link to={item.url} className='nav-link' onClick={this.closeMenu}>
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}

                                                </ul>
                                            </> :
                                                <Link to={row.url} className='nav-link' onClick={this.closeMenu}>
                                                    {row.parent}
                                                </Link>
                                            }
                                        </li>
                                    ))}


                                    <li className="nav-item icon-img btn-group-header ml-auto social d-none d-md-block">
                                        <button className='btn-header' onClick={this.handleOpenCart}>
                                            <img src={cart} className='img-icon' alt='user' />
                                            {this.state.carts.length > 0 &&
                                            <span className='tag-label'>{this.state.carts.length}</span>
                                            }
                                            </button>
                                        <div className={this.state.openCart ? 'popup-notif open' : 'popup-notif'}>
                                            {this.state.carts.length > 0 ?
                                                <div className='product-list'>
                                                    {this.state.carts.map(row => (
                                                        <div className='item-review align-items-center' key={row.id}>
                                                            <div className='img-wrap product'>
                                                                <img src={row.product.image ? row.product.image : Default} alt='user' className='img-avatar' />
                                                            </div>
                                                            <div className='content'>
                                                                <h3>{row.product.name.substring(0,20)+'...'}</h3>
                                                                <p>1 x <strong className='text-yellow'>{row.product['regular-price']}</strong></p>
                                                            </div>
                                                            <div className='btn-action ml-auto'>
                                                                <button className='btn-icon btn-delete' onClick={() => this.handleDeleteCart(row.id)}>
                                                                    <i className="fas fa-trash-alt"> </i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div> :
                                                <div className='product-list'>
                                                    <div className='item-review align-items-center justify-content-center'>
                                                        <p className='text-center'>Keranjang kosong</p>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.carts.length > 0 &&
                                            <>
                                                <div className='total'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <p className='mb-0'>Total</p>
                                                        </div>
                                                        <div className='col-6 text-right'>
                                                            <p className='mb-0'>
                                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='full mb-3'
                                                    onClick={() => this.handleGo('/cart')}
                                                >
                                                    Keranjang
                                                </Button>
                                                {/* <Button
                                variant="contained"
                                color="secondary"
                                className='full mb-3'
                                onClick={() => this.handleGo('/checkout')}
                            >
                                Checkout
                            </Button>*/}
                                            </>
                                            }


                                        </div>
                                    </li>
                                    {this.state.user &&
                                    <li className="nav-item icon-img social btn-group-header d-none d-md-block">
                                        <button className='btn-header'>
                                            <img src={user} className='img-icon' alt='user'/>
                                        </button>
                                        <div className={this.state.openNotif ? 'popup-notif menu open' : 'popup-notif menu'}>
                                            <div className='menu-list'>
                                                <div className='item-review'>
                                                    <Link to={'/account'}>Akun Saya</Link>
                                                </div>
                                                <div className='item-review'>
                                                    <button className='btn-link' onClick={this.handleOpen}> Keluar</button>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    }

                                </ul>
                            </div>
                        </nav>
                    </div>

                </div>

                <div className={this.state.open ? 'modal-overlay' : 'modal-overlay closed'} onClick={this.handleClose}> </div>
                <a href={'https://api.whatsapp.com/send?phone='+this.state.wa}  target='_blank' className='float-wa'>
                    <i className="fab fa-whatsapp"> </i>
                </a>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Apakah Anda yakin ingin keluar?</DialogTitle>
                    <DialogContent>


                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue" onClick={this.handleLogout}>
                            Ya, keluar
                        </button>
                    </DialogActions>
                </Dialog>
            </header>
        )

    }

}

const mapStateToProps = (state) => {

    return {

        cart_state: state.cart_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);