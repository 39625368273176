import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import NumberFormat from 'react-number-format';
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';
import Default from "../../../images/default.jpeg";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {toast, ToastContainer} from "react-toastify";
import Skeleton from "@material-ui/lab/Skeleton";
import _ from "lodash";


require('dotenv').config();

const validator = new SimpleReactValidator();
const validatorCoupon = new SimpleReactValidator();

class Cart extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            errors: {},
            anchorEl: null,

            showDialogRedeem: false,
            showDialogCoupon: false,
            showDialogAccount: false,
            showDialogAddress: false,
            loadingIcon: false,
            user: {},
            carts: [],
            totalAmount: 0,
            totalPointProduct: 0,
            discountCoupon: 0,
            discountRedeem: 0,
            date: null,
            total: 0,
            pointAll: 0,

            obsolete: false,

            redeempoint: '',
            coupon: '',
            address: '',
            zipCode: '',
            province: '',
            city: '',
            subDistrict: '',

            isFree: false,

        };

        this.handleChange = this.handleChange.bind(this);

    }


    componentDidMount() {

        document.title = 'Gudangemas - Keranjang';
        let user = AuthHelper.getProfile();
        let date = Date.now();
        window.scrollTo(0, 0);
        if(user) {

            this.setState({
                user: user,
                date: date,
            }, () => this.__fetch());

            Api.get('/billing-address/'+user['customer-code']).then(resp => {

                let data = resp.data;
                if (data) {
                    this.setState({
                            address: data.address,
                            zipCode: data['postal-code'],
                            province:  data['province'].province,
                            city: data['city'].name,
                            subDistrict: data['sub-district'].name,
                        }
                    );
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleClose = () => {

        this.setState({
            anchorEl: null
        })
    };

    handleOpenRedeem = (row) => {
        this.setState({
            showDialogRedeem :true,
        })
    };

    handleCloseRedeem = () => {
        this.setState({
            showDialogRedeem :false,
        })
    };

    handleOpenCoupon = (row) => {
        this.setState({
            showDialogCoupon :true,
        })
    };

    handleCloseCoupon = () => {
        this.setState({
            showDialogCoupon :false,
        })
    };

    handleCloseAccount = () => {
        this.setState({
            showDialogAccount :false,
        })
    };

    handleDeleteCart = (id) => {

        if(id) {
            this.setState({
                    loadingIcon: true
                }
            );
            Api.delete('/cart/'+id).then(resp => {

                if(resp.status === 200) {
                    window.location.reload();

                }

            }).catch(err => {

                this.setState({
                    loading: false
                    }
                );

            });
        }
        console.log(id)
    };

    __fetch = () => {
        this.setState({
                loading: true,
            }
        );

        Api.get('/cart/'+this.state.user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {

                let products = [];
                let all = 0;
                let obs = false;

                _.forEach(data.carts, function (o) {
                    all += (parseFloat(o.quantity*o.product['product-fixed-point']))

                    if(o['is-disabled'] === true) {
                        obs = true;
                    }
                    products.push({
                        id: o.id,
                        sku: o.sku,
                        image: o.product.image ? o.product.image : Default,
                        name: o.product.name ? o.product.name : '',
                        weight: o.product.weight ? o.product.weight : '',
                        attribute: o.product['atribute-value']  ? o.product['atribute-value'] : '',
                        stock: o.product['stock-quantity'] ? o.product['stock-quantity'] : '',
                        price: o.price,
                        totalPrice: o.totalPrice,
                        quantity: o.quantity,
                        isDisabled: o['is-disabled'],
                    })

                });

                /*let products = data.carts.map(o => {
                    return {
                        id: o.id,
                        sku: o.sku,
                        image: o.product.image ? o.product.image : Default,
                        name: o.product.name ? o.product.name : '',
                        weight: o.product.weight ? o.product.weight : '',
                        attribute: o.product['atribute-value']  ? o.product['atribute-value'] : '',
                        stock: o.product['stock-quantity'] ? o.product['stock-quantity'] : '',
                        price: o.price,
                        totalPrice: o.totalPrice,
                        quantity: o.quantity,
                    }
                });*/
                this.setState({
                        carts: products,
                        pointAll: all,
                        point: data.point,
                        totalAmount: data.totalAmount,
                        total: data.total,
                        totalPointProduct: data.totalPointProduct,
                        loading: false,
                        obsolete: obs,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handlePlus = (e, row) => {
        e.preventDefault();

        if ((row.quantity + 1) <= row.stock) {
            row.quantity = row.quantity + 1;
        } else {

            this.showMessage(false, 'Stok tidak mencukupi.');
            return false;
        }

        this.setState({
                loadingIcon: true,
            }
        );

        let params = {
            'customer-code': this.state.user['customer-code'],
            id: row.id,
            price: row.price,
            quantity: row.quantity,
            sku: row.sku,
        };

        Api.put('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.__fetch();
                this.setState({
                    loadingIcon: false,
                    }
                );
            }

            this.showMessage(true, 'Keranjang berhasil diperbaharui');

        }).catch(err => {

            this.setState({
                loadingIcon: false
                }
            );
        });
    };

    handleMinus = (e, row) => {
        e.preventDefault();
        this.setState({
                loadingIcon: true,
            }
        );
        let params = {
            'customer-code': this.state.user['customer-code'],
            id: row.id,
            price: row.price,
            quantity: row.quantity-1,
            sku: row.sku,
        };

        Api.put('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.__fetch();
                this.setState({
                    loadingIcon: false,
                    }
                );
            }

            this.showMessage(true, 'Keranjang berhasil diperbaharui');

        }).catch(err => {

            this.setState({
                loadingIcon: false
                }
            );
        });
    };

    handleUpdate = (e, row) => {
        e.preventDefault();

        this.setState({
                loadingIcon: true,
            }
        );
        let params = {
            'customer-code': this.state.user['customer-code'],
            id: row.id,
            price: row.price,
            quantity: row.quantity,
            sku: row.sku,
        };

        Api.put('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.__fetch();
                this.setState({
                    loadingIcon: false,
                    }
                );
            }

            this.showMessage(true, 'Harga berhasil diperbaharui');

        }).catch(err => {

            this.setState({
                loadingIcon: false
                }
            );
        });
    };

    handleSubmitPoint = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'customer-code': this.state.user['customer-code'],
            'redeem-point': this.state.redeempoint,
            'coupon-code': this.state.coupon,
            'total-price': this.state.totalAmount,
        };


        if(parseFloat(this.state.redeempoint) > parseFloat(this.state.point)) {
            this.showMessage(false, 'Redeem poin melebihi Poin yang Anda miliki');

            this.setState({
                    loadingButton: false,
                    showDialogRedeem: false,
                }
            );

            return false;
        }

        Api.put('/order/redeem', params).then(resp => {

            if(resp.status === 200) {

                this.showMessage(true, 'Poin Redeem berhasil');
                this.setState({
                    discountRedeem: resp.data['redeem-point']
                });

            }else {

                this.setState({
                    redeempoint: '',
                    }
                );
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loadingButton: false,
                showDialogRedeem: false,
                }
            );

        }).catch(err => {

            this.setState({
                loadingButton: false,
                showDialogRedeem: false
                }
            );
        });
    };

    handleSubmitCoupon = (e) => {
        e.preventDefault();

        if (!validatorCoupon.allValid()) {

            this.setState({
                    errors: validatorCoupon.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'customer-code': this.state.user['customer-code'],
            'coupon-code': this.state.coupon,
            'redeem-point': this.state.pointAll,
            'total-price': this.state.totalAmount,
        };


        Api.put('/order', params).then(resp => {

            if(resp.status === 200) {

                this.showMessage(true, 'Kupon Redeem berhasil');

                this.setState({
                    discountCoupon: resp.data['discount-coupon'] !== null ? resp.data['discount-coupon'] : 0,
                    isFree:  resp.data['is-freeongkir']
                });
            }else {
                this.showMessage(false, resp.message[0]);
                this.setState({
                    coupon: '',
                    }
                );
            }
            this.setState({
                    loadingButton: false,
                showDialogCoupon: false,
                }
            );

        }).catch(err => {

            this.setState({
                    loadingButton: false,
                showDialogCoupon: false
                }
            );
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleCheckout = () => {


        if(!this.state.address || !this.state.zipCode || !this.state.province || !this.state.city || !this.state.subDistrict) {
            this.setState({
                showDialogAddress: true,
                }
            );
            return false;
        }

        if(!this.state.user['full-name'] || !this.state.user['email'] || !this.state.user['phone-number']) {
            this.setState({
                showDialogAccount: true,
                }
            );
            return false;
        }

        if(this.state.obsolete) {
            this.showMessage(false, 'Mohon lakukan update harga tiap produk di keranjang');
            return false;
        }

        if(this.state.discountCoupon || this.state.discountRedeem || this.state.isFree) {
            history.push({
                pathname: 'checkout',
                state: {
                    coupon: this.state.coupon,
                    discountCoupon: this.state.discountCoupon,
                    redeempoint: this.state.redeempoint,
                    isFree: this.state.isFree,
                    discountRedeem: this.state.discountRedeem,
                }
            });
        }else {
            history.push('checkout');
        }

    };

    render() {
        const total = parseFloat(this.state.totalAmount) - parseFloat(this.state.discountCoupon) - parseFloat(this.state.discountRedeem);
        return (
            <main className='products'>
                <div className='container'>
                    <section className='row section-products'>
                        <div className='col-lg-7'>
                            <div className='section-title'>
                                <h2>PRODUK</h2>
                            </div>
                            {this.state.obsolete &&
                            <div className='alert alert-info mb-3'>
                                <p className='mb-0'><i className="fas fa-exclamation-circle"> </i> Silahkan update harga di keranjang dengan klik button reload</p>
                            </div>
                            }

                            <div className='review-wrapper'>
                                {this.state.loading ?
                                    <div className='item-review align-items-center'>
                                        <div className='img-wrap product mb-3'>
                                            <Skeleton variant="rect" width={120} height={120} />

                                        </div>
                                        <div className='content col-md-4'>
                                            <Skeleton variant="text" />

                                            <Skeleton variant="text" />


                                            <Skeleton variant="text" />

                                        </div>
                                        <div className='btn-action ml-auto'>
                                            <Skeleton variant="rect" width={30} height={30} />
                                        </div>
                                    </div> :
                                    this.state.carts.length > 0 ?
                                        this.state.carts.map((row, id) => (
                                            <div className='item-review align-items-center' key={id}>
                                                <div className='img-wrap product mb-3'>
                                                    <img src={row.image} alt='user' className='img-avatar' />
                                                </div>
                                                <div className='content'>
                                                    <h3>{row.name} {row.attribute ? '- '+row.attribute : ''}</h3>
                                                    <small className='d-block'>SKU: {row.sku}</small>
                                                    <strong className='text-yellow'>
                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </strong>

                                                    <div className='btn-group my-3' style={{maxWidth:125}}>
                                                        <button className='btn-minus'
                                                                disabled={row.quantity <= 1 ? true : false}
                                                                onClick={(e) => this.handleMinus(e, row)}>
                                                            <i className="fas fa-minus-circle"> </i>
                                                        </button>
                                                        <input type='text' value={row.quantity} readOnly />
                                                        <button className='btn-plus'
                                                                onClick={(e) => this.handlePlus(e, row)}>
                                                            <i className="fas fa-plus-circle"> </i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='btn-action ml-auto'>
                                                    {row.isDisabled &&
                                                    <button className='btn-icon btn-reload'
                                                            onClick={(e) => this.handleUpdate(e, row)}>
                                                        {this.state.loadingIcon ?
                                                            <i className="fa fa-spinner fa-spin"> </i> :
                                                            <i className="fas fa-sync"> </i>}
                                                    </button>
                                                    }
                                                    <button className='btn-icon btn-delete'
                                                            onClick={() => this.handleDeleteCart(row.id)}>
                                                        {this.state.loadingIcon ? <i className="fa fa-spinner fa-spin"> </i> : <i className="fas fa-trash-alt"> </i>}
                                                    </button>

                                                </div>
                                            </div>
                                        ))
                                        :
                                            <div className='item-review align-items-center justify-content-center'>
                                                <div className='content'>
                                                    <h3>Keranjang kosong</h3>
                                                </div>
                                            </div>
                                }


                            </div>
                        </div>
                        <div className='col-lg-5'>

                            <div className='box-cart'>
                                <div className='alert alert-poin mb-3'>
                                    <p className='mb-0 text-center'>Kamu akan dapatkan point reward sebesar:<br />
                                        <strong>{this.state.pointAll}</strong> jika menyelesaikan order ini.
                                    </p>
                                </div>
                                <div className='border-bottom'>
                                    <h2>Ringkasan Belanja</h2>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col-8'>
                                        <p className='mb-2'>Total belanja</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p className='mb-2'>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                {this.state.discountCoupon > 0 ?
                                <div className='row'>
                                    <div className='col-8'>
                                        <p className='mb-2'>Diskon Kupon</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p className='mb-2'>
                                            Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.discountCoupon} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div> : ''
                                }
                                {this.state.discountRedeem > 0 ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p className='mb-2'>Nilai Redeem</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p className='mb-2'>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.discountRedeem} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div> : ''
                                }

                                <div className='row pt-3 border-top'>
                                    <div className='col-8'>
                                        <p className='mb-2'><strong>Total</strong></p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        {total < 0 ?
                                            <p className='mb-2'>
                                                <small className='discount-price d-block'>
                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false}
                                                                     value={total} displayType={'text'} />
                                                </small>
                                                <strong> Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false}
                                                                     value={0} displayType={'text'}
                                                                     renderText={value => <span>{value}</span>}/>
                                                </strong>
                                            </p>
                                            :
                                            <p className='mb-2'>
                                                <strong>
                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false}
                                                                     value={total} displayType={'text'}
                                                                     renderText={value => <span>{value}</span>}/>
                                                </strong>
                                            </p>
                                        }
                                    </div>
                                </div>
                                {total < 0 &&
                                <div className='alert'>
                                    <p>Nilai poin Anda lebih besar dari total harga. Apakah Anda tetap yakin ingin me redeem semua poin yang anda miliki?</p>
                                </div>
                                }
                                <button className='btn-redeem' type='button' onClick={() => this.handleOpenCoupon()}>
                                    <i className="fas fa-tag"> </i>
                                    <span>{this.state.isFree ? 'Free Ongkir' : 'Masukan kupon belanja disini'}</span>
                                    { (this.state.discountCoupon > 0 || this.state.isFree ) &&
                                    <i className="fas fa-check-circle valid"> </i>
                                    }
                                </button>
                                <button className='btn-redeem' type='button'
                                        onClick={() => this.handleOpenRedeem()}
                                        disabled={this.state.point <= 0}>
                                    <i className="icon-point">P</i>
                                    <span>Redeem poin</span>
                                    { this.state.discountRedeem > 0 &&
                                    <i className="fas fa-check-circle valid"> </i>
                                    }
                                </button>

                                <p className='text-right text-yellow'><strong>Kamu memiliki {this.state.point} poin</strong></p>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='full'
                                    onClick={this.handleCheckout}
                                >
                                    Checkout
                                </Button>
                            </div>
                        </div>
                    </section>
                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialogRedeem}
                        onClose={this.handleCloseRedeem}
                        className='ge-dialog'
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Masukkan jumlah poin yang ingin digunakan</DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="form-group">
                                    <label>Poin</label>
                                    <TextField variant="outlined"
                                               type='number'
                                               id='redeempoint'
                                               name="redeempoint"
                                               label="Poin"
                                               onChange={(e) => this.handleChange(e, 'redeempoint')}
                                               value={this.state.redeempoint}
                                               fullWidth
                                    />
                                    {validator.message('redeempoint', this.state.redeempoint, 'required')}
                                    <div className='text-danger'>{this.state.errors.redeempoint}</div>
                                </div>
                                <strong className='text-yellow'>Kamu memiliki {this.state.point} poin</strong>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleCloseRedeem}>
                                BATAL
                            </button>
                            <button className="btn btn-blue" onClick={this.handleSubmitPoint}
                                    disabled={this.state.loadingButton }>
                                GUNAKAN {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                            </button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialogCoupon}
                        onClose={this.handleCloseCoupon}
                        className='ge-dialog'
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Masukkan kode voucher kamu</DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="form-group">
                                    <label>Kode</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='coupon'
                                               name="coupon"
                                               label="Kupon"
                                               onChange={(e) => this.handleChange(e, 'coupon')}
                                               value={this.state.coupon}
                                               fullWidth
                                    />
                                    {validatorCoupon.message('coupon', this.state.coupon, 'required')}
                                    <div className='text-danger'>{this.state.errors.coupon}</div>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleCloseCoupon}>
                                BATAL
                            </button>
                            <button className="btn btn-blue" onClick={this.handleSubmitCoupon}
                                    disabled={this.state.loadingButton}>
                                GUNAKAN {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                            </button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialogAddress}
                        onClose={this.handleCloseAccount}
                        className='ge-dialog'
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Informasi Alamat penagihan masih belum lengkap, silahkan lengkapi akun Anda terlebih dahulu</DialogTitle>
                        <DialogContent>

                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue" onClick={() => this.handleGo('/account')}
                            >
                                Pengaturan Akun
                            </button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialogAccount}
                        onClose={this.handleCloseAccount}
                        className='ge-dialog'
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Informasi Pribadi  masih belum lengkap, silahkan lengkapi akun Anda terlebih dahulu</DialogTitle>
                        <DialogContent>

                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue" onClick={() => this.handleGo('/account')}
                            >
                                Pengaturan Akun
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);