import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Produk from '../../../images/product.png';
import Cart from '../../../images/product-cart.svg';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Skeleton from "@material-ui/lab/Skeleton";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Api from '../../../libraries/api';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import AuthHelper from '../../../libraries/auth-helper';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {toast, ToastContainer} from "react-toastify";
import queryString from 'query-string';
import moment from "moment/moment";
import templateId from "../../../libraries/lang-id";

SimpleReactValidator.addLocale('indo', templateId);

require('dotenv').config();

const validator = new SimpleReactValidator({
    locale: 'indo'
});

class Products extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            errors: {},
            anchorEl: null,

            rows: [],
            total: 0,
            perPage: 12,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            categories: [],
            subcategories: [],

            cat: '',
            sub: '',
            search: '',

            showDialogCart: false,
            showDialogLogin: false,

            username: '',
            password: '',

            config: '',
            configText: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);

    }

    componentDidMount() {
        document.title = 'Gudangemas - Produk';
        let user = AuthHelper.getProfile();
        let date = Date.now();
        window.scrollTo(0, 0);
        if(user) {
            this.setState({
                    user: user,
                    date: date
                }
            );
        }

        let url = this.props.location.search;
        let urlParams = queryString.parse(url);

        Api.get('/product-category/all-active').then(resp => {

            if (resp.data) {

                this.setState({
                        categories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/2').then(resp => {

            if (resp.data) {
                this.setState({
                    config: resp.data.value ? resp.data.value : 'OPEN',
                    loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/6').then(resp => {

            if (resp.data) {
                this.setState({
                    configText: resp.data.value ? resp.data.value : '',
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        /*Api.get('/product-sub-category').then(resp => {

            if (resp.data) {

                this.setState({
                        subcategories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

*/

        if(urlParams) {
            this.setState({
                    cat: urlParams.cat ? urlParams.cat : '',
                    sub: urlParams.sub ? urlParams.sub : '',
                search: urlParams.s ? urlParams.s : '',
            },() => {
                if(this.state.cat) {
                    this.__fetchSub();
                }
                this.__fetchData(true);
            });
        }else {
            this.__fetchData(true);
        }

    }

    componentWillReceiveProps() {
        setTimeout(function () {
            let url = this.props.location.search;
            let urlParams = queryString.parse(url);

            if(urlParams) {
                this.setState({
                    cat: urlParams.cat ? urlParams.cat : '',
                    sub: urlParams.sub ? urlParams.sub : '',
                    search: urlParams.s ? urlParams.s : '',
                },() => {
                    if(this.state.cat) {
                        this.__fetchSub();
                    }
                    this.__fetchData(true);
                });
            }else {
                this.__fetchData(true);
            }

        }.bind(this), 1);
    }


    __fetchData = update => {
        let page = update ? 0 : this.state.currentPage;

        this.setState({
                loading: true
            }
        );
        let params = {
            page: page,
            size: this.state.perPage,
            ['search-by']: this.state.search,
            ['category-id']: this.state.cat,
            ['is-active']: true,
            ['sub-category-id']: this.state.sub,
            "sort": [
                {
                    "column-name": "sku",
                    "is-ascending": true
                }
            ],
        };

        Api.post('/product/view', params).then(resp => {

            let now = moment(Date.now());

            if(resp.data) {
                let products = resp.data.viewProducts.map(o => {
                    let start = moment(o['sale-start-date'],'DD/MM/YYYY');
                    let end = moment(o['sale-end-date'],'DD/MM/YYYY');

                    return {
                        id: o.id,
                        sku: o.sku,
                        image: o.image,
                        stock: parseFloat(o.stock),
                        rating: parseFloat(o.rating),
                        weight: o.weight,
                        category: o['category-name'],
                        sub: o['sub-catgeory-name'],
                        name: o['product-name'],
                        price: o['regular-price'],
                        priceSale: o['sale-price'],
                        isSale: moment(now).isBetween(start, end),
                        startSale: o['sale-start-date'],
                        endSale: o['sale-end-date'],
                        type: o['product-type-name'],
                        code: o['product-type-code'],
                        quantity: 0,
                    }
                });
                this.setState({
                        rows: products,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    handleChange (e, prop){
        let value = e.target.value;
        if(prop === 'username') {
            this.setState({
                [prop]: value.replace(/\s/g, '')
            })
        }else {
            this.setState({
                [prop]: e.target.value
            })
        }

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleCloseCart = () => {
        this.setState({
            showDialogCart :false,
        });
        window.location.reload();
    };

    handleClose = () => {

        this.setState({
            anchorEl: null
        })
    };

    handlePlus = (e, id) => {
        e.preventDefault();
        let data = this.state.rows;
        let message = '';
        let pop = false;
        _.forEach(data, function (v, k) {
            if (v.id === id) {
                if(v.code === 'VP') {
                    pop = true;
                    message = 'Pilih variasi terlebih dahulu di detil produk';
                }else {
                    if ((v.quantity + 1) <= v.stock) {
                        v.quantity = v.quantity + 1;
                    } else {
                        pop = true;
                        message = 'Stok tidak mencukupi.';
                    }
                }

            }
        });

        if (pop) {
            this.showMessage(false, message);
        }

        this.setState({
            rows: data,
        });
    };

    handleMinus = (e, id) => {
        e.preventDefault();
        let data = this.state.rows;


        _.forEach(data, function (v, k) {
            if (v.id === id) {
                v.quantity = v.quantity - 1;
            }
        });

        this.setState({
            rows: data,
        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleCart = (e, row) => {
        let profile = AuthHelper.getProfile();

        if(!profile) {
            this.setState({
                showDialogLogin: true,
            });
            return false;
        }

        let params = {
            'customer-code': this.state.user['customer-code'],
            price: row.isSale ? row.priceSale : row.price,
            quantity: row.quantity,
            sku: row.sku,
        };

        Api.post('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.setState({
                    showDialogCart: true,
                    }
                );

                this.props.setShowCart();
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loading: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleCat (e, prop){

        if(e.target.value === '') {
            window.location.replace('/products');

            this.setState({
                subcategories: [],
            });
        }

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchData(true);

            if(prop === 'cat' && e.target.value !== '') {
                window.location.replace('/products?cat='+e.target.value);

                this.__fetchSub();
            }
        });

    };

    handleSub = (e, prop) => {

        this.setState({

            sub: prop

        },() => {
            if(prop === '') {
                window.location.replace('/products?cat='+this.state.cat);
            }else {
                window.location.replace('/products?cat='+this.state.cat+'&sub='+prop);
            }
            this.__fetchData(true);
        });
    };

    __fetchSub = () => {
        Api.get('/product-sub-category/category-id/'+this.state.cat).then(resp => {

            if (resp.data) {

                this.setState({
                        subcategories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleCloseLogin = () => {
        this.setState({
            showDialogLogin: false,
        });
    };

    handleLogIn = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
            loadingButton: true,
            }
        );

        let params = {

            username: this.state.username,

            password: this.state.password,

        };

        Api.post('/user/authenticate', params).then(resp => {
            if(resp.status == 200) {
                AuthHelper.setLogin(resp.data);

                AuthHelper.setProfile(resp.data);
                window.location.reload();

            }else {
                this.showMessage(false, resp.message[0]);

            }

            this.setState({
                user: resp.data,
                loadingButton: false,
                showDialogLogin: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err,
                loadingButton: false
                }
            );

        });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };


    handleGo  = (link) => {
        window.location.href = link;
        //history.push(link);
    };

    handleSearch = () => {
        this.__fetchData(true);
    };

    render() {

        const Star = (item) => {
            const items = [];

            for (let i = 1; i <= 5 ; i++) {
                items.push(
                    <span key={i} className={i <= item.item ? 'star-icon yes' : 'star-icon'}>
                    <i className="fas fa-star"> </i>
                </span>
                )
            }
            return items;
        };
        return (
            <main className='products'>
                <div className='container'>
                    {this.state.config === 'CLOSE' &&
                    <div className='alert'>
                        <p className='mb-0'><i className="fas fa-exclamation-circle mr-2"></i> {this.state.configText}</p>
                    </div>
                    }

                    <section className='row section-products'>
                        <div className='col-md-6 col-xl-4 pr-lg-4 pr-xl-5'>
                            <div className='filter-title'>
                                <h2>CARI</h2>
                            </div>
                            <div className="d-flex mb-3 search-filter">
                                <input className="form-control me-2 "
                                       value={this.state.search}
                                       onChange={(e) => this.handleChange(e, 'search')}
                                       type="search" placeholder="Cari produk" aria-label="Cari" />
                                <button className="btn btn-outline-success" type="button" onClick={this.handleSearch}>
                                    <i className="fas fa-search"> </i>
                                </button>
                            </div>

                            <div className='filter-title'>
                                <h2>KATEGORI</h2>
                            </div>

                            <TextField variant="outlined"
                                       select
                                       id="cat"
                                       name="cat"
                                       label="Kategori"
                                       className='mb-3'
                                       onChange={(e) => this.handleCat(e, 'cat')}
                                       value={this.state.cat}
                                       fullWidth
                            >
                                <MenuItem value=''>
                                    Semua
                                </MenuItem>
                                {this.state.categories.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div className='filter-title'>
                                <h2>TAG</h2>
                            </div>
                            <div className='tags-filter'>
                                <ul className='tags'>
                                    <li className={this.state.sub === '' ? 'active' : ''}
                                        onClick={(e) => this.handleSub(e, '')}>Semua</li>
                                    {this.state.subcategories.map(option => (
                                        <li className={this.state.sub == option.id ? 'active' : ''} key={option.id}
                                        onClick={(e) => this.handleSub(e, option.id)}>{option.name}</li>

                                    ))}
                                </ul>
                            </div>

                        </div>
                        <div className='col-xl-8'>
                            <div className='row sort-filter mx-0 align-items-center justify-content-between'>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={this.state.total}
                                    rowsPerPage={this.state.perPage}
                                    page={this.state.currentPageTable}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                                {/*<div className='col-lg-3 border-left text-right'>
                                    <p className='mb-0' onClick={this.handleClick}>Sorting <i className="fas fa-chevron-down ml-2"></i></p>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                                        <MenuItem onClick={this.handleClose}>My account</MenuItem>
                                        <MenuItem onClick={this.handleClose}>Logout</MenuItem>
                                    </Menu>
                                </div>*/}
                            </div>
                            {this.state.loading ?
                                <div className='row'>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-4 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>

                                </div>
                                :
                                this.state.rows.length > 0 &&
                                <div className='row'>
                                    {this.state.rows.map((row, id) => (
                                        <div className='col-6 col-md-4' key={id}>
                                            <div className='card-product'>
                                                <Link to={'/products/'+row.sku}>
                                                    <div className='card-img'>
                                                        <label>{row.sub}</label>
                                                        <img src={row.image !== null ? row.image : Produk} alt='banner' />
                                                    </div>
                                                </Link>

                                                <div className='card-body'>
                                                    <small>{row.category}</small>
                                                    <Link to={'/products/'+row.sku}>
                                                        {row.name &&
                                                        <h4>{(row.name.length > 30) ? row.name.substring(0,30)+'...' : row.name}</h4>
                                                        }
                                                    </Link>
                                                    {/*<div className='star-wrap'>
                                                        <Star item={row.rating} />
                                                    </div>*/}
                                                    {row.stock > 0 ?
                                                        <div className='price'>
                                                            {row.isSale ?
                                                                <>
                                                                    <p className='discount-price'>
                                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                    </p>
                                                                    <p>
                                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.priceSale} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                    </p>
                                                                </>
                                                                :
                                                                <p>
                                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </p>
                                                            }
                                                        </div> :
                                                        <div className='price'>
                                                            <p>HABIS</p>
                                                        </div>
                                                    }



                                                    <div className='btn-group'>
                                                        <button className='btn-minus'
                                                                disabled={row.quantity <= 0 ? true : false}
                                                                onClick={(e) => this.handleMinus(e, row.id)}>
                                                            <i className="fas fa-minus-circle"> </i>
                                                        </button>
                                                        <input type='text' value={row.quantity} readOnly />
                                                        <button className='btn-plus'
                                                            /*disabled={row.stock < row.quantity ? true : false}*/
                                                                onClick={(e) => this.handlePlus(e, row.id)}>
                                                            <i className="fas fa-plus-circle"> </i>
                                                        </button>
                                                    </div>

                                                    <button className='btn btn-bottom'
                                                            disabled={(row.quantity <= 0 || this.state.config === 'CLOSE') ? true : false}
                                                            onClick={(e) => this.handleCart(e, row)}>
                                                        <i className="fas fa-shopping-cart"> </i> Beli</button>
                                                    </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            }
                            <div className='row sort-filter mx-0 align-items-center justify-content-between'>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={this.state.total}
                                    rowsPerPage={this.state.perPage}
                                    page={this.state.currentPageTable}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </div>
                        </div>
                    </section>
                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogLogin}
                    onClose={this.handleCloseLogin}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Silakan masuk ke dalam akun untuk melanjutkan transaksi kamu</DialogTitle>
                    <DialogContent>
                        <form name='login' id='dialogLogin' noValidate>
                            <div className='form-group'>
                                <TextField variant='outlined'
                                           type='text'
                                           id='username'
                                           name='username'
                                           label='Username'
                                           placeholder='exp: johndoe/john@mail.com'
                                           onChange={(e) => this.handleChange(e, 'username')}
                                           value={this.state.username}
                                           fullWidth
                                />
                                {validator.message('username', this.state.username, 'required')}
                                <div className='text-danger'>{this.state.errors.username}</div>
                            </div>

                            <div className='form-group'>
                                <TextField variant='outlined'
                                           type={this.state.showPassword ? 'text' : 'password'}
                                           id='adornment-password'
                                           name='password'
                                           label='Password'
                                           placeholder='Your password here..'
                                           onChange={(e) => this.handleChange(e,'password')}
                                           value={this.state.password}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position='end'>
                                                       <IconButton
                                                           className='btn-input'
                                                           aria-label='Toggle password visibility'
                                                           onClick={this.handleClickShowPassword}
                                                       >
                                                           {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                               <i className='fas fa-eye-slash'> </i>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               )
                                           }}
                                           fullWidth
                                />
                                {validator.message('password', this.state.password, 'required')}
                                <div className='text-danger'>{this.state.errors.password}</div>
                            </div>
                            <div className='text-danger mb-2'>{this.state.errors.message}</div>
                            <button onClick={this.handleLogIn} className='btn btn-blue' disabled={this.state.loadingButton}>MASUK { this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i> }</button>

                            <div className='row mt-2'>
                                <div className='col-md-6'>
                                    <p className='mb-0'>Baru mengenal Gudangemas?<br />
                                        <a href='/register' className='text-blue'>Daftar disini</a>
                                    </p>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <a href='/forgot' className='text-blue'>Lupa password?</a>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <hr />
                                    <a href='/' className='text-blue'>Pelajari Syaratan dan Ketentuan</a>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogCart}
                    onClose={this.handleCloseCart}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Produk berhasil dimasukan ke keranjang belanja</DialogTitle>
                    <DialogContent>
                        <div className='text-center'>
                            <img src={Cart} className='img-fluid' alt='keranjang' />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleCloseCart}>
                            Lanjutkan belanja
                        </button>
                        <button className="btn btn-blue" onClick={() => this.handleGo('/cart')}
                        >
                            Keranjang
                        </button>
                    </DialogActions>
                </Dialog>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Products);