import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Logo from '../../../images/logo.png';
import Image from '../../../images/succcess.svg';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import {toast, ToastContainer} from "react-toastify";
import {history} from '../../../shared/configure-store';

require('dotenv').config();

class RegisSuccess extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            email: '',
            username: '',
            code: '',
        };


    }

    componentDidMount() {

        document.title = 'Gudangemas - Register Berhasil';

    }
    handleGo  = (link) => {

        history.push(link);
    };

    render() {
        return (
            <main className='register'>
                <section className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6 col-lg-5 text-center'>
                            <Link to='/' className="navbar-brand">
                                <img src={Logo} alt='logo' className='img-logo my-4 my-md-5' width={280} />
                            </Link>
                            <p className='mb-3'>Anda berhasil daftar. Silahkan cek email Anda untuk password.</p>

                            <div className='text-center'>
                            <img src={Image} className='img-fluid my-4' />
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.handleGo('/')}
                            >
                                Kembali
                            </Button>
                        </div>

                    </div>
                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(RegisSuccess);