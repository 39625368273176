import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './shared/configure-store'
import Dashboard from "./components/container/dashboard"
import Login from "./components/container/login"
import Register from "./components/container/register"
import RegisterSucces from "./components/container/register/success"
import Forgot from "./components/container/forgot"
import ForgotSuccess from "./components/container/forgot/success"
import NotFound from "./components/container/not-found"
import Navbar from "./components/container/navbar"
import Footer from "./components/container/footer"
import Home from "./components/container/home"
import Pages from "./components/container/pages"
import Blogs from "./components/container/blogs"
import BlogsDetail from "./components/container/blogs/detail"
import Products from "./components/container/products"
import ProductsDetail from "./components/container/products/detail"
import Cart from "./components/container/cart"
import Checkout from "./components/container/checkout"
import Finish from "./components/container/checkout/done"
import Account from "./components/container/account"
import Quiz from "./components/container/quiz"
import Zakat from "./components/container/zakat"
import Simulation from "./components/container/simulation"
import { PrivateRoute, RedirectRoute } from './libraries/route-handling'
import About from "./components/container/pages/about";

const Routes = ({store}) => (

    <Provider store={store}>

        <ConnectedRouter history={history}>
            <Navbar />
            {

                <Switch>

                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/register-success" component={RegisterSucces}/>
                    <Route exact path="/forgot" component={Forgot}/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/products" component={Products}/>
                    <Route path="/products/:id" component={ProductsDetail}/>
                    <Route path="/pages/:slug" component={Pages}/>
                    <Route exact path="/blogs" component={Blogs}/>
                    <Route path="/blogs/:slug" component={BlogsDetail}/>
                    <Route path="/quiz" component={Quiz}/>
                    <PrivateRoute path="/zakat" component={Zakat}/>
                    <Route path="/about" component={About}/>
                    <Route path="/simulasi-perencanaan-keuangan" component={Simulation}/>
                    <Route path="/forgot-success" component={ForgotSuccess}/>
                    <PrivateRoute path="/cart" component={Cart}/>
                    <PrivateRoute path="/checkout" component={Checkout}/>
                    <PrivateRoute path="/finish/:id" component={Finish}/>
                    <PrivateRoute path="/account" component={Account}/>

                    {/*<PrivateRoute path="/" component={Dashboard}/>*/}

                    <Route component={NotFound}/>

                </Switch>

            }

            <Footer />
        </ConnectedRouter>

    </Provider>

);

export default Routes;