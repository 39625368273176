import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter, Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import Api from "../../../libraries/api";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {history} from "../../../shared/configure-store";
import _ from "lodash";

class Sidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            menus: [],
            userName: '',
            userRole: '',
            top: false,
            data: false,
            client: false,
            projects: false,
            finance: false,
            tasks: false,
            permissions: [],
        };
    }

    componentDidMount() {



    }

    checkPermission = (name) => {
        let all = this.state.permissions.filter((item) => item.name === name);
        if(all.length > 0)
            return true;
    };

    handleLogout = () => {

        AuthHelper.logOut();

    };

    openMenu  = (menu) => {

        this.setState({
            [menu]: !this.state[menu],
        })

    };

    handleGo  = (link) => {

        history.push(link);
    };

    render() {

        return(
            <aside className={"side-nav " + (this.props.toggle_sidebar_state ? "show" : "")  } >
                <header className="d-none d-lg-block">
                    <nav className="navbar justify-content-center">
                        <Link to="#" className="navbar-brand m-0 p-0">
                        </Link>
                    </nav>
                </header>
                <ul className="nav flex-column">
                    <li className="nav-item profile-picture">
                        <div className="content">
                            <h3>{this.state.userName}</h3>
                            <span>{this.state.userRole}</span>
                        </div>

                    </li>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {this.checkPermission('view-dashboards') &&
                        <ListItem button onClick={() => this.handleGo('/')}>
                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                        }

                        <ListItem button onClick={() => this.openMenu('top')}>
                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText primary="Top Management" />
                        </ListItem>
                        <Collapse in={this.state.top} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem  button onClick={() => this.handleGo('/projects?projectStatusReportId=6cb9bc24-606e-46cd-a17b-740398fbf5d7')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Alif" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/projects?projectStatusReportId=7ab80398-7935-4991-b32f-88df52cf3cbe')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Fauzi" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/projects?surveyorId=fa5ee958-b2ac-11ea-9f52-0242ac180002')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Pudjo" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <hr />
                        {this.checkPermission('list-projects') &&
                        <ListItem button onClick={() => this.openMenu('projects')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Projects"/>
                        </ListItem>
                        }
                        {this.checkPermission('list-projects') &&
                        <Collapse in={this.state.projects} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/projects')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="All Project"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=onp')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Report On Progress "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=onp')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval On Progress "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=fol')}
                                          >
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Report Follow Up "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=fol')}
                                        >
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval Follow Up "/>
                                </ListItem>
                            </List>
                        </Collapse>
                        }

                        <hr />
                        <ListItem button onClick={() => this.openMenu('tasks')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tasks"/>
                        </ListItem>
                        <Collapse in={this.state.tasks} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button
                                >
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Surveyor On Going"/>
                                </ListItem>
                                <ListItem button
                                >
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Admin On Going"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <hr />
                        {this.checkPermission('list-invoices') &&
                        <ListItem button onClick={() => this.openMenu('finance')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Finance"/>
                        </ListItem>
                        }
                        {this.checkPermission('list-invoices') &&
                        <Collapse in={this.state.finance} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/invoices')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Invoices"/>
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/pending-issued')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Pending Issued Invoice"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/pending-payment')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Pending Payment This Year"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/complete')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Complete Payment This Year"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/issued-month')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Issued Invoice This Month"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/issued-week')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Complete Payment This Month"/>
                                </ListItem>


                            </List>
                        </Collapse>
                        }

                        <hr />

                        {this.checkPermission('list-events') &&
                        <ListItem button onClick={() => this.handleGo('/events')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Events"/>
                        </ListItem>
                        }
                        <hr />

                        <ListItem button onClick={() => this.openMenu('data')}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Data" />
                        </ListItem>
                        <Collapse in={this.state.data} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/types')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Project Type" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/types-detail')}>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary="Project Detail Type" />
                                </ListItem>
                            </List>
                        </Collapse>

                        {this.checkPermission('list-clients') &&
                        <ListItem button onClick={() => this.openMenu('client')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Clients"/>
                        </ListItem>
                        }

                        <Collapse in={this.state.client} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/clients')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Clients" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Underwriter')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Underwriter" />
                                </ListItem>

                                <ListItem button onClick={() => this.handleGo('/clients/type/Broker')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Broker" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Shipowner')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Shipowner" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Adjuster')}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Adjuster" />
                                </ListItem>

                            </List>
                        </Collapse>

                        <hr />

                        {this.checkPermission('list-users') &&
                        <ListItem button onClick={() => this.handleGo('/users')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Admin"/>
                        </ListItem>
                        }

                        {this.checkPermission('list-roles') &&
                        <ListItem button onClick={() => this.handleGo('/roles')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Roles"/>
                        </ListItem>
                        }
                        <ListItem button onClick={() => this.handleGo('/charts')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Chart"/>
                        </ListItem>
                        <hr />

                        <ListItem button style={{cursor:'pointer'}} onClick={this.handleLogout}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>

                    </List>

                    </ul>
            </aside>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));