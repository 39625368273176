import React,{Component} from 'react';
import parse from "html-react-parser";
import {toast, ToastContainer} from "react-toastify";
import AuthHelper from "../../../libraries/auth-helper";
import Button from '@material-ui/core/Button';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import QuizImage from '../../../images/quiz.svg';
import {history} from "../../../shared/configure-store";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";


require('dotenv').config();
const validator = new SimpleReactValidator();

class Quiz extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loadingButton: false,
            showDialogLogin: false,

            errors: {},
            name: '',
            email: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: '',
            option6: '',
            option7: '',
            option8: '',

            success: false,
            score: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){

        document.title = 'Gudangemas - Quiz';
    };

    handleChange = (e, prop) => {
        this.setState({
            [prop]: e.target.value,
            }
        );
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        let params = {
            'email': this.state.email,
            'name': this.state.name,
            'values': [this.state.option1, this.state.option2, this.state.option3, this.state.option4, this.state.option5, this.state.option6, this.state.option7, this.state.option8],
        };

        Api.post('/quiz/submit-quiz', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Kuis berhasil dikirim');
                this.setState({
                        success: true,
                    score: resp.data['nilai-quiz'],
                    }
                );
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );




        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });


    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleReset = () => {

    };

    render() {
        return (
            <main className='pages'>
                <section className='container'>
                    <h1>Kuesioner Profil Risiko</h1>
                    <p>Kuesioner ini akan membantu Anda dalam menentukan sikap anda terhadap risiko, prioritas keuangan dan tujuan investasi Anda di kemudian hari sebelum memilih pola investasi emas yang sesuai. Sangat penting untuk memberikan informasi yang akurat dan lengkap untuk memastikan solusi investasi yang tepat untuk Anda.</p>
                    <p><strong>Harap diisi setiap melakukan pembelian produk investasi.</strong></p>
                            <p>Terima Kasih atas kesediaan dan waktu Anda mengisi Kuesioner Profil Risiko Investasi ini.</p>

                    <hr />
                    {!this.state.success ?
                        <form>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label>Nama</label>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='name'
                                                   name="name"
                                                   label="Name"
                                                   onChange={(e) => this.handleChange(e, 'name')}
                                                   value={this.state.name}
                                                   fullWidth
                                        />
                                        {validator.message('name', this.state.name, 'required')}
                                        <div className='text-danger'>{this.state.errors.name}</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='email'
                                                   name="email"
                                                   label="Email"
                                                   onChange={(e) => this.handleChange(e, 'email')}
                                                   value={this.state.email}
                                                   fullWidth
                                        />
                                        {validator.message('email', this.state.email, 'required|email')}
                                        <div className='text-danger'>{this.state.errors.email}</div>
                                    </div>
                                </div>
                            </div>
                            <p className='mt-4'><strong>1. Berapa usia Anda?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option1' onChange={(e) => this.handleChange(e, 'option1')} />
                                <span>Diatas 75 atau dibawah 18 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option1' onChange={(e) => this.handleChange(e, 'option1')} />
                                <span>66-75 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option1' onChange={(e) => this.handleChange(e, 'option1')} />
                                <span>56-65 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option1' onChange={(e) => this.handleChange(e, 'option1')} />
                                <span>46-55 tahun</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option1' onChange={(e) => this.handleChange(e, 'option1')} />
                                <span>18-45s tahun</span>
                            </label>
                            {validator.message('option1', this.state.option1, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option1}</div>

                            <p className='mt-5'><strong>2. Berapa persenkah dari pendapatan bulanan Anda yang Anda sediakan untuk berinvestasi atau menabung?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option2' onChange={(e) => this.handleChange(e, 'option2')} />
                                <span>0%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option2' onChange={(e) => this.handleChange(e, 'option2')} />
                                <span>Lebih dari 0% sampai dengan 10%n</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option2' onChange={(e) => this.handleChange(e, 'option2')} />
                                <span>Lebih dari 10% sampai dengan 25%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option2' onChange={(e) => this.handleChange(e, 'option2')} />
                                <span>Lebih dari 25% sampai dengan 50%</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option2' onChange={(e) => this.handleChange(e, 'option2')} />
                                <span>Lebih dari 50%</span>
                            </label>
                            {validator.message('option2', this.state.option2, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option2}</div>


                            <p className='mt-5'><strong>3. Portfolio Anda sekarang terdiri dari?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option3' onChange={(e) => this.handleChange(e, 'option3')} />
                                <span>Hanya uang tunai (deposito dan tabungan)</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option3' onChange={(e) => this.handleChange(e, 'option3')} />
                                <span>Sebagian besar uang tunai (seperti di atas ditambah obligasi atau investasi sejenis lainnya)</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option3' onChange={(e) => this.handleChange(e, 'option3')} />
                                <span>Campuran (Reksa Dana Campuran, properti, emas dan uang tunai)</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option3' onChange={(e) => this.handleChange(e, 'option3')} />
                                <span>Agresif (saham unggulan, Reksa Dana Saham, Reksa Dana Indeks, komoditas)</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option3' onChange={(e) => this.handleChange(e, 'option3')} />
                                <span>Spekulatif (saham-saham teknologi/biomedis, investasi derivatif, option, future dan waran)</span>
                            </label>
                            {validator.message('option3', this.state.option3, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option3}</div>

                            <p className='mt-5'><strong>4. Berapa lama pengalaman yang Anda miliki dalam berinvestasi di produk yang berfluktuatif?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option4' onChange={(e) => this.handleChange(e, 'option4')} />
                                <span>Tidak memiliki pengalaman</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option4' onChange={(e) => this.handleChange(e, 'option4')} />
                                <span>Kurang dari 3 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option4' onChange={(e) => this.handleChange(e, 'option4')} />
                                <span>3-6 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option4' onChange={(e) => this.handleChange(e, 'option4')} />
                                <span>7-10 tahun</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option4' onChange={(e) => this.handleChange(e, 'option4')} />
                                <span>Lebih dari 10 tahun</span>
                            </label>
                            {validator.message('option4', this.state.option4, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option4}</div>

                            <p className='mt-5'><strong>5. Berdasarkan pertanyaan di atas sebelumnya, Seberapa besar aset yang Anda alokasikan ke dalam produk yang berfluktuatif tersebut?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option5' onChange={(e) => this.handleChange(e, 'option5')} />
                                <span>0%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option5' onChange={(e) => this.handleChange(e, 'option5')} />
                                <span>Lebih dari 0% sampai dengan 10%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option5' onChange={(e) => this.handleChange(e, 'option5')} />
                                <span>Lebih dari 10% sampai dengan 25%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option5' onChange={(e) => this.handleChange(e, 'option5')} />
                                <span>Lebih dari 25% sampai dengan 50%</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option5' onChange={(e) => this.handleChange(e, 'option5')} />
                                <span>Lebih dari 50%</span>
                            </label>
                            {validator.message('option5', this.state.option5, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option5}</div>

                            <p className='mt-5'><strong>6. Semakin tinggi risiko dari suatu investasi, semakin besar potensial fluktuasinya dan semakin besar pula tingkat pengembaliannya (High Risk, High Return), demikian pula sebaliknya. Berapa tingkat fluktuasi yang sesuai dengan Anda?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option6' onChange={(e) => this.handleChange(e, 'option6')} />
                                <span>Fluktuasi antara -5% dan +5%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option6' onChange={(e) => this.handleChange(e, 'option6')} />
                                <span>Fluktuasi antara -10% dan +10%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option6' onChange={(e) => this.handleChange(e, 'option6')} />
                                <span>Fluktuasi antara -15% dan +15%</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option6' onChange={(e) => this.handleChange(e, 'option6')} />
                                <span>Fluktuasi antara -20% dan +20%</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option6' onChange={(e) => this.handleChange(e, 'option6')} />
                                <span>{'Fluktuasi antara <-20% dan >+20%'}</span>
                            </label>
                            {validator.message('option6', this.state.option6, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option6}</div>

                            <p className='mt-5'><strong>7. Semakin lama jangka waktu berinvestasi maka semakin besar pula risiko yang dapat ditoleransi oleh investor. Seberapa lamakah Anda dapat bertoleransi dengan produk investasi yang berfluktuatif?</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option7' onChange={(e) => this.handleChange(e, 'option7')} />
                                <span>Kurang dari 1 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option7' onChange={(e) => this.handleChange(e, 'option7')} />
                                <span>1-3 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option7' onChange={(e) => this.handleChange(e, 'option7')} />
                                <span>4-5 tahun</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option7' onChange={(e) => this.handleChange(e, 'option7')} />
                                <span>6-10 tahun</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option7' onChange={(e) => this.handleChange(e, 'option7')} />
                                <span>Lebih dari 10 tahun</span>
                            </label>
                            {validator.message('option7', this.state.option7, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option7}</div>

                            <p className='mt-5'><strong>8. Berapa jumlah dana yang Anda simpan untuk kejadian yang tidak terduga? Dana tersebut dapat diakses dengan mudah dan memiliki likuiditas yang tinggi.</strong></p>
                            <label className='radio-option'>
                                <input type="radio" value={1} name='option8' onChange={(e) => this.handleChange(e, 'option8')} />
                                <span>Tidak ada *</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={2} name='option8' onChange={(e) => this.handleChange(e, 'option8')} />
                                <span>Kurang dari 3 bulan gaji</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={3} name='option8' onChange={(e) => this.handleChange(e, 'option8')} />
                                <span>{'Antara 3 bulan gaji sampai dengan < 6 bulan gaji'}</span>
                            </label>
                            <label className='radio-option'>
                                <input type="radio" value={4} name='option8' onChange={(e) => this.handleChange(e, 'option8')} />
                                <span>{'Antara 6 bulan gaji sampai dengan < 9 bulan gaji'}</span>
                            </label>
                            <label className='radio-option mb-0'>
                                <input type="radio" value={5} name='option8' onChange={(e) => this.handleChange(e, 'option8')} />
                                <span>{'Lebih dari 9 bulan gaji'}</span>
                            </label>
                            <small>*Sebaiknya Anda mempertimbangkan untuk menyisihkan sebagian dana Anda untuk kejadian yang tidak terduga (misalkan 3 sampai 6 bulan gaji) sebelum berinvestasi.</small>

                            {validator.message('option8', this.state.option8, 'required')}
                            <div className='text-danger mb-3'>{this.state.errors.option8}</div>


                            <div className='text-center mt-3'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Kirim{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </form> :
                        <div className='text-center'>
                            <img src={QuizImage} alt='berhasil' />
                            <h2>Selesai!</h2>
                            <p>Terima kasih telah mengikuti quiz ini</p>
                            <p className='text-yellow'>Skor yang anda dapatkan {this.state.score}</p>

                            <div className="table-list text-left">
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NILAI</TableCell>
                                            <TableCell>PROFIL RISIKO</TableCell>
                                            <TableCell>POLA INVESTASI</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{'< 11'}</TableCell>
                                            <TableCell>Secure</TableCell>
                                            <TableCell>Deposito dengan bagi hasil dibelikan Emas</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>12-19</TableCell>
                                            <TableCell>Cautious/Conservative</TableCell>
                                            <TableCell>Beli dan simpan Emas</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>20-28</TableCell>
                                            <TableCell>Balanced</TableCell>
                                            <TableCell>Beli dengan cicilan atau Tabungan Emas Digital</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>29-40</TableCell>
                                            <TableCell>Adventurous/Aggressive</TableCell>
                                            <TableCell>Trading</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    }




                </section>
                <ToastContainer />
            </main>
        )
    }
}

export default Quiz;

