import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class ContentDashboard extends Component {
    constructor(props) {

        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {

        return (

            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h2 className="page-title">Dashboard</h2>
                </div>

            </div>

        )

    }
}

export default ContentDashboard;