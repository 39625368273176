import React,{Component} from 'react';
import parse from "html-react-parser";
import {toast, ToastContainer} from "react-toastify";
import AuthHelper from "../../../libraries/auth-helper";
import Button from '@material-ui/core/Button';
import SimpleReactValidator from "simple-react-validator";
import NumberFormat from 'react-number-format';

import Api from "../../../libraries/api";
import QuizImage from '../../../images/quiz.svg';
import {history} from "../../../shared/configure-store";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


require('dotenv').config();
const validator = new SimpleReactValidator();

class Simulation extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loadingButton: false,
            showDialogLogin: false,
            errors: {},
            time: '',
            purpose: '',
            money: '',
            up: '',
            estimate: '',
            buyback: '',
            target: '',
            gold: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){

        document.title = 'Gudangemas - Simulasi';
        let user = AuthHelper.getProfile();
    };

    handleChange = (e, prop) => {
        this.setState({
            [prop]: e.target.value,
            }
        );
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        let estimate = parseFloat(this.state.money) * (1+((parseFloat(this.state.up)/100)*parseFloat(this.state.time)));
        let target = estimate/parseFloat(this.state.buyback);
        let gold = target/(parseFloat(this.state.time)*12)

        if(gold) {
            this.setState({
                estimate: parseFloat(estimate).toFixed(2),
                target: parseFloat(target).toFixed(2),
                gold: parseFloat(gold).toFixed(2),
                    loadingButton: false,
                }
            );
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    handleReset = (e) => {
        this.setState({
            time: '',
            purpose: '',
            money: '',
            up: '',
            estimate: '',
            buyback: '',
            target: '',
            gold: '',

            }
        );
    }
    render() {
        return (
            <main className='pages simulation'>
                <section className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-7'>
                            <h1 className='text-center'>Simulasi Perencanaan
                                Keuangan Berbasis Emas</h1>
                            <div className='card'>
                                <form className='row'>
                                    <div className='col-12'>
                                        <h3>Silahkan diisi kolom di bawah ini:</h3>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Tujuan Investasi</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='purpose'
                                                       placeholder='Pernikahan, masa depan, dana darurat'
                                                       onChange={(e) => this.handleChange(e,'purpose')}
                                                       value={this.state.purpose}
                                                       fullWidth
                                            />
                                            {validator.message('purpose', this.state.purpose, 'required')}
                                            <div className='text-danger'>{this.state.errors.purpose}</div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Target waktu</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='time'
                                                       onChange={(e) => this.handleChange(e,'time')}
                                                       value={this.state.time}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                  Tahun
                                                               </InputAdornment>
                                                           )
                                                       }}
                                                       fullWidth
                                            />
                                            {validator.message('time', this.state.time, 'required')}
                                            <div className='text-danger'>{this.state.errors.time}</div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Total Dana yang dibutuhkan</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='money'
                                                       onChange={(e) => this.handleChange(e,'money')}
                                                       value={this.state.money}
                                                       InputProps={{
                                                           startAdornment: (
                                                               <InputAdornment position='start'>
                                                                   Rp
                                                               </InputAdornment>
                                                           ),
                                                           inputComponent: this.NumberFormatCustom,
                                                       }}
                                                       fullWidth
                                            />
                                            {validator.message('money', this.state.money, 'required')}
                                            <div className='text-danger'>{this.state.errors.money}</div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Tingkat kenaikan biaya (%)</label>
                                            <TextField variant='outlined'
                                                       type='number'
                                                       name='up'
                                                       onChange={(e) => this.handleChange(e,'up')}
                                                       value={this.state.up}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                   % per Tahun
                                                               </InputAdornment>
                                                           )
                                                       }}
                                                       fullWidth
                                            />
                                            {validator.message('up', this.state.up, 'required')}
                                            <div className='text-danger'>{this.state.errors.up}</div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Harga buyback emas saat ini (Rp)</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='buyback'
                                                       onChange={(e) => this.handleChange(e,'buyback')}
                                                       value={this.state.buyback}
                                                       InputProps={{
                                                           startAdornment: (
                                                               <InputAdornment position='start'>
                                                                   Rp
                                                               </InputAdornment>
                                                           ),
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                   per gram
                                                               </InputAdornment>
                                                           ),
                                                           inputComponent: this.NumberFormatCustom,
                                                       }}
                                                       fullWidth
                                            />
                                            {validator.message('buyback', this.state.buyback, 'required')}
                                            <div className='text-danger'>{this.state.errors.buyback}</div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <h3>Hasil simulasi di atas adalah sebagai berikut:</h3>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Perkiraan jumlah dana di masa depan</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='estimate'
                                                       className='yellow'
                                                       value={this.state.estimate}
                                                       InputProps={{
                                                           startAdornment: (
                                                               <InputAdornment position='start'>
                                                                   Rp
                                                               </InputAdornment>
                                                           ),
                                                           inputComponent: this.NumberFormatCustom,
                                                           readOnly: true,
                                                       }}
                                                       fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'></div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Target emas yang diperlukan</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='target'
                                                       className='yellow'
                                                       value={this.state.target}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                   per gram
                                                               </InputAdornment>
                                                           )
                                                       }}
                                                       fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <div className='form-group'>
                                            <label>Target menabung emas per bulan</label>
                                            <TextField variant='outlined'
                                                       type='text'
                                                       name='gold'
                                                       className='yellow'
                                                       value={this.state.gold}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position='end'>
                                                                   per gram
                                                               </InputAdornment>
                                                           ),
                                                           readOnly: true,
                                                       }}
                                                       fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='mr-2'
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton }
                                        >
                                            Hitung {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={this.handleReset}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer />
            </main>
        )
    }
}

export default Simulation;

