import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Skeleton from "@material-ui/lab/Skeleton";
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';
import Default from "../../../images/default.jpeg";
import Button from "@material-ui/core/Button";
import {toast, ToastContainer} from "react-toastify";
import templateId from '../../../libraries/lang-id';
import _ from "lodash";
SimpleReactValidator.addLocale('indo', templateId);

require('dotenv').config();

const validator = new SimpleReactValidator({
    locale: 'indo'
});

class Checkout extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            loadingCart: false,
            loadingShipping: false,
            errors: {},
            anchorEl: null,

            company: '',
            place: '',
            address: '',
            province: '',
            city: '',
            subdistrict: '',
            firstName: '',
            lastName: '',
            phone: '',
            provinceName: '',
            cityName: '',
            subdistrictName: '',
            zipCode: '',
            note: '',

            provinces: [],
            cities: [],
            subdistricts: [],

            dropship: false,

            companyShipping: '',
            placeShipping: '',
            addressShipping: '',
            provinceShipping: '',
            provinceShippingName: '',
            cityShipping: '',
            cityShippingName: '',
            zipCodeShipping: '',
            subdistrictShipping: '',
            subdistrictShippingName: '',
            firstNameShipping: '',
            lastNameShipping: '',
            phoneShipping: '',
            shippingType: '',

            shippings: [],
            ship: null,
            data: null,

            banks: [],
            bank: '',

            carts: [],

            couponValid: '',
            couponValue: 0,
            redeemPoint: 0,
            redeemValue: 0,
            pointAll: 0,
            check:null,

            shippingCost: '',
            shippingCostDetail: '',
            uniqueCode: '',
            weightTotal: 0,

            success: false,
            isFree: false,
            orderNumber: '',
            orderDate: '',
            bankName: '',
            accountName: '',
            accountNumber: '',
            logo: Default,

            config: '',
            configText: '',

            isOnline: false,
            isReseller: false,

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleShipping = this.handleShipping.bind(this);

    }

    componentDidMount() {

        document.title = 'Gudangemas - Checkout';

        let user = AuthHelper.getProfile();

        let pointredem = this.props.location.state ? this.props.location.state.redeempoint : null;
        let pointrward= this.props.location.state ? parseFloat(this.props.location.state.discountRedeem) : 0;
        let coupon = this.props.location.state ? this.props.location.state.coupon : null;
        let isFree = this.props.location.state ? this.props.location.state.isFree : false;
        let valueCoupon = this.props.location.state ? parseFloat(this.props.location.state.discountCoupon) : 0;
        this.setState({
            user: user,
            code: user['customer-code'],
            loadingCart: true,
            isFree: isFree,
            couponValid: this.props.location.state ? this.props.location.state.coupon : null,
            couponValue: this.props.location.state ? parseFloat(this.props.location.state.discountCoupon) : 0,
            redeemPoint: this.props.location.state ? this.props.location.state.redeempoint : null,
            redeemValue: this.props.location.state ? parseFloat(this.props.location.state.discountRedeem) : 0,

        });

        if(this.props.location.state) {
            if(this.props.location.state.coupon) {
                Api.get('/coupon/'+this.props.location.state.coupon).then(resp => {

                    if (resp.data) {
                        if(resp.data['coupon-type'].name === 'Reseller') {
                            this.setState({
                                    isReseller: true
                                }
                            );
                        }
                    }

                }).catch(err => {
                    console.log(err);
                });
            }
        }


        Api.get('/config/2').then(resp => {

            if (resp.data) {
                this.setState({
                        config: resp.data.value ? resp.data.value : 'OPEN',
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/6').then(resp => {

            if (resp.data) {
                this.setState({
                        configText: resp.data.value ? resp.data.value : '',
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });


        this.__fetchProvince();

        Api.get('/cart/'+user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {
                let weightTotal = 0;
                let products = [];
                let all = 0;
                _.forEach(data.carts, function (o) {
                    all += (parseFloat(o.quantity*o.product['product-fixed-point']))
                    weightTotal += parseFloat(o.product.weight);
                    products.push({
                        id: o.id,
                        sku: o.sku,
                        'product-id': o.product.id ? o.product.id : '',
                        image: o.product.image ? o.product.image : Default,
                        name: o.product.name ? o.product.name : '',
                        weight: o.product.weight ? o.product.weight : '',
                        attribute: o.product['atribute-value']  ? o.product['atribute-value'] : '',
                        stock: o.product['stock-quantity'] ? o.product['stock-quantity'] : '',
                        price: o.price,
                        totalPrice: o.totalPrice,
                        quantity: o.quantity,
                        point: o.product['product-fixed-point'] ? o.product['product-fixed-point'] : 0,
                    })

                });
                /*let products = data.carts.map(o => {
                    weightTotal += parseFloat(o.product.weight);
                    return {
                        id: o.id,
                        sku: o.sku,
                        'product-id': o.product.id ? o.product.id : '',
                        image: o.product.image ? o.product.image : Default,
                        name: o.product.name ? o.product.name : '',
                        weight: o.product.weight ? o.product.weight : '',
                        stock: o.product['stock-quantity'] ? o.product['stock-quantity'] : '',
                        price: o.price,
                        totalPrice: o.totalPrice,
                        quantity: o.quantity,
                        point: o.product['product-fixed-point'] ? o.product['product-fixed-point'] : 0,
                    }
                });*/

                if(data['is-full-training']) {
                    this.setState({
                        ship: 0,
                        shippingCost: '0',
                    });
                }

                this.setState({
                    pointAll: all,
                        carts: products,
                        'redeem-point': pointredem,
                        'point-reward': pointrward,
                        'discount-coupon': valueCoupon,
                        'discount-redeem': coupon,
                        totalAmount: data.totalAmount,
                        total: data.total,
                        weightTotal: weightTotal,
                        totalPointProduct: data.totalPointProduct,
                        isOnline: data['is-full-training'],
                        loadingCart: false,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/billing-address/'+user['customer-code']).then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    data: data,
                    firstName: data['first-name'] !== null ? data['first-name'] : '',
                    lastName: data['last-name'] !== null ? data['last-name'] : '',
                    phone: user['phone-number'] !== null ? user['phone-number'] : '',
                    address: data.address ?  data.address : '',
                    company: data.company ?  data.company : '',
                    place: data.place ?  data.place : '',
                    province: data.province ?  data.province.province_id : '',
                    provinceName: data.province ?  data.province.province : '',
                    city: data.city ?  data.city && data.city.id : '',
                    zipCode: data['postal-code'] ?  data['postal-code'] : '',
                    cityName: data.city ?  data.city && data.city.type+' '+data.city.name : '',
                    subdistrict: data['sub-district'] ?  data['sub-district']['name'] : '',
                    subdistrictName: data['sub-district'] ?  data['sub-district']['id'] : '',
                    companyShipping: data.company ?  data.company : '',
                    placeShipping: data.place ?  data.place : '',
                    addressShipping: data.address ?  data.address : '',
                    provinceShipping: data.province ?  data.province.province_id : '',
                    cityShipping: data.city ?  data.city && data.city.id : '',
                    zipCodeShipping: data['postal-code'] ?  data['postal-code'] : '',
                    subdistrictShipping: data['sub-district'] ?  data['sub-district']['name'] : '',
                    subdistrictShippingName: data['sub-district'] ?  data['sub-district']['id'] : '',
                    firstNameShipping: data['first-name'] !== null ? data['first-name'] : '',
                    lastNameShipping: data['last-name'] !== null ? data['last-name'] : '',
                    phoneShipping: user['phone-number'] !== null ? user['phone-number'] : '',
                }, () => {
                    if(data.address) {
                        this.__fetchAddress();
                    }
                });
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/lookup/by-group-id/3').then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    shippings: data,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });


        Api.get('/bank').then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    banks: data,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/order/get-unique-code').then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    uniqueCode: data,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });
    }

    __fetchProvince = () => {
        Api.get('/province').then(resp => {

            if (resp.data) {

                this.setState({
                        provinces: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchProvinceRPX = () => {
        Api.get('/province/by-service-code/'+this.state.shippingType).then(resp => {

            if (resp.data) {

                this.setState({
                        provinces: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };


    __fetchAddress = () => {

        this.__fetchProvince();

        this.__fetchCity();

        this.__fetchSubdistrict();

    };


    __fetchCity = () => {

        Api.get('/city/'+this.state.provinceShipping).then(resp => {

            if (resp.data) {

                this.setState({
                        cities: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    __fetchCityRPX = () => {

        Api.get('/city/rpx?province='+this.state.provinceShipping).then(resp => {

            if (resp.data) {

                this.setState({
                        cities: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    __fetchSubdistrict = () => {

        Api.get('/subdistrict/'+this.state.cityShipping).then(resp => {

            if (resp.data) {

                this.setState({
                        subdistricts: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    __fetchSubdistrictRPX = (e) => {
        Api.get('/subdistrict/rpx?city-id='+this.state.cityShipping+'&city-name='+this.state.cityShippingName).then(resp => {

            if (resp.data) {

                this.setState({
                        subdistricts: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    handleChange (e, prop){
        if(prop === 'subdistrictShipping') {

            if(this.state.ship == 'rpx') {
                let all = this.state.subdistricts.filter((item) => item.kecamatan === e.target.value);

                this.setState({
                    subdistrictShippingName: all[0]['id'],
                    zipCodeShipping: all[0]['postal-code'],
                })
            }else {
                let all = this.state.subdistricts.filter((item) => item.name === e.target.value);

                this.setState({
                    subdistrictShippingName: all[0]['id'],
                })
            }
        }

        this.setState({
            [prop]: e.target.value,
        });
    };

    handleChecked = (e) => {

        this.setState({

            check: e.target.checked,

        })

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleClose = () => {

        this.setState({
            anchorEl: null
        })
    };

    handleDropship = (event) => {
        if(!event.target.checked) {
            this.setState({
                [event.target.name]: event.target.checked,
                companyShipping: this.state.company,
                placeShipping: this.state.place,
                addressShipping: this.state.address,
                provinceShipping: this.state.province,
                provinceShippingName: this.state.provinceName,
                cityShipping: this.state.city,
                cityShippingName: this.state.cityShippingName,
                subdistrictShipping: this.state.subdistrict,
                subdistrictShippingName: this.state.subdistrictName,
                firstNameShipping: this.state.firstName,
                lastNameShipping: this.state.lastName,
                phoneShipping: this.state.phone,
                zipCodeShipping: this.state.zipCode,
            })
        }else {
            this.setState({
                [event.target.name]: event.target.checked,
                companyShipping: '',
                placeShipping: '',
                addressShipping: '',
                provinceShipping: '',
                cityShipping: '',
                provinceShippingName: '',
                cityShippingName: '',
                subdistrictShipping: '',
                subdistrictShippingName: '',
                firstNameShipping: '',
                lastNameShipping: '',
                phoneShipping: '',
                zipCodeShipping: '',
            })
        }
    };

    checkCost = () => {
        if(this.state.ship === null) {
            this.showMessage(false, 'Metode belum dipilih');
            return false;
        }
        this.setState({
            loadingShipping: true,
            shippingCost: '',
        });
        let params;

        if(this.state.ship == 'rpx') {
            params = {
                destination: this.state.zipCodeShipping,
                'is-jnt': false,
                'is-rpx': true,
                'is-sap': false,
                weight: this.state.weightTotal <= 1000 ? 1 : parseInt(this.state.weightTotal/1000),
            };

        }else if(this.state.ship == 'sap') {
            params = {
                destination: this.state.subdistrictShippingName,
                'is-jnt': false,
                'is-rpx': false,
                'is-sap': true,
                weight: this.state.weightTotal <= 1000 ? 1 : parseInt(this.state.weightTotal/1000),
                'insurance' : null,
                'item-value' : this.state.totalAmount,
            };

        }else {

            params = {
                destination: "",
                'is-jnt': true,
                'is-rpx': false,
                'is-sap': false,
                'subdistrict-id': this.state.subdistrictShippingName,
                weight: this.state.weightTotal <= 1000 ? 1 : parseInt(this.state.weightTotal/1000),
            };

        }

        Api.post('/order/get-cost', params).then(resp => {

            if(resp.status === 200) {
                if(this.state.ship == 'rpx' || this.state.ship == 'sap' ) {
                    if(resp.data !== null) {
                        this.setState({
                                shippingCost: resp.data.value,
                                loadingShipping: false,
                            }
                        );
                    }else {
                        let message = '';
                        if(resp.message[0] === 'PARAMETER @destination NOT FOUND') {
                            message = 'Alamat pengiriman belum lengkap';
                        }else if(resp.message[0] === '@item_value harus lebih dari 500000'){
                            message = 'Total belanja harus lebih dari Rp 500,000';
                        }else {
                            message = resp.message[0];
                        }

                        this.showMessage(false, this.state.ship +': '+message);
                        this.setState({
                            dropship:true,
                            loadingShipping: false,
                            shippingCost: '',
                        });
                    }
                }else {
                    if(resp.data.costs.length > 0) {
                        this.setState({
                                shippingCost: resp.data.costs[0].cost[0].value,
                                shippingCostDetail: resp.data.costs[0].description,
                                loadingShipping: false,
                            }
                        );
                    }else {
                        this.showMessage(false, 'Pengiriman tidak ditemukan');
                        this.setState({
                                loadingShipping: false,
                            shippingCost: '',
                            }
                        );
                    }
                }




            }else {
                this.showMessage(false, resp.message[0]);
                this.setState({
                        loadingShipping: false,
                        ship: null
                    }
                );

            }

        }).catch(err => {

            this.setState({
                    loadingShipping: false,
                }
            );
        });
    };

    handleShipping = (e) => {

        if(e.target.value == 'rpx' || e.target.value == 'sap') {

            this.setState({
                dropship: true,
                shippingCost: '',
                companyShipping: '',
                placeShipping: '',
                addressShipping: '',
                provinceShipping: '',
                cityShipping: '',
                subdistrictShipping: '',
                firstNameShipping: '',
                lastNameShipping: '',
                phoneShipping: '',
                zipCodeShipping: '',
                shippingType: e.target.value,
            }, () => {
                this.__fetchProvinceRPX();
            });
        }else {
            let data = this.state.data;
            let user = AuthHelper.getProfile();

            this.setState({
                dropship: false,
                shippingCost: '',
                address: data.address ?  data.address : '',
                company: data.company ?  data.company : '',
                place: data.place ?  data.place : '',
                province: data.province ?  data.province.province_id : '',
                provinceName: data.province ?  data.province.province : '',
                city: data.city ?  data.city && data.city.id : '',
                cityShippingName: data.city ?  data.city && data.city.type+' '+data.city.name : '',
                zipCode: data['postal-code'] ?  data['postal-code'] : '',
                cityName: data.city ?  data.city && data.city.type+' '+data.city.name : '',
                subdistrict: data['sub-district'] ?  data['sub-district']['name'] : '',
                subdistrictName: data['sub-district'] ?  data['sub-district']['id'] : '',
                companyShipping: data.company ?  data.company : '',
                placeShipping: data.place ?  data.place : '',
                addressShipping: data.address ?  data.address : '',
                provinceShipping: data.province ?  data.province.province_id : '',
                provinceShippingName: data.province ?  data.province.province : '',
                cityShipping: data.city ?  data.city && data.city.id : '',
                zipCodeShipping: data['postal-code'] ?  data['postal-code'] : '',
                subdistrictShipping: data['sub-district'] ?  data['sub-district']['name'] : '',
                subdistrictShippingName: data['sub-district'] ?  data['sub-district']['id'] : '',
                firstNameShipping: data['first-name'] !== null ? data['first-name'] : '',
                lastNameShipping: data['last-name'] !== null ? data['last-name'] : '',
                phoneShipping: user['phone-number'] !== null ? user['phone-number'] : '',
                subdistricts: [],
                cities: [],
            }, () => {
                this.__fetchProvince();

            });
        }

        this.setState({
            ship: e.target.value,
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleBank = (e) => {
        this.setState({
            bank: e.target.value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let ship = this.state.shippings.filter((item) => item.code === this.state.ship);
        if(this.state.shippingCost === '') {
            this.showMessage(false, 'Cek harga ongkir terlebih dahulu');
            return false;
        }
        console.log(validator.getErrorMessages())
        if (!validator.allValid()) {
            this.setState({
                    errors: validator.getErrorMessages()
                }
            );
            return false;

        }

        if(!this.state.check) {
            this.showMessage(false, 'Cek syarat dan ketentuan');

            return false;
        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'bank-type-id': this.state.bank,
            'coupon-code': this.state.couponValid,
            'customer-code': this.state.code,
            'discount-coupon': this.state.couponValue,
            'discount-redeem': this.state.redeemValue,
            'flag-term-condition': this.state.check,
            'grand-total': parseFloat(this.state.totalAmount) - this.state.couponValue - this.state.uniqueCode + (this.state.isFree ? 0 : parseFloat(this.state.shippingCost)) - this.state.redeemValue,
            'point-reward': this.state.totalPointProduct,
            'products': this.state.carts,
            'redeem-point': this.state.redeemPoint,
            'shipping-address': {
                'address': this.state.addressShipping,
                'city-id': this.state.cityShipping,
                'city-name': this.state.cityShippingName,
                'company': this.state.companyShipping,
                'first-name': this.state.firstNameShipping,
                'last-name': this.state.lastNameShipping,
                'phone-number': this.state.phoneShipping,
                'place': this.state.placeShipping,
                'province-id': this.state.provinceShipping,
                'province-name': this.state.provinceShippingName,
                'subdistrict-id': this.state.subdistrictShippingName,
                'subdistrict-name': this.state.subdistrictShipping,
                'village': this.state.subdistrictShipping,
                'postal-code': this.state.zipCodeShipping,
                'note': this.state.note,
            },
            'shipping-cost': this.state.isFree ? 0 : String(this.state.shippingCost),
            'shipping-type-id': this.state.isOnline ? null : ship.length > 0 && ship[0]['id'],
            'total-amount': this.state.totalAmount,
            'unique-code': this.state.uniqueCode,
        };


        Api.post('/order', params).then(resp => {

            if(resp.status === 200) {

                let data = resp.data;
                window.location.href = '/finish/'+data['order-code'];
                /*this.setState({
                    success: true,
                    orderNumber: data['order-code'],
                    orderDate: data['order-date'],
                    bankName: data.bank ? data.bank.bankName : '',
                    accountName: data.bank ? data.bank.accountName : '',
                    accountNumber: data.bank ? data.bank.accountNumber : '',
                    logo: data.bank.logo !== null ? data.bank.logo : Default,
                    phoneShipping: data['shipping-address'] ? data['shipping-address']['phone-number'] : '',
                    subdistrictShipping: data['shipping-address'] ? data['shipping-address']['subdistrict']['name'] : '',
                    cityShipping: data['shipping-address'] ? data['shipping-address']['city']['type']+' '+data['shipping-address']['city']['name'] : '',
                    provinceShipping: data['shipping-address'] ? data['shipping-address']['province']['province'] : '',
                });
                this.showMessage(true, 'Order Berhasil');*/

            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                loadingButton: false,
                }
            );
        });
    };

    handleProvince (e, prop){
        let all = this.state.provinces.filter((item) => item.province_id === e.target.value);

        console.log(e.target.value)
        if(this.state.ship == 'rpx') {
            this.setState({
                cityShipping: '',
                subdistrictShipping: '',
                zipCodeShipping: '',
                cities: [],
                subdistricts: [],
                [prop]: e.target.value,
                provinceShippingName: all.length > 0 ? all[0].province : ''

            }, () => {
                this.__fetchCityRPX();
            });
        }else {
            this.setState({
                shippingCost: '',
                cityShipping: '',
                subdistrictShipping: '',
                zipCodeShipping: '',
                cities: [],
                subdistricts: [],
                provinceShippingName: all.length > 0 ? all[0].province : '',
                [prop]: e.target.value

            }, () => {
                this.__fetchCity();
            });
        }


    };

    handleCity (e, prop){
        let all = this.state.cities.filter((item) => item.id === e.target.value);

        if(this.state.ship == 'rpx') {
            this.setState({
                subdistrictShipping: '',
                subdistricts: [],
                [prop]: e.target.value,
                cityShippingName: all.length > 0 ? all[0].name : '',

            }, () => {
                this.__fetchSubdistrictRPX();
            });
        }else {
            this.setState({
                shippingCost: '',
                subdistrictShipping: '',
                zipCodeShipping: all.length > 0 ? all[0].postalCode : '',
                cityShippingName: all.length > 0 ? (all[0].type !== null ? all[0].type : '') +' '+all[0].name : '',
                subdistricts: [],
                [prop]: e.target.value

            }, () => {
                this.__fetchSubdistrict();
            });
        }
    };


    render() {
        const total = parseFloat(this.state.totalAmount) - this.state.couponValue - this.state.redeemValue - this.state.uniqueCode + (this.state.isFree ? 0 : parseFloat(this.state.shippingCost));
        return (
            <main className='products'>
                <div className='container'>
                    {this.state.config === 'CLOSE' &&
                    <div className='alert'>
                        <p className='mb-0'><i className="fas fa-exclamation-circle mr-2"> </i> {this.state.configText}</p>
                    </div>
                    }
                    {!this.state.success ?
                        <section className='row section-products'>
                            <form className='col-md-7'>
                                {!this.state.isOnline &&
                                <>
                                    <div className='section-title '>
                                        <h2>Metode Pengiriman</h2>
                                        <strong>Pilih PENGIRIMAN  >  Lanjut/Lengkapi ALAMAT PENGIRIMAN  >  Pilih METODE PEMBAYARAN  >  CEK ONGKIR  >  Selesai (Lanjut Pembayaran)</strong>
                                    </div>

                                    {this.state.shippings.map(function (option) {
                                        return <label className='radio-option' key={option.id}>
                                            <input type="radio" value={option.code}
                                                   checked={this.state.ship == option.code ? true : false}
                                                   onChange={this.handleShipping} />
                                            <span>{option.name} {option.code !== 'jnt' && '(Lengkapi form pengiriman di bawah ini)'}</span>
                                        </label>
                                    }.bind(this))
                                    }
                                    {validator.message('ship', this.state.ship, 'required')}
                                    <div className='text-danger'>{this.state.errors.ship}</div>
                                    <small className='text-red'>*Metode pengiriman SAP berlaku untuk pembelanjaan minimal Rp 500,000</small>

                                </>
                                }

                                <div className='section-title mt-3'>
                                    <h2>Informasi Penagihan</h2>
                                </div>
                                <div className='row'>
                                    <div className="form-group col-md-6 mb-0">
                                        <label>Nama Depan</label>
                                        <p>{this.state.firstName}</p>
                                        <label>Nama Belakang</label>
                                        <p>{this.state.lastName}</p>
                                        <label>Telepon</label>
                                        <p>{this.state.phone}</p>
                                    </div>
                                    <div className="form-group col-md-6 mb-0">
                                        <label>Alamat</label>
                                        <p>{this.state.address}, {this.state.subdistrictName}, {this.state.cityName}, {this.state.provinceName}, {this.state.zipCode}</p>
                                        <label>Nama Perusahaan</label>
                                        <p>{this.state.company}</p>
                                        <label>Apt, Suite, etc.</label>
                                        <p>{this.state.place}</p>
                                    </div>
                                    <div className="col-12 form-group"><hr />
                                    <p className='mb-0 text-red'><strong>CATATAN</strong></p>
                                        <p className='mb-0 text-red'>Mohon menuliskan alamat dengan lengkap,</p>
                                        <p className='text-red'>Kesalahan pengiriman karena alamat yang tidak lengkap bukan tanggung jawab kami</p>
                                        {this.state.ship == 'rpx' || this.state.ship == 'sap' ? <h2>Masukkan alamat pengiriman khusus <span className='text-uppercase'>{this.state.ship}</span></h2> :
                                            <FormControlLabel
                                                control={<Switch checked={this.state.dropship} onChange={this.handleDropship} name="dropship" />}
                                                label='Kirim sebagai dropship'
                                            />}

                                    </div>
                                    <div className={this.state.dropship ? 'd-flex row m-0' : 'd-none'}>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Depan</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='firstNameShipping'
                                                           name="firstNameShipping"
                                                           label="Nama Depan"
                                                           onChange={(e) => this.handleChange(e, 'firstNameShipping')}
                                                           value={this.state.firstNameShipping}
                                                           fullWidth
                                                />
                                                {validator.message('firstNameShipping', this.state.firstNameShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.firstNameShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Belakang</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='lastNameShipping'
                                                           name="lastNameShipping"
                                                           label="Nama Belakang"
                                                           onChange={(e) => this.handleChange(e, 'lastNameShipping')}
                                                           value={this.state.lastNameShipping}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.lastNameShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Telepon</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='phoneShipping'
                                                           name="phoneShipping"
                                                           label="Telepon"
                                                           onChange={(e) => this.handleChange(e, 'phoneShipping')}
                                                           value={this.state.phoneShipping}
                                                           fullWidth
                                                />
                                                {validator.message('phoneShipping', this.state.phoneShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.phoneShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Nama Perusahaan</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='companyShipping'
                                                           name="companyShipping"
                                                           label="Nama Perusahaa"
                                                           onChange={(e) => this.handleChange(e, 'companyShipping')}
                                                           value={this.state.companyShipping}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.companyShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Apt, Suite, etc.</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='placeShipping'
                                                           name="placeShipping"
                                                           label="Lokasi"
                                                           onChange={(e) => this.handleChange(e, 'placeShipping')}
                                                           value={this.state.placeShipping}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.placeShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Address*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='addressShipping'
                                                           name="addressShipping"
                                                           label="Address"
                                                           onChange={(e) => this.handleChange(e, 'addressShipping')}
                                                           value={this.state.addressShipping}
                                                           fullWidth
                                                />
                                                {validator.message('addressShipping', this.state.addressShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.addressShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Provinsi*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="provinceShipping"
                                                           name="provinceShipping"
                                                           label="Provinsi"
                                                           onChange={(e) => this.handleProvince(e, 'provinceShipping')}
                                                           value={this.state.provinceShipping}
                                                           fullWidth
                                                >
                                                    {this.state.provinces.map(option => (
                                                        <MenuItem key={option.province_id} value={option.province_id}>
                                                            {option.province}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validator.message('provinceShipping', this.state.provinceShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.provinceShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kota*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="cityShipping"
                                                           name="cityShipping"
                                                           label="Kota"
                                                           onChange={(e) => this.handleCity(e, 'cityShipping')}
                                                           value={this.state.cityShipping}
                                                           fullWidth
                                                >
                                                    {this.state.cities.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.type} {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validator.message('cityShipping', this.state.cityShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.cityShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kecamatan*</label>
                                                <TextField variant="outlined"
                                                           select
                                                           id="subdistrictShipping"
                                                           name="subdistrictShipping"
                                                           label="Kecamatan"
                                                           onChange={(e) => this.handleChange(e, 'subdistrictShipping')}
                                                           value={this.state.subdistrictShipping}
                                                           fullWidth
                                                >
                                                    {this.state.subdistricts.map((option,id) => (
                                                        <MenuItem key={id} value={this.state.ship == 'rpx' ? option.kecamatan : option.name}>
                                                            {this.state.ship == 'rpx' ? option.kecamatan : option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {validator.message('subdistrictShipping', this.state.subdistrictShipping, 'required')}
                                                <div className='text-danger'>{this.state.errors.subdistrictShipping}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Kode Pos*</label>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id="zipCodeShipping"
                                                           name="zipCodeShipping"
                                                           label="Kode Pos"
                                                           value={this.state.zipCodeShipping}
                                                           onChange={(e) => this.handleChange(e, 'zipCodeShipping')}
                                                           fullWidth
                                                />
                                                {validator.message('zipCodeShipping', this.state.zipCodeShipping, 'required')}

                                                <div className='text-danger'>{this.state.errors.zipCodeShipping}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 form-group">
                                        <label>Catatan Order</label>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id="note"
                                                   name="note"
                                                   placeholder="Jika ada pesan atau catatan khusus"
                                                   onChange={(e) => this.handleChange(e, 'note')}
                                                   value={this.state.note}
                                                   fullWidth
                                                   multiline
                                        />
                                        <div className='text-danger'>{this.state.errors.note}</div>
                                    </div>
                                </div>
                                <div className='section-title mt-4'>
                                    <h2>Metode Pembayaran</h2>
                                </div>

                                {this.state.banks.map(function (option) {
                                    return <label className='radio-option' key={option.id}>
                                        <input type="radio" value={option.id} name='bank' onChange={this.handleBank} />
                                        <span>{option.bankName}</span>
                                    </label>
                                }.bind(this))
                                }
                                {validator.message('bank', this.state.bank, 'required')}
                                <div className='text-danger'>{this.state.errors.bank}</div>

                            </form>
                            <div className='col-md-5'>
                                {!this.state.isReseller &&
                                <div className='alert alert-poin'>
                                    <p className='mb-0 text-center'>Kamu akan dapatkan point reward sebesar:<br />
                                        <strong>{this.state.pointAll}</strong> jika menyelesaikan order ini.
                                    </p>
                                </div>
                                }

                                <div className='review-wrapper'>

                                    {this.state.loadingCart ?
                                        <div className='item-review align-items-center'>
                                            <div className='img-wrap product mb-3'>
                                                <Skeleton variant="rect" width={120} height={120} />

                                            </div>
                                            <div className='content col-md-4'>
                                                <Skeleton variant="text" />

                                                <Skeleton variant="text" />


                                                <Skeleton variant="text" />

                                            </div>
                                            <div className='btn-action ml-auto'>
                                                <Skeleton variant="rect" width={30} height={30} />
                                            </div>
                                        </div> :
                                        this.state.carts.length > 0 ?
                                            this.state.carts.map((row, id) => (
                                                <div className='item-review align-items-center' key={id}>
                                                    <div className='img-wrap product mb-3'>
                                                        <img src={row.image} alt='user' className='img-avatar' />
                                                    </div>
                                                    <div className='content'>
                                                        <h3>{row.name} {row.attribute ? '- '+row.attribute : ''}</h3>
                                                        <small className='d-block'>SKU: {row.sku}</small>
                                                        <p>{row.quantity}x <strong className='text-yellow'>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </strong></p>

                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='item-review align-items-center justify-content-center'>
                                                <div className='content'>
                                                    <h3>Keranjang kosong</h3>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {(this.state.couponValid && this.state.couponValue > 0) &&
                                <button className='btn-redeem' type='button'
                                        disabled={false}>
                                    <i className="fas fa-tag"> </i>
                                    <span>{this.state.couponValid}</span>
                                    { this.state.couponValue > 0 &&
                                    <i className="fas fa-check-circle valid"> </i>
                                    }
                                </button>
                                }
                                {this.state.isFree &&
                                <button className='btn-redeem' type='button'
                                        disabled={false}>
                                    <i className="fas fa-tag"> </i>
                                    <span>Free Ongkir</span>
                                    { this.state.isFree > 0 &&
                                    <i className="fas fa-check-circle valid"> </i>
                                    }
                                </button>
                                }

                                {this.state.redeemPoint &&
                                <button className='btn-redeem' type='button'
                                        disabled={false}>
                                    <i className="icon-point">P</i>
                                    <span>Redeem poin : {this.state.redeemPoint}</span>
                                    { this.state.redeemValue > 0 &&
                                    <i className="fas fa-check-circle valid"> </i>
                                    }
                                </button>
                                }

                                <div className='row mt-3 '>
                                    <div className='col-8'>
                                        <p>Total belanja</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                <div className='row '>
                                    <div className='col-6'>
                                        <p>Biaya Kirim</p>
                                    </div>
                                    <div className='col-6 text-right'>
                                        {!this.state.isOnline &&
                                        <button className='btn btn-ongkir mb-2' onClick={this.checkCost}>CEK
                                            ONGKIR</button>
                                        }
                                        <p>
                                            Rp {this.state.loadingShipping ? <i className="fa fa-spinner fa-spin"> </i> :
                                                this.state.shippingCost && <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className='row '>
                                    <div className='col-8'>
                                        <p>Kode Unik</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p>
                                            Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.uniqueCode} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                {this.state.couponValue > 0 ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p>Diskon Kupon</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.couponValue} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div> : ''
                                }
                                {this.state.isFree ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p>Diskon Kupon Ongkir</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div> : ''
                                }
                                {this.state.redeemValue > 0 ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p>Redeem poin</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.redeemValue} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div> : ''
                                }

                                <div className='row pt-3 border-top'>
                                    <div className='col-8'>
                                        <p><strong>Grand Total</strong></p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        {total < 0 ?
                                            <p>
                                                <small className='discount-price d-block'>
                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false}
                                                                     value={total} displayType={'text'} />
                                                </small>
                                                <strong> Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false}
                                                                          value={0} displayType={'text'}
                                                                          renderText={value => <span>{value}</span>}/>
                                                </strong>
                                            </p>
                                            :
                                            <p><strong>
                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={total} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong></p>
                                        }

                                    </div>
                                </div>

                                <FormGroup className='mb-3 label-tnc'>
                                    <FormControlLabel
                                        color="primary"
                                        name='check'
                                        control={<Checkbox
                                            checked={this.state.check}
                                            onChange={this.handleChecked}
                                            name='check'
                                            value={true}
                                        />}
                                        label={<span>Saya telah membaca dan menyetujui <Link to='/'>syarat dan ketentuan</Link></span>}

                                        className="mb-0"
                                    />
                                    {validator.message('check', this.state.check, 'required')}
                                    <div className='text-danger'>{this.state.errors.check}</div>
                                </FormGroup>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='full'
                                    disabled={this.state.loadingButton || this.state.config === 'CLOSE'}
                                    onClick={this.handleSubmit}
                                >
                                    Lanjut Pembayaran
                                    { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </section> :
                        <section className='row section-products'>
                            <div className='col-12 mb-3'>
                                <h1>{this.state.orderNumber} <date>{this.state.orderDate}</date></h1>
                                <i>
                                    Mohon lakukan pembayaran maksimal 30 menit setelah order. Jika sudah melakukan pembayaran harap konfirmasi pembayaran ke Email: cs@gudangemas.com
                                    dan/atau WA: Kirim pesan Konfirmasi</i>
                            </div>

                            <div className='col-md-7'>
                                <div className='cart-detail mb-3'>
                                    <h2>Produk</h2>

                                    <div className='review-wrapper'>
                                        {this.state.carts.length > 0 &&
                                        this.state.carts.map((row, id) => (
                                            <div className='item-review align-items-center' key={id}>
                                                <div className='img-wrap product mb-3'>
                                                    <img src={row.image} alt='user' className='img-avatar' />
                                                </div>
                                                <div className='content'>
                                                    <h3>{row.name}</h3>
                                                    <p>{row.quantity}x <strong className='text-yellow'>
                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </strong></p>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='cart-detail'>
                                    <h2>Payment</h2>
                                    <img src={this.state.logo} alt='user' className='img-bank mb-3' />

                                    <p className='mb-0'>{this.state.accountName}</p>
                                    <small>Account Number:</small>
                                    <p className='big-num'>
                                        {this.state.accountNumber}</p>
                                    <div className='row mt-3 '>
                                        <div className='col-8'>
                                            <p>Total belanja</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalAmount} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-8'>
                                            <p>Biaya Kirim</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-8'>
                                            <p>Kode Unik</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.uniqueCode} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div>
                                    {this.state.couponValue > 0 ?
                                        <div className='row'>
                                            <div className='col-8'>
                                                <p>Diskon Kupon</p>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <p>
                                                    Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.couponValue} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </p>
                                            </div>
                                        </div> : ''
                                    }
                                    {this.state.isFree ?
                                        <div className='row'>
                                            <div className='col-8'>
                                                <p>Diskon Kupon Ongkir</p>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <p>
                                                    Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </p>
                                            </div>
                                        </div> : ''
                                    }
                                    {this.state.redeemValue > 0 ?
                                        <div className='row'>
                                            <div className='col-8'>
                                                <p>Redeem poin</p>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <p>
                                                    Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.redeemValue} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </p>
                                            </div>
                                        </div> : ''
                                    }

                                    <div className='row pt-3 border-top'>
                                        <div className='col-8'>
                                            <p><strong>Grand Total</strong></p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p><strong>
                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={total} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='cart-detail'>
                                    <h2>Customer Information</h2>
                                    <div className='review-wrapper'>
                                        <div className='item-review pb-3 align-items-center'>
                                            {/*<div className='img-wrap' style={{width:'auto'}}>
                                                <img src={Default} alt='user' className='user-photo' />
                                            </div>*/}
                                            <div className='content col-12'>
                                                <h3>{this.state.user && this.state.user['full-name']}</h3>
                                                <time>{this.state.user && this.state.user['phone-number']}</time>
                                                <date>{this.state.user && this.state.user['email']}</date>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='border-bottom pt-3'>
                                        <h4>Alamat Pengiriman</h4>

                                        <p className='mb-0'>{this.state.phoneShipping}</p>
                                        <p>{this.state.firstNameShipping} {this.state.lastNameShipping} <br />
                                            {this.state.companyShipping}, {this.state.placeShipping}<br />
                                            {this.state.addressShipping}<br />
                                            {this.state.subdistrictShipping}, {this.state.cityShipping}<br />
                                            {this.state.provinceShipping}
                                        </p>
                                    </div>
                                    <div className='border-bottom pt-3'>
                                        <h4>Alamat Penagihan</h4>
                                        <p className='mb-0'>{this.state.phone}</p>
                                        <p>{this.state.firstName} {this.state.lastName} <br />
                                            {this.state.company}, {this.state.place}<br />
                                            {this.state.address}<br />
                                            {this.state.subdistrictName}, {this.state.cityName}<br />
                                            {this.state.provinceName}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </section>

                    }



                </div>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);