import React,{Component} from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class UsersAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            roles: [],
            showAdd: false,
            showForm: false,
            showPassword: false,

            name: '',
            email: '',
            password: '',
            phone: '',
            address: '',
            birth: null,
            roleId: '',
            additional_roles: '',
            avatar: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Project - Add User';

        Api.get('/roles').then(resp => {

            if (resp.data) {

                this.setState({
                    roles: resp.data,
                })

            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let date = this.state.birth;

        let formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('password', this.state.password);
        formData.append('phone', this.state.phone);
        formData.append('address', this.state.address);
        formData.append('birth', date.getFullYear()+'-'+this.appendLeadingZeroes(date.getMonth()+1)+'-'+this.appendLeadingZeroes(date.getDate()));
        formData.append('roleId', this.state.roleId);
        formData.append('additional_roles', this.state.additional_roles);
        if(this.state.avatar) {
            formData.append('avatar', this.state.avatar);
        }


        Api.putFile('/admins',{
            method: 'POST',
            body: formData
        }).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            history.push('/users');

            this.showMessage(true, 'User successfully added');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleAvatar = e => {
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            avatar: e.target.files[0]
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };




    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        })

    };

    appendLeadingZeroes = n =>{
        if(n <= 9){
            return "0" + n;
        }
        return n
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12 px-lg-5">
                    <h1 className="page-title">Create Admin</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/users" >Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Create</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12 mt-3 px-lg-5">
                    <div className="table-wrapper">
                        <form name="add" id="addUser" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group">
                                    {/*<img src={this.state.photo} alt="user" className="user-photo mr-2" />*/}
                                    <TextField variant="outlined"
                                               type="file"
                                               id='avatar'
                                               name="avatar"
                                               className="avatar d-inline"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="avatar" className="label-file btn btn-blue">Upload Avatar</label>
                                    <div className='text-danger'>{this.state.errors.avatar}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='name'
                                               name="name"
                                               label="Full Name"
                                               onChange={(e) => this.handleChange(e, 'name')}
                                               value={this.state.name}
                                               fullWidth
                                    />
                                    {validator.message('name', this.state.name, 'required')}
                                    <div className='text-danger'>{this.state.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='email'
                                               name="email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email|required')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <TextField variant="outlined"
                                               type={this.state.showPassword ? 'text' : 'password'}
                                               id="adornment-password"
                                               name="password"
                                               label="Password"
                                               onChange={(e) => this.handleChange(e,'password')}
                                               value={this.state.password}
                                               fullWidth
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="Toggle password visibility"
                                                               onClick={this.handleClickShowPassword}
                                                           >
                                                               {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                   <i className="fas fa-eye-slash"> </i>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   )
                                               }}
                                    />
                                    {validator.message('password', this.state.password, 'required|min:8')}
                                    <div className='text-danger'>{this.state.errors.password}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date of Birth</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            label="Birth Date"
                                            value={this.state.birth}
                                            onChange={(date) => this.handleDate(date, 'birth')}
                                            format={'dd MMM yyyy'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validator.message('birth', this.state.birth, 'required')}
                                    <div className='text-danger'>{this.state.errors.birth}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='address'
                                               name="address"
                                               label="Address"
                                               onChange={(e) => this.handleChange(e, 'address')}
                                               value={this.state.address}
                                               fullWidth
                                    />
                                    {validator.message('address', this.state.address, 'required')}
                                    <div className='text-danger'>{this.state.errors.address}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <TextField variant="outlined"
                                               type='number'
                                               id='phone'
                                               name="phone"
                                               label="Phone"
                                               onChange={(e) => this.handleChange(e, 'phone')}
                                               value={this.state.phone}
                                               fullWidth
                                    />
                                    {validator.message('phone', this.state.phone, 'required')}
                                    <div className='text-danger'>{this.state.errors.phone}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Role</label>
                                    <TextField variant="outlined"
                                               select
                                               id="roleId"
                                               name="roleId"
                                               label="Role"
                                               onChange={(e) => this.handleChange(e, 'roleId')}
                                               value={this.state.roleId}
                                               fullWidth
                                    >
                                        {this.state.roles.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.display_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('roleId', this.state.roleId, 'required')}
                                    <div className='text-danger'>{this.state.errors.roleId}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Additional Role</label>
                                    <TextField variant="outlined"
                                               select
                                               id="additionalRoles"
                                               name="additionalRoles"
                                               label="Additional Roles"
                                               onChange={(e) => this.handleChange(e, 'additional_roles')}
                                               value={this.state.additional_roles}
                                               fullWidth
                                    >
                                        <MenuItem value="adm-report">
                                            Admin Report
                                        </MenuItem>
                                        <MenuItem value="adm-approval">
                                            Admin Approval
                                        </MenuItem>
                                    </TextField>
                                    <div className='text-danger'>{this.state.errors.additional_roles}</div>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/users')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=""
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Submit{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                <ToastContainer />

            </div>
        )
    }
}

export default UsersAdd;
