import React,{Component} from 'react'
import Error from '../../../images/error.png';


class NotFound extends Component {

    componentDidMount(){

        document.title = 'Gudangemas - 404';

    };

    render() {
        return (
            <main className='pages'>
                <section className='container text-center'>

                    <img src={Error} alt='error' />
                <h1>Ooops, tujuanmu nggak ada!</h1>
                    <div className='mt-3'>
                        <a href='/' className='btn-blue'>Kembali</a>
                    </div>

                </section>
            </main>
        )
    }
}

export default NotFound;

