import React,{Component} from 'react';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';

import Api from '../../../libraries/api';
import {history} from '../../../shared/configure-store';
import Default from "../../../images/default.jpeg";
import NumberFormat from "react-number-format";

require('dotenv').config();


class Done extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            isFree: false,
            code: '',
            orderDate: '',
            company: '',
            place: '',
            address: '',
            province: '',
            city: '',
            subdistrict: '',
            firstName: '',
            lastName: '',
            phone: '',
            provinceName: '',
            cityName: '',
            subdistrictName: '',
            note: '',


            companyShipping: '',
            placeShipping: '',
            addressShipping: '',
            provinceShipping: '',
            cityShipping: '',
            subdistrictShipping: '',
            firstNameShipping: '',
            lastNameShipping: '',
            phoneShipping: '',

            shippings: [],
            ship: null,

            banks: [],
            bank: null,

            carts: [],

            coupon: null,
            couponValid: '',
            couponValue: 0,
            redeemPoint: 0,
            redeemValue: 0,
            pointAll: 0,
            check:null,

            zipCodeShipping: '',
            shippingCost: '',
            shippingCostDetail: '',
            uniqueCode: '',
            weightTotal: 0,
        };
    }

    componentDidMount() {
        document.title = 'Gudangemas - Order Berhasil';

        const id = this.props.match.params.id;
        Api.get('/order/get-order-by-code/'+id).then(resp => {

            let data = resp.data.order;

            if (data) {
                this.setState({
                    code: data.orderCode,
                    orderDate: data['order-date'],
                    orders: data['order-details'],
                    status: data.status,
                    name: resp.data.name,
                    username: resp.data.username,
                    email: resp.data.email,
                    coupon: data.coupon,

                    carts: data['order-details'],
                    resi: data['resi-no'],
                    shipping: data['shipping-address'],
                    phoneShipping: data['shipping-address'] ? data['shipping-address']['phone-number'] : '',
                    fullNameShipping: data['shipping-address'] ? data['shipping-address']['full-name'] : '',
                    companyShipping: data['shipping-address'] ? data['shipping-address']['company'] : '',
                    placeShipping: data['shipping-address'] ? data['shipping-address']['place'] : '',
                    addressShipping: data['shipping-address'] ? data['shipping-address']['address'] : '',
                    subdistrictShipping: data['shipping-address'] ? data['shipping-address']['subdistrictName'] : '',
                    cityShipping: data['shipping-address'] ? data['shipping-address']['cityName'] : '',
                    zipCodeShipping: data['shipping-address'] ? data['shipping-address']['postal-code'] : '',
                    provinceShipping: data['shipping-address'] ? data['shipping-address'].provinceName : '',
                    shippingType: data['shipping-type'],
                    note: data['shipping-address'] ? data['shipping-address'].note : '',

                    bank: data.bank,
                    payment: data['payment-method'],
                    date: data['order-date'],
                    shippingCost: data['shipping-cost'],
                    histories: data['order-history'],
                    total: data['total-amount'],
                    uniqueCode: data['unique-code'],
                    grandTotal: data['grand-total'],
                    discountCoupon: data['discount-coupon'],
                    discountRedeem: data['discount-redeem'],
                    loading: false,
                });
            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleGo  = (link) => {

        history.push(link);
    };

    render() {
        return (
            <main className='products'>
                <div className='container'>
                    <section className='row section-products'>
                        <div className='col-12 mb-3'>
                            <h1>{this.state.code} <date>{this.state.orderDate}</date></h1>
                            <i>
                                Mohon lakukan pembayaran maksimal 30 menit setelah order. Jika sudah melakukan pembayaran harap konfirmasi pembayaran ke Email: cs@gudangemas.com
                                dan/atau WA: Kirim pesan Konfirmasi</i>
                        </div>

                        <div className='col-md-7'>
                            <div className='cart-detail mb-3'>
                                <h2>Produk</h2>

                                <div className='review-wrapper'>
                                    {this.state.carts.length > 0 &&
                                    this.state.carts.map((row, id) => (
                                        <div className='item-review align-items-center' key={id}>
                                            <div className='img-wrap product mb-3'>
                                                <img src={row.image} alt='user' className='img-avatar' />
                                            </div>
                                            <div className='content'>
                                                <h3>{row.name}</h3>
                                                <p>{row.quantity}x <strong className='text-yellow'>
                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                </strong></p>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='cart-detail'>
                                <h2>Payment</h2>
                                <img src={this.state.bank ? this.state.bank.logo : Default} alt='user' className='img-bank mb-3' />

                                <p className='mb-0'>{this.state.bank ? this.state.bank.bankName : ''} <br />
                                    <strong>{this.state.bank ? this.state.bank.accountName : ''}</strong></p>
                                <small>Account Number:</small>
                                <p className='big-num'>
                                    {this.state.bank ? this.state.bank.accountNumber : ''}</p>
                                <div className='row mt-3 '>
                                    <div className='col-8'>
                                        <p>Total belanja</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.total} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                <div className='row '>
                                    <div className='col-8'>
                                        <p>Biaya Kirim</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                <div className='row '>
                                    <div className='col-8'>
                                        <p>Kode Unik</p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p>
                                            Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.uniqueCode} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </p>
                                    </div>
                                </div>
                                {this.state.coupon !== null ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p>Diskon Kupon</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            {this.state.coupon['coupon-type'].code === 'CT-4' ?
                                                <p>{this.state.coupon['coupon-type'].name}</p> :
                                                <p>Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.discountCoupon} displayType={'text'} renderText={value => <span>{value}</span>}/></p>
                                            }
                                        </div>
                                    </div> : ''
                                }

                                {this.state.discountRedeem > 0 ?
                                    <div className='row'>
                                        <div className='col-8'>
                                            <p>Redeem poin</p>
                                        </div>
                                        <div className='col-4 text-right'>
                                            <p>
                                                Rp -<NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.discountRedeem} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </p>
                                        </div>
                                    </div> : ''
                                }

                                <div className='row pt-3 border-top'>
                                    <div className='col-8'>
                                        <p><strong>Grand Total</strong></p>
                                    </div>
                                    <div className='col-4 text-right'>
                                        <p><strong>

                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.grandTotal} displayType={'text'} renderText={value => <span>{value}</span>}/>

                                        </strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='cart-detail'>
                                <h2>Customer Information</h2>
                                <div className='review-wrapper'>
                                    <div className='item-review pb-3 align-items-center'>
                                        {/*<div className='img-wrap' style={{width:'auto'}}>
                                                <img src={Default} alt='user' className='user-photo' />
                                            </div>*/}
                                        <div className='content col-12'>
                                            <h3>{this.state.name}</h3>
                                            <time>{this.state.username}</time>
                                            <date>{this.state.email}</date>
                                        </div>
                                    </div>

                                </div>
                                <div className='border-bottom pt-3'>
                                    <h4>Alamat Pengiriman</h4>

                                    <p className='mb-0'>{this.state.phoneShipping}</p>
                                    <p>{this.state.fullNameShipping}<br />
                                        {this.state.companyShipping}, {this.state.placeShipping}<br />
                                        {this.state.addressShipping}<br />
                                        {this.state.subdistrictShipping}, {this.state.cityShipping}<br />
                                        {this.state.provinceShipping}, {this.state.zipCodeShipping}
                                    </p>

                                    <h4>Catatan</h4>
                                    <p> {this.state.note}</p>
                                </div>
                               {/* <div className='border-bottom pt-3'>
                                    <h4>Alamat Penagihan</h4>
                                    <p className='mb-0'>{this.state.phone}</p>
                                    <p>{this.state.firstName} {this.state.lastName} <br />
                                        {this.state.company}, {this.state.place}<br />
                                        {this.state.address}<br />
                                        {this.state.subdistrictName}, {this.state.cityName}<br />
                                        {this.state.provinceName}
                                    </p>
                                </div>*/}
                            </div>

                        </div>
                    </section>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Done);