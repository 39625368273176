import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Logo from '../../../images/logo.png';
import Image from '../../../images/succcess.svg';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {toast, ToastContainer} from "react-toastify";
import {history} from '../../../shared/configure-store';
import queryString from 'query-string';

require('dotenv').config();

const validator = new SimpleReactValidator();

class ForgotSuccess extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            email: '',
            username: '',
            code: '',
        };


    }

    componentDidMount() {

        document.title = 'Gudangemas - Register';

        let url = this.props.location.search;
        let urlParams = queryString.parse(url);

        if(urlParams) {
            if(urlParams.email) {
                Api.get('/user/get-new-password/'+urlParams.email).then(resp => {

                    if (resp.data) {
                        console.log(resp.data)
                    }

                }).catch(err => {
                    console.log(err);
                });
            }
            this.setState({
                email: urlParams.email,
                    loading: false
                }
            );


        }
    }


    render() {
        return (
            <main className='register'>
                <section className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6 col-lg-5 text-center'>
                            <Link to='/' className="navbar-brand">
                                <img src={Logo} alt='logo' className='img-logo my-4 my-md-5' width={280} />
                            </Link>
                            <p className='mb-3'>Permintaan Anda berhasil dilakukan. Silahkan cek email Anda untuk password baru.</p>
                            <img src={Image} className='img-fluid my-4' />
                        </div>

                    </div>
                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotSuccess);