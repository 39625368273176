import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import Lightbox from 'react-image-lightbox';
import NumberFormat from 'react-number-format';
import {toast, ToastContainer} from "react-toastify";
import { Link } from 'react-router-dom';
import Cart from '../../../images/product-cart.svg';
import Produk from '../../../images/product.png'
import Default from '../../../images/default.jpeg'
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Button from '@material-ui/core/Button';
import Slider from "react-slick";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';
import _ from 'lodash';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment/moment";
import Skeleton from "@material-ui/lab/Skeleton";
import templateId from "../../../libraries/lang-id";
SimpleReactValidator.addLocale('indo', templateId);

require('dotenv').config();

const validator = new SimpleReactValidator({
    locale: 'indo'
});
class ProductsDetail extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            value: 0,
            showDialogCart: false,
            anchorEl: null,
            images : [],
            photoIndex: 0,
            isOpen: false,

            id: '',
            name: '',
            description: '',
            notes: '',
            sku: '',
            weight: '',
            length: '',
            width: '',
            height: '',
            category: '',
            image: '',
            productTypeId: '',
            regularPrice: '',
            salePrice: '',
            startDate: '',
            endDate: '',
            code: '',
            type: '',
            purchaseNote: '',
            enableReview: false,
            referralPoints: [],
            subCategories: [],
            galleries: [],
            attributes: [],
            variations: [],
            rows: [],
            totalReview: 0,
            ratingPoint: 0,
            reviews: [],
            isSale: false,

            user: '',
            isReview: false,
            title: '',
            comment: '',

            selected: null,
            quantity: 0,
            quantityCurrent: 0,

            config: '',
            configText: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);

    }

    componentDidMount() {
        document.title = 'Gudangemas - Produk';
        this.__fetch();

        Api.get('/config/2').then(resp => {

            if (resp.data) {
                this.setState({
                        config: resp.data.value ? resp.data.value : 'OPEN',
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/6').then(resp => {

            if (resp.data) {
                this.setState({
                        configText: resp.data.value ? resp.data.value : '',
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
        }
    }

    __fetch = () => {
        window.scrollTo(0, 0);
        const id = this.props.match.params.id;


        let user = AuthHelper.getProfile();

        if(user) {
            this.setState({
                    user: user,
                }
            );
        }

        Api.get('/product/by-sku/'+id).then(resp => {
            let now = moment(Date.now());

            let data = resp.data;
            let start = moment(data['sale-start-date'],'YYYY-MM-DD');
            let end = moment(data['sale-end-date'],'YYYY-MM-DD');

            if (data) {

                let index = 0;
                let images = data['galeries'].map((o,i) => {
                    if(o.isFavourite) {
                        index = i;
                    }
                    return o.image;
                });

                let variations = data['tab-variation'].map((o,i) => {
                    return {
                        id: o.id,
                        weight: o.weight,
                        quantity: 0,
                        stock: o['stock-quantity'],
                        sku: o.sku,
                        selected: false,
                        attr: o.attributes.map((o,i) => {
                            return i === 0 ? o['id-value'] : ' - '+o['id-value']
                            }),

                    }
                });

                let subs = [];
                data['sub-categories'].map((o) => {
                    if(o['is-check']) {
                        subs.push(o);
                    }

                });

                this.setState({
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    notes: data.notes,
                    sku: data.sku,
                    weight: data.weight,
                    length: data.length,
                    width: data.width,
                    height: data.height,
                    category: data.category,
                    ratingPoint: data.ratingPoint,
                    image: data.image ? data.image : Produk,
                    productTypeId: data['product-type-id'],
                    regularPrice: data['regular-price'],
                    salePrice: data['sale-price'],
                    startDate: data['sale-start-date'],
                    endDate: data['sale-end-date'],
                    quantityCurrent: data['stock-quantity'],
                    purchaseNote: data['purchase-note'],
                    enableReview: data['enable-reviews'],
                    referralPoints: data['referal-points'],
                    subCategories: subs,
                    galleries: data['galeries'],
                    images: images,
                    photoIndex: index,
                    isSale: moment(now).isBetween(start, end),
                    attributes: data['tab-attribute'],
                    type: data['product-type-id'],
                    code: data['product-type-code'],
                    variations: variations,
                    loading: false
                },() => {
                    this.__fetchReview();
                    this.__fetchData();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchData = update => {
        let page = update ? 0 : this.state.nextPage;

        this.setState({
                loading: true
            }
        );
        let params = {
            page: 0,
            size: 4,
            ['is-active']: true,
            ['category-id']: this.state.category ? this.state.category.id : '',
            ['sub-category-id']: this.state.subCategories.length > 0 ? this.state.subCategories[0].id : '',
        };

        Api.post('/product/view', params).then(resp => {

            let now = moment(Date.now());

            if(resp.data) {
                let products = resp.data.viewProducts.map(o => {
                    let start = moment(o['sale-start-date'],'DD/MM/YYYY');
                    let end = moment(o['sale-end-date'],'DD/MM/YYYY');

                    return {
                        id: o.id,
                        sku: o.sku,
                        image: o.image,
                        stock: parseFloat(o.stock),
                        rating: parseFloat(o.rating),
                        weight: o.weight,
                        category: o['category-name'],
                        sub: o['sub-catgeory-name'],
                        name: o['product-name'],
                        price: o['regular-price'],
                        priceSale: o['sale-price'],
                        isSale: moment(now).isBetween(start, end),
                        startSale: o['sale-start-date'],
                        endSale: o['sale-end-date'],
                        type: o['product-type-name'],
                        code: o['product-type-code'],
                        quantity: 0,
                    }
                });
                this.setState({
                        rows: products,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchReview = () => {

        let params = {
            page: 0,
            size: 50,
            status: 'APPROVE',
            'product-id': this.state.id
        };

        Api.post('/product-review/get-all', params).then(resp => {

            if (resp.data) {

                this.setState({
                    totalReview: resp.data.totalItems,
                    reviews: resp.data.productReviews,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange (e, prop){


        this.setState({

            [prop]: e.target.value

        });


    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleClick = (event) => {

        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {

        this.setState({
            anchorEl: null
        })
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    handleReview = () => {
        this.setState({
                isReview: !this.state.isReview,
            }
        );
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'customer-code': this.state.user['customer-code'],
            'description': this.state.comment,
            'id-product': this.state.id,
            'rating': this.state.rate,
            'title': this.state.title,
        };

        Api.post('/product-review/create', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Review terkirim,menunggu persetujuan admin');
                this.setState({
                        isReview: false,
                },() => {
                    this.__fetchReview();
                });
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });


    };

    handleRate = (i) => {
        this.setState({
            rate: i,
            }
        );
    };


    handleGo  = (link) => {

        history.push(link);
    };


    handleSub = (e, sku) => {
        let data = this.state.variations;


        _.forEach(data, function (v, k) {
            console.log(v)
            if (v.id === sku.id) {
                v.selected = true;
            }else {
                v.selected = false;
            }
        });
        this.setState({
            selected: sku,
            variations: data,
            }
        );

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleMinus = (e) => {
        e.preventDefault();
        let q = this.state.quantity - 1;

        this.setState({
            quantity: q,
        });
    };

    handlePlus = (e) => {
        e.preventDefault();
        let message = '';
        let pop = false;
        let q = this.state.quantity;

        if(this.state.code === 'VP') {
            if(this.state.selected !== null) {

                if ((q + 1) <= this.state.selected.stock) {
                    q = q + 1;
                } else {
                    pop = true;
                    message = 'Stok tidak mencukupi.';
                }
                this.setState({
                    sku: this.state.selected.sku,
                });
            }else {
                this.showMessage(false, 'Pilih variasi terlebih dahulu');
            }



        }else {
            if ((q + 1) <= this.state.quantityCurrent) {
                q = q + 1;
            } else {
                pop = true;
                message = 'Stok tidak mencukupi.';
            }

        }

        if (pop) {
            this.showMessage(false, message);
        }

        this.setState({
            quantity: q,
        });
    };

    handleCart = (e) => {

        let params = {
            'customer-code': this.state.user['customer-code'],
            price: this.state.isSale ? this.state.salePrice : this.state.regularPrice,
            quantity: this.state.quantity,
            sku: this.state.sku,
        };


        Api.post('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.setState({
                        showDialogCart: true,
                    }
                );

                this.props.setShowCart();
            }else {
                this.showMessage(false, resp.message[0]);

            }
            this.setState({
                    loading: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleCloseCart = () => {
        this.setState({
            showDialogCart :false,
        })
        window.location.reload();
    };

    handleCartC = (e, row) => {
        let profile = AuthHelper.getProfile();

        if(!profile) {
            this.showMessage(false, 'Anda belum masuk, silahkan login terlebih dahulu');
            return false;
        }

        let params = {
            'customer-code': this.state.user['customer-code'],
            price: row.isSale ? row.priceSale : row.price,
            quantity: row.quantity,
            sku: row.sku,
        };

        Api.post('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.setState({
                        showDialogCart: true,
                    }
                );

                this.props.setShowCart();
            }
            this.setState({
                    loading: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handlePlusC = (e, id) => {
        e.preventDefault();
        let data = this.state.rows;
        let message = '';
        let pop = false;
        _.forEach(data, function (v, k) {
            if (v.id === id) {
                if(v.code === 'VP') {
                    pop = true;
                    message = 'Pilih variasi terlebih dahulu di detil produk';
                }else {
                    if ((v.quantity + 1) <= v.stock) {
                        v.quantity = v.quantity + 1;
                    } else {
                        pop = true;
                        message = 'Stok tidak mencukupi.';
                    }
                }
            }
        });

        if (pop) {
            this.showMessage(false, message);
        }

        this.setState({
            rows: data,
        });
    };

    handleMinusC = (e, id) => {
        e.preventDefault();
        let data = this.state.rows;


        _.forEach(data, function (v, k) {
            if (v.id === id) {
                v.quantity = v.quantity - 1;
            }
        });

        this.setState({
            rows: data,
        });

    };

    render() {
        const settings = {
            dots: false,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            infinite: false,
            verticalSwiping: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        verticalSwiping: false,
                        vertical: false,
                    }
                }],
        };

        const Star = (item) => {
            const items = [];

            for (let i = 1; i <= 5 ; i++) {
                items.push(
                    <span key={i} className={i <= item.item ? 'star-icon yes' : 'star-icon'}>
                    <i className="fas fa-star"> </i>
                </span>
                )
            }
            return items;
        };


        return (
            <main className='products'>
                <div className='container'>
                    {this.state.config === 'CLOSE' &&
                    <div className='alert'>
                        <p className='mb-0'><i className="fas fa-exclamation-circle mr-2"> </i> {this.state.configText}</p>
                    </div>
                    }
                    <section className='row section-products'>
                        <div className='col-md-6'>
                            <div className='row flex-lg-row-reverse'>
                                <div className='col-md-9'>
                                    <img src={this.state.image ? this.state.image : Produk} alt='product' className='img-fluid' onClick={() => this.setState({ isOpen: true })} />
                                </div>
                                <div className='col-md-3'>
                                    {this.state.galleries.length > 0 &&
                                    <Slider {...settings} >
                                        {this.state.galleries.map((row,key) => (
                                            <div key={key}>
                                                <img src={row.image} alt='product' className='img-fluid' />
                                            </div>
                                    ))}
                                    </Slider>
                                    }

                                </div>

                            </div>

                            {this.state.isOpen && (
                                <Lightbox
                                    mainSrc={this.state.images[this.state.photoIndex]}
                                    nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                                    prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                                        })
                                    }
                                />
                            )}

                        </div>
                        <div className='col-md-6'>
                            <h1>{this.state.name}</h1>
                            <div className='d-md-flex mb-3'>
                                {this.state.isSale ?
                                    <div className='d-block'>
                                        <strong className='d-block discount-price'>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.regularPrice} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </strong>
                                        <strong className='d-block text-yellow'>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.salePrice} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </strong>
                                    </div>
                                    :
                                    <div className='d-block'>
                                        <strong className='d-block text-yellow'>
                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.regularPrice} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                        </strong>
                                    </div>
                                }

                                <div className='star-wrap mx-3'>
                                    <Star item={this.state.ratingPoint} />
                                </div>
                                <p className='mb-0'>{this.state.totalReview} Reviews</p>
                            </div>

                            <hr />

                            <p className='mb-2'><strong>Kategori:</strong> {this.state.category && this.state.category.name}</p>
                            <p className='mb-2 tag-options'><strong className='mr-2'>Tag:</strong>
                                {this.state.subCategories.length > 0 &&
                                this.state.subCategories.map(o =>
                                    (<span className='tags' key={o.name}>{o.name}</span>)
                                )}
                            </p>
                            <p className='mb-2'><strong>SKU:</strong> {this.state.sku}</p>

                            {this.state.variations.length > 0 &&
                            <div className='tags-filter'>
                                <p className='mb-2'><strong>Variasi:</strong></p>
                                <ul className='tags-item'>
                                    {this.state.variations.map(option => (
                                        <li className={option.selected === true ? 'active' : ''} key={option.id}
                                            onClick={(e) => this.handleSub(e, option)}>{option.attr}</li>

                                    ))}
                                </ul>
                            </div> }
                            <div className='row mt-3'>
                                <div className='col-md-6 col-lg-4'>
                                    <div className='btn-group'>
                                        <button className='btn-minus'
                                                disabled={this.state.quantity <= 0 ? true : false}
                                                onClick={this.handleMinus}
                                        >
                                            <i className="fas fa-minus-circle"> </i>
                                        </button>
                                        <input type='text' value={this.state.quantity} readOnly />
                                        <button className='btn-plus'
                                                onClick={this.handlePlus}
                                        >
                                            <i className="fas fa-plus-circle"> </i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                    <Button
                                         variant="contained"
                                        color="primary"
                                        className='full'
                                        disabled={(!this.state.user || this.state.quantity <= 0 || this.state.config === 'CLOSE') ? true : false}
                                        onClick={this.handleCart}
                                    >
                                        <i
                                            className="fas fa-shopping-cart mr-2"> </i> Beli
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <div className='col-12'>
                            <AppBar position="static">
                                <Tabs value={this.state.value}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      onChange={this.handleChangeTab} aria-label="produk tabs">
                                    <Tab label="DESKRIPSI" {...a11yProps(0)} />
                                    <Tab label="REVIEW" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <p className='description'>{this.state.description}</p>

                                <strong>Notes:</strong>
                                <p>{this.state.notes}</p>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Customer Reviews</h4>
                                        <div className='d-md-flex align-items-center mb-3 border-bottom pb-3'>
                                            <div className='star-wrap mr-3'>
                                                <Star item={this.state.ratingPoint} />
                                            </div>
                                            <p className='mb-0'>{this.state.totalReview} Reviews</p>

                                            {(this.state.user && this.state.enableReview)  &&
                                            <button type='button' className='btn-link ml-auto' onClick={this.handleReview}>Review produk</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                                {this.state.isReview &&
                                <form className='row pt-3'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='title'
                                                       name="title"
                                                       label="Title"
                                                       onChange={(e) => this.handleChange(e, 'title')}
                                                       value={this.state.title}
                                                       fullWidth
                                            />
                                            {validator.message('title', this.state.title, 'required')}
                                            <div className='text-danger'>{this.state.errors.title}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Konten Review</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='comment'
                                                       name="comment"
                                                       label="Konten"
                                                       onChange={(e) => this.handleChange(e, 'comment')}
                                                       value={this.state.comment}
                                                       multiline
                                                       fullWidth
                                            />
                                            {validator.message('comment', this.state.comment, 'required')}
                                            <div className='text-danger'>{this.state.errors.comment}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Rating</label>
                                            <div className='btn-rate'>
                                                <span className={this.state.rate >=1 ? 'star-icon yes' : 'star-icon'} onClick={() => this.handleRate(1)}>
                                                    <i className="fas fa-star"> </i>
                                                </span>
                                                <span className={this.state.rate >=2 ? 'star-icon yes' : 'star-icon'} onClick={() => this.handleRate(2)}>
                                                    <i className="fas fa-star"> </i>
                                                </span>
                                                <span className={this.state.rate >=3 ? 'star-icon yes' : 'star-icon'} onClick={() => this.handleRate(3)}>
                                                    <i className="fas fa-star"> </i>
                                                </span>
                                                <span className={this.state.rate >=4 ? 'star-icon yes' : 'star-icon'} onClick={() => this.handleRate(4)}>
                                                    <i className="fas fa-star"> </i>
                                                </span>
                                                <span className={this.state.rate >=5 ? 'star-icon yes' : 'star-icon'} onClick={() => this.handleRate(5)}>
                                                    <i className="fas fa-star"> </i>
                                                </span>
                                            </div>
                                            {validator.message('rate', this.state.rate, 'required')}
                                            <div className='text-danger'>{this.state.errors.rate}</div>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton}
                                        >
                                            Kirim { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </div>

                                </form>
                                }

                                <div className='review-wrapper'>
                                    {this.state.reviews.length > 0 &&
                                    this.state.reviews.map(option => (
                                        <div className='item-review' key={option.id}>
                                            <div className='img-wrap'>
                                                <img src={option.image ? option.image : Default} alt='user' className='img-avatar' />
                                            </div>
                                            <div className='content col-md-6'>
                                                <h3>{option.name}</h3>
                                                <time>{option['submited-on']}</time>
                                                <div className='star-wrap mr-3'>
                                                    <Star item={option.rate} />
                                                </div>
                                                <p>{option.comment}</p>
                                            </div>
                                        </div>

                                    ))}
                                </div>

                            </TabPanel>
                        </div>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h2>PRODUK TERKAIT</h2>
                            </div>
                            {this.state.loading ?
                                <div className='row'>
                                    <div className='col-6 col-md-3 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3 mb-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>

                                </div>
                                :
                                this.state.rows.length > 0 &&
                                <div className='row'>
                                    {this.state.rows.map((row, id) => (
                                        <div className='col-6 col-md-3' key={id}>
                                            <div className='card-product'>
                                                <Link to={'/products/'+row.sku}>
                                                    <div className='card-img'>
                                                        <label>{row.sub}</label>
                                                        <img src={row.image !== null ? row.image : Produk} alt='banner' />
                                                    </div>
                                                </Link>

                                                <div className='card-body'>
                                                    <small>{row.category}</small>
                                                    <Link to={'/products/'+row.sku}>
                                                        {row.name &&
                                                        <h4>{(row.name.length > 30) ? row.name.substring(0,30)+'...' : row.name}</h4>
                                                        }
                                                    </Link>
                                                    {row.stock > 0 ?
                                                    <div className='price'>
                                                        {row.isSale &&
                                                        <p className='discount-price'>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.priceSale} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </p>
                                                        }
                                                        <p>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </p>
                                                    </div> :
                                                        <div className='price'>
                                                            <p>HABIS</p>
                                                        </div>
                                                    }



                                                    <div className='btn-group'>
                                                        <button className='btn-minus'
                                                                disabled={row.quantity <= 0 ? true : false}
                                                                onClick={(e) => this.handleMinusC(e, row.id)}>
                                                            <i className="fas fa-minus-circle"> </i>
                                                        </button>
                                                        <input type='text' value={row.quantity} readOnly />
                                                        <button className='btn-plus'
                                                            /*disabled={row.stock < row.quantity ? true : false}*/
                                                                onClick={(e) => this.handlePlusC(e, row.id)}>
                                                            <i className="fas fa-plus-circle"> </i>
                                                        </button>
                                                    </div>

                                                    <button className='btn btn-bottom'
                                                            disabled={(row.quantity <= 0 || this.state.config === 'CLOSE') ? true : false}
                                                            onClick={(e) => this.handleCartC(e, row)}>
                                                        <i className="fas fa-shopping-cart"> </i> Beli</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            }

                        </div>
                    </section>
                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogCart}
                    onClose={this.handleCloseCart}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Produk berhasil dimasukan ke keranjang belanja</DialogTitle>
                    <DialogContent>
                        <div className='text-center'>
                            <img src={Cart} className='img-fluid' alt='keranjang' />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleCloseCart}>
                            Lanjutkan belanja
                        </button>
                        <button className="btn btn-blue" onClick={() => this.handleGo('/cart')}
                        >
                            Keranjang
                        </button>
                    </DialogActions>
                </Dialog>
                <ToastContainer />
            </main>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className='tab-content'>
                    {children}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDetail);