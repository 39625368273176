import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import Logo from '../../../images/logo.png';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import {toast, ToastContainer} from "react-toastify";
import {history} from '../../../shared/configure-store';
import queryString from 'query-string';

require('dotenv').config();

const validator = new SimpleReactValidator();

class Forgot extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            email: '',
            username: '',
            code: '',
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {

        document.title = 'Gudangemas - Forgot';

        let url = this.props.location.search;
        let urlParams = queryString.parse(url);

        if(urlParams) {
            this.setState({
                code: urlParams.ref,
                    loading: false
                }
            );
        }
    }

    handleSubmit = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            email: this.state.email,

        };

        Api.put('/user/forgot-password', params).then(resp => {

            if(resp.status === 200) {
                history.push('/');
                this.showMessage(true, 'Email sudah dikirim');
            }else {
                this.showMessage(false, resp.message[0]);

            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleChange (e, prop){

        let value = e.target.value;
        if(prop === 'email') {
            this.setState({
                [prop]: value.replace(/\s/g, '')
            })
        }else {
            this.setState({
                [prop]: e.target.value
            })
        }

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (
            <main className='register'>
                <section className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6 col-lg-5 text-center'>
                            <Link to='/' className="navbar-brand">
                                <img src={Logo} alt='logo' className='img-logo my-4 my-md-5' width={280} />
                            </Link>
                            <h1>Atur ulang kata sandi</h1>
                            <p className='mb-3'>Masukkan e-mail yang terdaftar. Kami akan mengirimkan email untuk atur ulang kata sandi.</p>
                            <form name='forgot' id='forgot' className='text-left' noValidate>
                                <div className='form-group'>
                                    <TextField variant='outlined'
                                               type='text'
                                               id='email'
                                               name='email'
                                               label='Email'
                                               placeholder='Your email here...'
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email|required')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='full my-3'
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    KIRIM {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                                <div className='text-danger mb-2'>{this.state.errors.message}</div>

                            </form>

                            <div className='row text-left'>
                                <div className='col-12'>
                                    <p className='p-small'>Kembali masuk <Link to='/' className='underline'>disini</Link></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);