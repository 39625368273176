import React,{Component} from 'react';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import parse from 'html-react-parser';
import {toast, ToastContainer} from "react-toastify";

require('dotenv').config();

class About extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            errors: {},

            id: '',
            title: '',
            content: '',
            details: [],
            banner: null,
            heroImage: null,
            heroTitle: '',
            heroDesc: '',
            whyTitle: '',
        };
    }

    componentDidMount() {

        document.title = 'Gudangemas - About';

        this.__fetchData();

    }

    __fetchData = () => {
        this.setState({
            loading: true
        });
        Api.get('/about/1').then(resp => {

            if (resp.data) {

                this.setState({
                        id: resp.data.id,
                        title: resp.data['about-title'],
                        content: resp.data.description,
                        details: resp.data.details,
                        banner: resp.data['about-banner'] ? resp.data['about-banner'] : null,
                        heroImage: resp.data['desc-image'] ? resp.data['desc-image'] : null,
                        heroTitle: resp.data['desc-title'],
                        heroDesc: resp.data['hero-description'],
                        whyTitle: resp.data['why-title'],
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (
            <main className='pages about'>
                    {this.state.loading ?
                        <div className=' container loading-page text-center py-5'>
                            <i className='fa fa-spinner fa-spin'> </i>
                        </div> :
                        <>
                            <section className='banner-about' style={{backgroundImage:'url('+this.state.banner+')'}}></section>
                            <section className='container'>
                            <h1>{this.state.title}</h1>
                            <p>{this.state.content}</p>

                        </section>
                        <section className='container-fluid mt-3'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 pl-0'>
                                    <img src={this.state.heroImage} className='img-fluid' />
                                </div>
                                <div className='col-md-6 col-lg-4 ml-lg-5'>
                                    <h2>{this.state.heroTitle}</h2>
                                    <p>{this.state.heroDesc}</p>
                                </div>
                            </div>
                        </section>
                            <section className='container'>
                                <h2 className='text-center mb-4'>{this.state.whyTitle}</h2>
                                <div className='row'>
                                    {this.state.details.length > 0 &&
                                    this.state.details.map((row, id) => (
                                        <div className='col-md-6' key={id}>
                                            <div className='row'>
                                                <div className='col-2'>
                                                    <img src={row.icon} className='img-fluid' />
                                                </div>
                                                <div className='col-10'>
                                                    <h3>{row.title}</h3>
                                                    <p>{row.description}</p>

                                                </div>
                                            </div>
                                        </div>


                                    ))}
                                </div>

                            </section>
                        </>
                    }

                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(About);