import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import { EditorState, convertFromRaw, } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import Logo from '../../../images/logo.png';
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import AuthHelper from '../../../libraries/auth-helper';
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import moment from "moment/moment";
import Default from '../../../images/default.jpeg';
import {toast, ToastContainer} from "react-toastify";
import {history} from '../../../shared/configure-store';

require('dotenv').config();

const validator = new SimpleReactValidator();

class BlogsDetail extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},

            id: '',
            title: '',
            content: '',
            image: '',
            slug: '',

            rows: [],
            total: 0,
            perPage: 4,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };

    }

    componentDidMount() {

        document.title = 'Gudangemas - Blog';

        this.__fetchData(true);
    }

    __fetchData = update => {
        let page = update ? 0 : this.state.currentPage;

        this.setState({
                loading: true
            }
        );

        let params = {
            page: page,
            size: this.state.perPage,
            ['is-blog']: true,

        };

        Api.post('/blog-page/get-all',params).then(resp => {

            if (resp.data) {
                this.setState({
                        rows: resp.data.viewBlogPages,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );

            }

        }).catch(err => {
            console.log(err);
        });
    };


    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };


    render() {
        return (
            <main className='blogs'>
                <section className='container'>
                    <div className='row'>
                        {this.state.loading ? (
                            <>
                                <div className='col-md-6'>
                                    <article>
                                        <Skeleton variant='rect' height={200} />
                                        <div className='content-article'>
                                            <Skeleton variant='rect'/>
                                            <Skeleton variant='rect' height={50} />
                                        </div>

                                    </article>
                                </div>

                                <div className='col-md-6'>
                                    <article>
                                        <Skeleton variant='rect' height={200} />
                                        <div className='content-article'>
                                            <Skeleton variant='rect'/>
                                            <Skeleton variant='rect' height={50} />
                                        </div>

                                    </article>
                                </div>
                            </>
                        ) : (
                            this.state.rows.length === 0 ? (
                               <p>kosong</p>
                            ) : (
                                this.state.rows.map(row => (
                                    <div className='col-md-6'>
                                        <article>
                                            <img src={row.image ? row.image : Default} className='img-fluid' />
                                            <div className='content-article'>
                                                <time className='text-left'><span className='cat'>{row.category}</span>|<span className='date'>{ moment(row.createdDate).format('DD/MM/YYYY')}</span></time>
                                                <h3>{row.title ? row.title : ''}</h3>
                                                <p>{row.excerpt ? row.excerpt : ''}</p>
                                                <button className='btn-more' onClick={() => this.handleGo('/blogs/'+row.slug)}>Selengkapnya <i className="fas fa-chevron-right"> </i></button>

                                            </div>

                                        </article>
                                     </div>
                                ))
                            )
                        )}
                        <div className='col-12'>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={this.state.perPage}
                                page={this.state.currentPageTable}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>
                    </div>


                </section>
                <ToastContainer />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsDetail);