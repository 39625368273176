import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {connect} from 'react-redux';
import Popup from '../../../images/popup.png';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import { Link } from 'react-router-dom';
import AuthHelper from '../../../libraries/auth-helper';
import NumberFormat from 'react-number-format';
import YoutubeModal from 'react-youtube-modal';
import Default from '../../../images/default.jpeg';
import Produk from '../../../images/product.png';
import Cart from '../../../images/product-cart.svg';
import Kuis from '../../../images/kuis.svg';
import Simulasi from '../../../images/simulasi.svg';
import Zakat from '../../../images/zakat.png';
import Slider from "react-slick";
import Skeleton from "@material-ui/lab/Skeleton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment/moment";
import {toast, ToastContainer} from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import _ from 'lodash';
import Storage from "../../../libraries/storage";
import templateId from "../../../libraries/lang-id";
SimpleReactValidator.addLocale('indo', templateId);

require('dotenv').config();

const validator = new SimpleReactValidator({
    locale: 'indo'
});

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingProfile: true,
            showDialogLogin: false,
            errors: {},
            username: '',
            password: '',
            showPassword: false,

            showDialogCart: false,

            date:null,
            banners: [],
            products: [],
            menus: [],
            galleries: [],
            quizes: [],
            blogs: [],
            user: null,
            photo: Default,
            video: '',

            level: '',
            lastTransaction: '',
            lastUpdate: '',
            customerPoint: {},
            influencers: [],

            popupEnable: false,

            config: '',
            configText: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Gudangemas - Home';
        let user = AuthHelper.getProfile();
        let date = Date.now();

        let popup = Storage.get('popup');
        if(popup) {
            this.setState({
                popupEnable: false,
            });
        }else {
            this.setState({
                popupEnable: true,
            });
        }


        if(user) {
            Api.get('/customer/by-code/'+user['customer-code']).then(resp => {

                let data = resp.data;
                if (data) {
                    this.setState({
                        photo: data.avatar === null ? Default : data.avatar,
                        level: data['customer-type'],
                        lastTransaction: data['last-transaction-date'],
                        lastUpdate: data['last-update'],
                        influencers: data.influencers !== null ? data.influencers : [],
                        customerPoint: data['customer-point'] !== null ? data['customer-point'] : null,
                        loadingProfile: false,
                    });
                }
            }).catch(err => {
                console.log(err);
            });

            this.setState({
                    user: user,
                    date: date
                }
            );
        }

        Api.get('/dashboard').then(resp => {

            let data = resp.data;
            let now = moment(Date.now());

            let products = data.products.map(o => {
                let start = moment(o['sale-start-date'],'DD/MM/YYYY');
                let end = moment(o['sale-end-date'],'DD/MM/YYYY');

                return {
                    id: o.id,
                    sku: o.sku,
                    image: o.image,
                    stock: parseFloat(o.stock),
                    rating: parseFloat(o.rating),
                    weight: o.weight,
                    category: o['category-name'],
                    sub: o['sub-catgeory-name'],
                    name: o['product-name'],
                    price: o['regular-price'],
                    priceSale: o['sale-price'],
                    isSale: moment(now).isBetween(start, end),
                    startSale: o['sale-start-date'],
                    endSale: o['sale-end-date'],
                    code: o['product-type-code'],
                    quantity: 0,
                }
            });

            let banners = [];
                data.banners.map(o => {
                if(o.title === 'true') {
                    banners.push({
                        image: o.image,
                        url: o.url,
                        content: o.content,
                        title: o.title,
                    });
                }

            });

            if (data) {

                this.setState({
                    banners: banners !== null ? banners : [],
                    video: data.video,
                    blogs: data.blogs !== null ? data.blogs : [],
                    menus: data['list-menu'],
                    quizes: data['additional-menu'],
                    galleries: data.galleries !== null ? data.galleries : [],
                    products: products,
                    loading: false,
                    }
                );
            }
        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/2').then(resp => {

            if (resp.data) {
                this.setState({
                        config: resp.data.value ? resp.data.value : 'OPEN',
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/6').then(resp => {

            if (resp.data) {
                this.setState({
                        configText: resp.data.value ? resp.data.value : '',
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleClosePop = () => {
        Storage.set('popup', true);
        this.setState({
            popupEnable: false,
        });
    };

    handleLogIn = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            username: this.state.username,

            password: this.state.password,

        };

        Api.post('/user/authenticate', params).then(resp => {
            if(resp.status == 200) {
                AuthHelper.setLogin(resp.data);

                AuthHelper.setProfile(resp.data);
                window.location.reload();

            }else {
                this.showMessage(false, resp.message[0]);

            }


            this.setState({
                user: resp.data,
                    loading: false,
                showDialogLogin: false,
                }
            );



        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false,
                showDialogLogin: false,

                }
            );

        });

    };

    handleChange (e, prop){
        let value = e.target.value;
        if(prop === 'username') {
            this.setState({
                [prop]: value.replace(/\s/g, '')
            })
        }else {
            this.setState({
                [prop]: e.target.value
            })
        }
    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handlePlus = (e, id) => {
        e.preventDefault();
        let data = this.state.products;
        let message = '';
        let pop = false;
        _.forEach(data, function (v, k) {
            if (v.id === id) {
                if(v.code === 'VP') {
                    pop = true;
                    message = 'Pilih variasi terlebih dahulu di detil produk';
                }else {
                    if ((v.quantity + 1) <= v.stock) {
                        v.quantity = v.quantity + 1;
                    } else {
                        pop = true;
                        message = 'Stok tidak mencukupi.';
                    }
                }
            }
        });

        if (pop) {
            this.showMessage(false, message);
        }

        this.setState({
            products: data,
        });
    };

    handleMinus = (e, id) => {
        e.preventDefault();
        let data = this.state.products;


        _.forEach(data, function (v, k) {
            if (v.id === id) {
                v.quantity = v.quantity - 1;
            }
        });

        this.setState({
            products: data,
        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleCart = (e, row) => {
        let profile = AuthHelper.getProfile();

        if(!profile) {
            this.setState({
                showDialogLogin: true,
            });
            return false;
        }

        let params = {
            'customer-code': this.state.user['customer-code'],
            price: row.isSale ? row.priceSale : row.price,
            quantity: row.quantity,
            sku: row.sku,
        };

        Api.post('/cart', params).then(resp => {

            if(resp.status === 200) {
                this.setState({
                        showDialogCart: true,
                    }
                );

                this.props.setShowCart();
            }
            this.setState({
                    loading: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleCloseLogin = () => {
        this.setState({
            showDialogLogin: false,
        });
    };


    handleGo  = (link) => {
        window.location.href = link;
        //history.push(link);
    };
    handleCloseCart = () => {
        this.setState({
            showDialogCart :false,
        });
        window.location.reload();
    };

    render() {
        const settings = {
            dots: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const products = {
            dots: true,
            nav: false,
            slidesToShow: 4,
            slidesToScroll: 3,
            variableWidth: true,
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }],
            nextArrow: false,
            prevArrow: false,
            infinite: false,
        };

        const galleries = {
            dots: false,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const Star = (item) => {
            const items = [];

            for (let i = 1; i <= 5 ; i++) {
                items.push(
                    <span key={i} className={i <= item.item ? 'star-icon yes' : 'star-icon'}>
                    <i className="fas fa-star"> </i>
                </span>
                )
            }
            return items;
        };

        return (
            <main className='home'>

                <div className='container'>
                    {this.state.config === 'CLOSE' &&
                    <div className='alert'>
                        <p className='mb-0'><i className="fas fa-exclamation-circle mr-2"> </i> {this.state.configText}</p>
                    </div>
                    }
                    <section className='row section-banner'>
                        <div className='col-md-7 col-lg-8'>
                            {this.state.loading ?
                                <Skeleton variant='rect' height={450}/>
                            :
                                this.state.banners.length > 0 &&
                                <Slider {...settings} >
                                    {this.state.banners.map((row, id) => (
                                        row.url !== '' ?
                                            <a href={row.url} target={row.content === 'true' ? '_blank' : '_self'} key={id}>
                                                <img src={row.image} alt='banner' />
                                            </a>
                                        :
                                            <img src={row.image} alt='banner' key={id} />
                                        )
                                        )}
                                </Slider>
                            }

                        </div>
                        <div className='col-md-5 col-lg-4'>
                            {this.state.user ?
                                <div className='dash-profile'>
                                    <img src={this.state.photo} className='img-profile' alt='profile' />

                                    <h3>Hi, {this.state.user['full-name'] ? this.state.user['full-name'] : this.state.user['username']}</h3>

                                    <p className='mb-1'>{this.state.level} |  <i className='icon-p mr-2'>P</i>
                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.customerPoint ? this.state.customerPoint['total-point-active'] : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                    </p>
                                    <strong>Influencer </strong>
                                    {this.state.loadingProfile ?
                                        <i className="fa fa-spinner fa-spin"> </i>
                                        : this.state.influencers.length > 0 &&
                                    <p className='mb-0'>
                                        <i className="fas fa-people-arrows"> </i> {this.state.influencers[0].name ? this.state.influencers[0].name : this.state.influencers[0].code}
                                    </p>
                                    }
                                  {/*<strong>Influencer</strong>
                                    {this.state.influencers.length > 0 &&
                                    this.state.influencers.map((row,key) => (
                                        <p className='mb-0' key={key}>
                                            <i className="fas fa-people-arrows"> </i> {row.name}
                                            </p>

                                        ))
                                    }*/}
                                    <hr />

                                    <div className='row justify-content-center'>
                                        <div className='col-6 text-right border-right'>
                                            <div className='text-center'>
                                                <i className="far fa-clock"> </i>
                                                <p className='mb-2'><strong>Login terakhir</strong></p>
                                                <time>{this.state.loadingProfile ? <i className="fa fa-spinner fa-spin"> </i> : this.state.lastUpdate}</time>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='text-center'>
                                                <i className="fas fa-exchange-alt"> </i>
                                                <p className='mb-2'><strong>Transaksi terakhir</strong></p>
                                                <time>{this.state.loadingProfile ? <i className="fa fa-spinner fa-spin"> </i> : this.state.lastTransaction}</time>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                :
                                <form name='login' id='login' noValidate>
                                    <h3>Login</h3>
                                    <div className='form-group'>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='username'
                                                   name='username'
                                                   label='Username'
                                                   placeholder='exp: johndoe/john@mail.com'
                                                   onChange={(e) => this.handleChange(e, 'username')}
                                                   value={this.state.username}
                                                   fullWidth
                                        />
                                        {validator.message('username', this.state.username, 'required')}
                                        <div className='text-danger'>{this.state.errors.username}</div>
                                    </div>

                                    <div className='form-group'>
                                        <TextField variant='outlined'
                                                   type={this.state.showPassword ? 'text' : 'password'}
                                                   id='adornment-password'
                                                   name='password'
                                                   label='Password'
                                                   placeholder='Your password here..'
                                                   onChange={(e) => this.handleChange(e,'password')}
                                                   value={this.state.password}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position='end'>
                                                               <IconButton
                                                                   className='btn-input'
                                                                   aria-label='Toggle password visibility'
                                                                   onClick={this.handleClickShowPassword}
                                                               >
                                                                   {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                       <i className='fas fa-eye-slash'> </i>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       )
                                                   }}
                                                   fullWidth
                                        />
                                        {validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>
                                    <div className='text-danger mb-2'>{this.state.errors.message}</div>
                                    <button onClick={this.handleLogIn} className='btn btn-yellow' disabled={this.state.loading }>MASUK { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }</button>

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p>Baru mengenal Gudangemas?</p>
                                        </div>
                                        <div className='col-md-6 text-right'>
                                            <a href='/forgot' className='text-forgot'>Lupa password?</a>
                                        </div>
                                    </div>

                                    <button onClick={() => this.handleGo('/register')} type='button' className='btn btn-blue'> DAFTAR DISINI</button>

                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            <hr />

                                            <p>Pelajari <a href='/pages/syarat-dan-ketentuan' className='text-forgot'>Syarat dan Ketentuan</a></p>
                                        </div>
                                    </div>
                                </form>
                            }

                        </div>
                    </section>
                    <section className='row section-menu'>
                        {this.state.menus.length > 0 &&
                        this.state.menus.map((row, id) => (
                            <div className='col-sm-4' key={id}>
                                <div className='card-menu' style={{background:row['background-color']}} onClick={() => this.handleGo(row.link)}>
                                    <label>{id+1}</label>
                                    <p>{row.name.split(" ")[0]} <span>{row.name.split(" ")[1]}</span></p>
                                </div>
                            </div>
                        ))}


                    </section>
                    <section className='row section-product'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h2>Antam Certicard</h2>
                            </div>
                            {this.state.loading ?
                                <div className='row'>
                                    <div className='col-6 col-md-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3 d-none d-md-block'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>
                                    <div className='col-6 col-md-3 d-none d-md-block'>
                                        <Skeleton variant='rect' height={450} width={250} />
                                    </div>

                                </div>
                                :
                                this.state.products.length > 0 &&
                                <Slider {...products} style={{marginLeft:'-15px'}} >
                                    {this.state.products.map((row, id) => (
                                        <div className='item-slider' key={id}>
                                            <div className='card-product'>
                                                <Link to={'/products/'+row.sku} >
                                                    <div className='card-img'>
                                                        <label>{row.sub ? row.sub : ''}</label>
                                                        <img src={row.image ? row.image : Produk} alt='banner' />
                                                    </div>
                                                </Link>
                                                <div className='card-body'>
                                                    <small>{row.category ? row.category : ''}</small>
                                                    <Link to={'/products/'+row.sku} >
                                                        {row.name &&
                                                        <h4>{(row.name.length > 30) ? row.name.substring(0,30)+'...' : row.name}</h4>
                                                        }
                                                        </Link>
                                                  {/*  <div className='star-wrap'>
                                                        <Star item={row.rating} />
                                                    </div>*/}

                                                    {row.stock > 0 ?
                                                    <div className='price'>
                                                        {row.isSale &&
                                                            <p className='discount-price'>
                                                            Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.priceSale} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                            </p>
                                                        }
                                                        <p>
                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                        </p>
                                                        </div>:
                                                        <div className='price'>
                                                            <p>HABIS</p>
                                                        </div>
                                                    }

                                                    <div className='btn-group'>
                                                        <button className='btn-minus'
                                                                disabled={row.quantity <= 0 ? true : false}
                                                                onClick={(e) => this.handleMinus(e, row.id)}>
                                                            <i className="fas fa-minus-circle"> </i>
                                                        </button>
                                                        <input type='text' value={row.quantity} readOnly />
                                                        <button className='btn-plus'
                                                                /*disabled={row.stock < row.quantity ? true : false}*/
                                                                onClick={(e) => this.handlePlus(e, row.id)}>
                                                            <i className="fas fa-plus-circle"> </i>
                                                        </button>
                                                    </div>

                                                    <button className='btn btn-bottom'
                                                            disabled={(row.quantity <= 0 || this.state.config === 'CLOSE') ? true : false}
                                                            onClick={(e) => this.handleCart(e, row)}>
                                                        <i className="fas fa-shopping-cart"> </i> Beli</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </Slider>
                            }
                        </div>
                    </section>
                    {this.state.quizes.length > 0 &&
                    <section className='row section-quiz'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h2>Kuis</h2>
                            </div>
                        </div>

                        <div className='col-md-6 mb-2'>
                            <div className='card-quiz' onClick={() => this.handleGo(this.state.quizes[0].link)} >
                                <img src={Kuis} className='img-quiz' alt='kuis' />
                                <p>{this.state.quizes[0].name}</p>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <div className='card-quiz' onClick={() => this.handleGo(this.state.quizes[1].link)}>
                                <img src={Simulasi} className='img-quiz' alt='simulasi' />
                                <p>{this.state.quizes[1].name}</p>
                            </div>
                        </div>
                    </section>
                    }


                    <section className='row section-video'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h2>Video</h2>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='img-video'>
                                <i className="fas fa-play-circle"> </i>
                                {this.state.loading ?
                                    <Skeleton variant='rect' height={300}/>
                                    :
                                    this.state.video &&
                                    <YoutubeModal videoId={this.state.video.link} width={600} height={450}>
                                        <img src={this.state.video.urlImage} className='img-fluid' width='100%' />
                                    </YoutubeModal>
                                }
                            </div>

                        </div>
                        <div className='col-md-6'>
                           <h2>{this.state.video.title}</h2>
                            <p>{this.state.video.content}</p>
                        </div>
                    </section>

                    <section className='row section-gallery'>
                        <div className='col-md-6'>
                            <div className='section-title'>
                                <h2>Berita</h2>
                            </div>
                            {this.state.loading ?
                                <Skeleton variant='rect' height={100} width={250} />
                                :
                                this.state.blogs.length > 0 &&
                                this.state.blogs.map((row, id) => (
                                        <article className='post-wrap' key={id}>
                                            <div className='img-post'>
                                                <img src={row.image ? row.image : Default} alt='blog' />
                                            </div>
                                            <div className='post-content'>
                                                <h3>{row.title}</h3>
                                                <p>{row.excerpt}</p>
                                                <Link to={'/blogs/'+row.slug}>Selengkapnya</Link>
                                            </div>
                                        </article>
                                    ))
                            }
                        </div>
                        <div className='col-md-6'>
                            <div className='section-title'>
                                <h2>Gallery</h2>
                            </div>
                            {this.state.loading ?
                                <Skeleton variant='rect' height={100} width={250} />
                                :
                                this.state.galleries.length > 0 &&
                                <Slider {...galleries} >
                                    { this.state.galleries.map((row, id) => (
                                        <img key={id} src={row.image ? row.image : Default}  alt='banner' />

                                    ))}
                                </Slider>
                            }
                        </div>

                    </section>
                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogLogin}
                    onClose={this.handleCloseLogin}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Silakan masuk ke dalam akun untuk melanjutkan transaksi kamu</DialogTitle>
                    <DialogContent>
                        <form name='login' id='dialogLogin' noValidate>
                            <div className='form-group'>
                                <TextField variant='outlined'
                                           type='text'
                                           id='username'
                                           name='username'
                                           label='Username'
                                           placeholder='Email/Username'
                                           onChange={(e) => this.handleChange(e, 'username')}
                                           value={this.state.username}
                                           fullWidth
                                />
                                {validator.message('username', this.state.username, 'required')}
                                <div className='text-danger'>{this.state.errors.username}</div>
                            </div>

                            <div className='form-group'>
                                <TextField variant='outlined'
                                           type={this.state.showPassword ? 'text' : 'password'}
                                           id='adornment-password'
                                           name='password'
                                           label='Password'
                                           placeholder='Your password here..'
                                           onChange={(e) => this.handleChange(e,'password')}
                                           value={this.state.password}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position='end'>
                                                       <IconButton
                                                           className='btn-input'
                                                           aria-label='Toggle password visibility'
                                                           onClick={this.handleClickShowPassword}
                                                       >
                                                           {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                               <i className='fas fa-eye-slash'> </i>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               )
                                           }}
                                           fullWidth
                                />
                                {validator.message('password', this.state.password, 'required')}
                                <div className='text-danger'>{this.state.errors.password}</div>
                            </div>
                            <div className='text-danger mb-2'>{this.state.errors.message}</div>
                            <button onClick={this.handleLogIn} className='btn btn-blue' disabled={this.state.loading && 'disabled' }>MASUK { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }</button>

                            <div className='row mt-2'>
                                <div className='col-md-6'>
                                    <p className='mb-0'>Baru mengenal Gudangemas?<br />
                                        <a href='/' className='text-blue'>Daftar disini</a>
                                    </p>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <a href='/' className='text-blue'>Lupa password?</a>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <hr />
                                    <a href='/' className='text-blue'>Pelajari Syaratan dan Ketentuan</a>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialogCart}
                    onClose={this.handleCloseCart}
                    className='ge-dialog'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Produk berhasil dimasukan ke keranjang belanja</DialogTitle>
                    <DialogContent>
                        <div className='text-center'>
                            <img src={Cart} className='img-fluid' alt='keranjang' />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleCloseCart}>
                            Lanjutkan belanja
                        </button>
                        <button className="btn btn-blue" onClick={() => this.handleGo('/cart')}
                        >
                            Keranjang
                        </button>
                    </DialogActions>
                </Dialog>
                <ToastContainer />
            </main>
        )
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className+' custom-left'}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        />
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className+' custom-right'}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        />
    );
}
const mapStateToProps = (state) => {

    return {

        cart_state: state.cart_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Home);